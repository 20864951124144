import {call, select} from "redux-saga/effects";
import * as selectors from "../../selectors";
import request from "../../../../common/services/request";

export function* dismissById({id}){
  return yield call(request.post, {
    path: `Notification/dismiss/${id}`,
    showError: true
  })
}

export function* dismissAll(){
  let data = yield select(selectors.getData);

  if(!data.grid.rows)
    return;

  return yield call(request.post, {
    path: 'Notification/dismiss/all',
    params: {},
    showError: true
  });
}