import React from 'react';

import * as config from './../config';
import * as immutable from './../../common/helpers/immutable';
import {rules} from './../../common/services/form-validator/config';
import inputTextModel from './../../common/components/elements/InputText/models';

export const data = {};

export const ui = {
  blockResendCodeFor: 0,
  sendCodeModal: {
    show: false,
  },
  sendCodeForm: {
    code: immutable.updateObjectProps(inputTextModel, {
      id: 'code',
      type: 'number',
      label: 'Kode',
      placeholder: 'Kode',
      inputProps: {
        autoComplete: 'one-time-code'
      },
      rules: [rules.REQUIRED, rules.NUMBER]
    })
  },
  profileForm: {
    firstName: immutable.updateObjectProps(inputTextModel, {
      id: 'firstName',
      label: 'Fornavn',
      placeholder: 'Fornavn',
      rules: [rules.REQUIRED]
    }),
    lastName: immutable.updateObjectProps(inputTextModel, {
      id: 'lastName',
      label: 'Etternavn',
      placeholder: 'Etternavn',
      rules: [rules.REQUIRED]
    }),
    email: immutable.updateObjectProps(inputTextModel, {
      id: 'email',
      label: 'E-post',
      Etternavn: 'E-post',
      extraText: 'Ved å oppgi e-postadresse vil du få tilgang til utvidet funksjonalitet som varsling fra lagrede søk',
      rules: [rules.EMAIL]
    }),
    phoneNumber: immutable.updateObjectProps(inputTextModel, {
      id: 'phoneNumber',
      label: 'Telefonnummer',
      contactPhoneNumberConfirmed: false,
      rules: [rules.PHONE_NUMBER]
    })
  },
  passwordForm: {
    oldPassword: immutable.updateObjectProps(inputTextModel, {
      id: 'oldPassword',
      type: 'password',
      label: 'Gammelt passord',
      placeholder: 'Gammelt passord',
      inputProps: {
        autoComplete: 'current-password'
      },
      rules: [rules.REQUIRED, {name: rules.LENGTH, size: 5}]
    }),
    newPassword: immutable.updateObjectProps(inputTextModel, {
      id: 'newPassword',
      type: 'password',
      label: 'Nytt passord',
      placeholder: 'Nytt passord',
      inputProps: {
        autoComplete: 'new-password'
      },
      rules: [rules.REQUIRED, {name: rules.LENGTH, size: 5}, {name: rules.EQUAL, with: 'confirmPassword'}]
    }),
    confirmPassword: immutable.updateObjectProps(inputTextModel, {
      id: 'confirmPassword',
      type: 'password',
      label: 'Gjenta passord',
      placeholder: 'Gjenta det nye passordet',
      inputProps: {
        autoComplete: 'new-password'
      },
      rules: [rules.REQUIRED, {name: rules.LENGTH, size: 5}, {name: rules.EQUAL, with: 'newPassword'}]
    }),
  },
  communicationForm: {
    notifyEmailSearchSubscription: {
      label: 'Send e-post',
      value: true
    },
    notifySmsSearchSubscription: {
      label: 'Send sms',
      value: false
    },
    notifyEmailNewMessage: {
      label: 'Send e-post',
      value: true
    },
    notifySmsNewMessage: {
      label: 'Send sms',
      value: false
    },
    allowEmailInformation: {
      label: 'Send e-post',
      value: true
    },
    allowSmsInformation: {
      label: 'Send sms',
      value: true
    },
    allowEmailMarketing: {
      label: 'Send e-post',
      value: true
    },
    allowSmsMarketing: {
      label: 'Send sms',
      value: true
    }
  }
};

export const query = {
  tab: config.tabs[0].value
};