// see: https://learn.javascript.ru/metrics
// scrollTop: height of top scrolled part
// clientHeight: height of content of element without borders (clientTop/clientLeft)
// offsetHeight: full height of element including borders (clientTop/clientLeft)
// scrollHeight: all scrollable height

const _getSum = (from, to) => {
  let sum = 0;
  for(let v = from; v <= to; v++){
    sum += v;
  }
  return sum;
};

export const getScrollTop = ({id} = {}) => {
  if(id){
    return document.getElementById(id).scrollTop;
  } else {
    return document.documentElement.scrollTop || document.body.scrollTop;
  }
};
export const getClientHeight = ({id} = {}) => id ? document.getElementById(id).clientHeight : document.documentElement.clientHeight;
export const getScrollHeight = ({id} = {}) => id ? document.getElementById(id).scrollHeight : document.documentElement.scrollHeight;

export const getDistanceFromBottom = ({id}) => {
  if(id && !document.getElementById(id))
    return;

  return getScrollHeight({id}) - getClientHeight({id}) - getScrollTop({id});
};

export const isTimeToLoadMore = ({id, afterHitsBottom = true, offset = 500/*px*/} = {}) => {
  return isCrossedThreshold({id, afterHitsBottom, offset});
};

export const isCrossedThreshold = ({id, afterHitsBottom = true, offset = 1}) => {
  if(id && !document.getElementById(id))
    return;

  if(afterHitsBottom){
    return (getScrollTop({id}) + getClientHeight({id}) + offset >= getScrollHeight({id}));
  } else /*after hits top*/ {
    return (getScrollTop({id}) - offset <= 0);
  }
};

export const setToTopById = (id) => {
  if(!id || !document.getElementById(id))
    return;

  let elem = document.getElementById(id);

  elem.scrollTop = 0;
};

export const setToBottomById = (id) => {
  if(!id || !document.getElementById(id))
    return;

  let elem = document.getElementById(id);

  elem.scrollTop = elem.scrollHeight;
};

export const to = (id) => {
  let elem = document.getElementById(id);

  let maxSpeed = 20;
  // minSpeed of 1px can`t be used because of buggy behaviour of window.scrollBy on resizing and zooming out of window
  let minSpeed = 2;
  let step = minSpeed, diff;

  let interval = setInterval(() => {
    let rect = elem.getBoundingClientRect();

    diff = Math.abs(rect.top);

    if(diff < _getSum(minSpeed, maxSpeed)){
      step = step > minSpeed ? --step : minSpeed;
    } else {
      step = step < maxSpeed ? ++step: maxSpeed;
    };

    window.scrollBy(0, rect.top > 0 ? step : -step);

    let scrollBarHasReachedEdge =
      // reached top
      getScrollTop() < minSpeed ||
      // reached bottom
      getScrollHeight() - Math.floor(getClientHeight() + getScrollTop()) < minSpeed;

    if(Math.abs(rect.top) < minSpeed || scrollBarHasReachedEdge){
      clearInterval(interval);
    };
  }, 1);
};