import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import * as sessionSelectors from '../common/redux/session/selectors';
import * as sessionActions from '../common/redux/session/actions';
import * as userService from '../common/services/user';
import * as chatPageSelectors from '../Chat/redux/selectors';
import * as notificationsPageSelectors from '../Notifications/redux/selectors';

import './style.scss';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';

const mapStateToProps = (state) => ({
  session: sessionSelectors.getSession(state),
  chatPageDate: chatPageSelectors.getData(state),
  notificationsPageData: notificationsPageSelectors.getData(state)
});

class MainNavigation extends Component {
  constructor(props){
    super(props);
    this.state = {
      isSideNavOpen: false
    };
  };
  logout = () => {
    let {dispatch} = this.props;

    dispatch(sessionActions.unsetSession());
  };
  toggleSideNav = () => {
    this.setState({
      isSideNavOpen: !this.state.isSideNavOpen
    });
  };
  render(){
    let {session, chatPageDate, notificationsPageData} = this.props;

    let userAvatarUrl = userService.getUserAvatar(session);

    let totalUnreadMessageCount = chatPageDate.threads && chatPageDate.threads.unreadMessageCount;
    let totalNotifications = notificationsPageData.grid && notificationsPageData.grid.notDismissedCount;

    if(totalUnreadMessageCount > 0)
      totalUnreadMessageCount = totalUnreadMessageCount < 99 ? totalUnreadMessageCount : 99;
    if(totalNotifications > 0)
      totalNotifications = totalNotifications < 99 ? totalNotifications : 99;

    return (
      <nav className="Navigation">
        <DesktopNavigation
          session={session}
          userAvatarUrl={userAvatarUrl}
          totalNotifications={totalNotifications}
          totalUnreadMessageCount={totalUnreadMessageCount}
          logout={this.logout}
        />
        <MobileNavigation
          session={session}
          userAvatarUrl={userAvatarUrl}
          totalNotifications={totalNotifications}
          totalUnreadMessageCount={totalUnreadMessageCount}
          logout={this.logout}
          isSideNavOpen={this.state.isSideNavOpen}
          toggleSideNav={this.toggleSideNav}
        />
      </nav>
    );
  };
};

// without withRouter rerendering of component wont work as expected
// see: https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
export default withRouter(connect(mapStateToProps)(MainNavigation));