import * as actions from './actions';
import * as immutable from './../../helpers/immutable';

import * as sessionActions from './../../redux/session/actions';

const processes = (state = {}, action) => {
  switch (action.type) {
    case actions.UPDATE:
      return immutable.updateObjectProps(state, action.updateOb);
    case actions.ADD_PROCESS:
      return {
        ...state,
        [action.processName]: {
          inProcess: true,
          isSuccessed: false,
          hasError: false
        }
      };
    case actions.SET_SUCCESS:
      return {
        ...state,
        [action.processName]: {
          inProcess: false,
          isSuccessed: true,
          hasError: false,
          status: action.status
        }
      };
    case actions.SET_ERROR:
      return {
        ...state,
        [action.processName]: {
          inProcess: false,
          isSuccessed: false,
          hasError: true,
          status: action.status,
          errorMessage: action.errorMessage,
          payload: action.payload,
        }
      };
    default:
      return state;
  }
};

const reducer = (state, action) => {
  if (action.type === sessionActions.UNSET_SESSION) {
    state = undefined;
  }

  return processes(state, action);
};

export default reducer;

