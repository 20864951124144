import {take, call, select, put, takeLatest} from 'redux-saga/effects';

import * as actions from './actions';
import * as selectors from './selectors';
import * as mainAppCompActions from './../../../MainApp/redux/actions';
import * as reduxSagaService from './../../services/redux-saga';
import * as signalrActions from './../../services/signalr/actions';
import request from './../../services/request';

export function* fetchProfile(){
  let profile = yield call(request.get, {
    path: 'Profile'
  });

  if(profile instanceof Error)
    return;

  yield put(actions.updateSession({
    decodedIdToken: profile
  }));

  yield put(actions.profileIsUpdated());
};

export function* watchProfileIsUpdatedEventAction(){
  while(true){
    let {payload: profile} = yield take(signalrActions.PROFILE_IS_UPDATED);

    yield put(actions.updateSession({
      decodedIdToken: profile
    }));

    yield put(actions.profileIsUpdated());
  }
};

export function* watchUpdateSession(){
  while(true){
    yield take(actions.UPDATE_SESSION);

    let session = yield select(selectors.getSession);

    localStorage.setItem('session', JSON.stringify(session));
  }
};

export function* watchMainComponentDidMount(){
  while(true){
    let action = yield take([mainAppCompActions.MAIN_APP_COMPONENT_DID_MOUNT, actions.SUCCESSFUL_LOGIN]);

    let session = yield select(selectors.getSession);

    if(action.type === mainAppCompActions.MAIN_APP_COMPONENT_DID_MOUNT && !session.isAuthorized)
      continue;

    yield call(fetchProfile);
  };
};

export function* watchUnsetSession(){
  while(true){
    yield take(actions.UNSET_SESSION);

    localStorage.removeItem('session');
  }
};

export function* watchFetchProfile(){
  yield takeLatest(actions.FETCH_PROFILE, fetchProfile)
};

export default reduxSagaService.createRootSaga(
  watchUpdateSession,
  watchUnsetSession,
  watchMainComponentDidMount,
  watchFetchProfile,
  watchProfileIsUpdatedEventAction
);