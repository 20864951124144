import React, { Component } from 'react';
import {Route, Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import * as sessionSelectors from './../../../redux/session/selectors';
import * as userService from './../../../services/user';

const mapStateToProps = state => ({
  session: sessionSelectors.getSession(state)
});

class ProtectedRoute extends Component {
  render() {
    let {path, component, session, role} = this.props;

    let hasAccess = session.isAuthorized;

    if(hasAccess && role){
      hasAccess = userService.mapRoleToMethod[role](session);
    };

    return hasAccess ?
      <Route path={path} component={component}/> :
      <Redirect to="/"/>
  };
};

// without withRouter rerendering of component wont work as expected
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
export default withRouter(connect(mapStateToProps)(ProtectedRoute))
