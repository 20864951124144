import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';

import Spinner, {spinnerSizes} from './../../Spinner';

class BaseButton extends PureComponent {
  saveRef = (component ) => {
    this.ref = component;
  };
  animate = () => {
    let elem = ReactDOM.findDOMNode(this.ref);

    elem.className = elem.className.replace(/\s*animate/, '');

    // see: https://css-tricks.com/restart-css-animation/
    setTimeout(() => elem.className += ' animate', 0);
  };
  render(){
    let {
      id,
      type,
      disabled,
      component,
      className = '',
      children,
      animate,
      onClick,
      inProcess,
      spinnerSize = spinnerSizes.SMALL,
      ...rest
    } = this.props;

    // normalize/prepare values

    let ButtonComponent = component || 'button';

    className = `Button ${className}`;

    if(inProcess){
      animate = false;
      disabled = true;
      onClick = () => {};
    }

    // prepare props

    let props = {
      type,
      className,
      disabled,
      ref: this.saveRef,
      onClick,
      ...rest
    };

    if(id) props.id = id;
    if(animate) props.onMouseDown = this.animate;

    return (
      <ButtonComponent {...props}>
        {
          inProcess ?
            <Spinner size={spinnerSize}/> :
            children
        }
      </ButtonComponent>
    );
  };
};

export default BaseButton;