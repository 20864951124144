import {fork, take, put, call, all, select, takeLatest, takeEvery} from 'redux-saga/effects';

import * as actions from './actions';
import * as immutable from './../../helpers/immutable';
import * as scroll from './../../helpers/scroll';
import * as reduxSagaService from './../../services/redux-saga';
import {rules, sideRules, validators, errorMessages} from './config';
import request from './../request';

export const getLinkedProp = (propRules) => {
  if(propRules){
    for(let i = 0; i < propRules.length; i++){
      if(propRules[i].name === rules.EQUAL || propRules[i].name === rules.NOT_EQUAL)
        return propRules[i].with;
    }
  }
};

export function* check ({prop, value, propRules, form}){
  let additionalData = {}, result;

  let valueType = Object.prototype.toString.call(value);

  for(let rule of propRules){

    let ruleName = rule.name || rule;

    // if such ruleName doesnt exists, warn about this

    if(!rules[ruleName] && !sideRules[ruleName])
      alert(`unknown rule '${ruleName}'`);

    // if rule is not required and value is empty continue because there is nothing to check

    if(ruleName != rules.REQUIRED && (value == '' || valueType == '[object Undefined]' || valueType == '[object Null]'))
      continue;

    // check value according to type of value

    if(valueType == '[object Array]'){
      if(ruleName === rules.REQUIRED){
        return value.length ? [false, '', additionalData] : [true, errorMessages[rules.REQUIRED], additionalData];
      }
    } else if(valueType == '[object Boolean]'){
      if(ruleName === rules.REQUIRED){
        return value ? [false, '', additionalData] : [true, errorMessages[rules.REQUIRED], additionalData];
      }
    } else { // if value is string/number

      // client-side validation

      if(validators[ruleName]){

        // validation via pattern

        if(validators[ruleName].pattern){

          if(!validators[ruleName].pattern.test(value)){
            return [true, errorMessages[ruleName], additionalData];
          };

        // validation via function

        } else if(validators[ruleName].func){
          switch(ruleName){
            case rules.REQUIRED:
              if(!validators[ruleName].func(value)){
                return [true, errorMessages[ruleName], additionalData];
              };
              break;
            case rules.LENGTH:
              if(!validators[ruleName].func(value, rule.size, rule.type)){
                return [true, errorMessages[ruleName](rule.size, rule.type), additionalData];
              };
              break;
            case rules.NUMBER_MIN_MAX:
              if(!validators[ruleName].func(value, rule.min, rule.max)){
                return [true, errorMessages[ruleName](value, rule.min, rule.max), additionalData];
              };
              break;
            case rules.EQUAL:
            case rules.NOT_EQUAL:
              if(!validators[ruleName].func(value, form[rule.with].value)){
                return [true, errorMessages[ruleName](form[prop].label, form[rule.with].label), additionalData];
              };
              break;
            case rules.SELECT_FROM_LIST:
              if(!validators[ruleName].func(form, prop)){
                return [true, errorMessages[ruleName], additionalData];
              };
              break;
          }
        }
      }

      // * back-side validation

      if(ruleName === sideRules.POSTAL_CODE){
        if(value){
          let processName = `getPostaCodeTitle::${prop}`;

          let result = yield call(request.get, {
            path: `Address/postalCode/${value}`,
            processName,
            returnWholeResponse: true
          });

          if(result instanceof Error){
            return [true, result.message, additionalData];
          };

          additionalData = immutable.updateObjectProps(additionalData, {payload: {city: result.text}});
        };
      }
    };
  };

  return [false, '', additionalData];
};

export function* validateAndUpdateProp({event, value, payload, prop, formSelector, updateFormActionCreator, onValid, onEnd, isRecursiveCall = false}){
  let form = yield select(formSelector);

  let resultValueToCheck;

  // put new value before any checking

  // * if input is checkbox

  if(event && event.target.type === 'checkbox'){
    let newValueToApply = value || event.target.checked;

    if(Array.isArray(form[prop].value)){
      let updatedArray = event.target.checked ?
        immutable.pushToArray(form[prop].value, newValueToApply) :
        immutable.removeFromArray(form[prop].value, newValueToApply);

      resultValueToCheck = updatedArray;

      yield put(updateFormActionCreator({
        [prop]: {value: updatedArray}
      }));
    } else {
      yield put(updateFormActionCreator({
        [prop]: {value: newValueToApply}
      }));

      resultValueToCheck = newValueToApply;
    }

  // * in case of text input

  } else {
    let newValueToApply = value || (event ? event.target.value : '');

    yield put(updateFormActionCreator({
      [prop]: {value: newValueToApply, ...(payload || {})}
    }));

    resultValueToCheck = newValueToApply;
  };

  // select form one more time

  form = yield select(formSelector);

  // do checking

  let updateOb = {}, isFieldValid = true;

  let propRules = form[prop].rules;

  if(propRules && propRules.length){

    // check current field

    let [hasError, errorMessage, additionalData] = yield call(check, {
      form,
      prop,
      value: resultValueToCheck,
      propRules,
    });

    // prepare updateOb and put it

    updateOb = yield call(immutable.updateObjectProps, updateOb, {hasError, errorMessage}, additionalData);

    yield put(updateFormActionCreator({[prop]: updateOb}));

    // update status

    if(hasError) isFieldValid = false;

    // if there is any prop that is linked to/coupled with current prop we also run additional checking for it
    // example: when there are `new password` + `confirm password` fields with `EQUAL` rule in both fields

    if(!isRecursiveCall){
      let linkedPropName = yield call(getLinkedProp, propRules);

      if(!!linkedPropName){
        yield put(actions.validateAndUpdateProp({
          prop: linkedPropName,
          value: form[linkedPropName].value,
          formSelector,
          updateFormActionCreator,
          isRecursiveCall: true // to prevent infinite loop of calls
        }))
      };
    }
  };

  // if field is valid, call `onValid` callback if such callback is provided

  if(isFieldValid && onValid) yield call(onValid, resultValueToCheck);

  // if `onEnd` callback is provided

  if(onEnd) yield call(onEnd);
};

export function* submitForm({event, props, formSelector, updateFormActionCreator, onValid, onInvalid, disableScrolling, readyValidationResult}){
  event.preventDefault();

  let form = yield select(formSelector);

  let firstElemWithError;

  let formUpdateOb = {isFormValid: true};

  let isFormValid = true;

  // check form

  for(let prop of props){

    let propRules = form[prop].rules;

    if(propRules && propRules.length){

      // check field

      let [hasError, errorMessage, additionalData] = yield call(check, {
        prop,
        value: form[prop].value,
        propRules,
        form
      });

      // prepare prop updateOb and update form updateOb

      let propUpdateOb = yield call(immutable.updateObjectProps, {hasError, errorMessage}, additionalData);

      formUpdateOb = yield call(immutable.updateObjectProps, formUpdateOb, {
        [prop]: propUpdateOb
      });

      // update status

      if(hasError || (readyValidationResult && readyValidationResult[prop] && readyValidationResult[prop].hasError)){
        isFormValid = false;
        formUpdateOb = yield call(immutable.updateObjectProps, formUpdateOb, {isFormValid});
        if(!firstElemWithError) firstElemWithError = prop;
      }
    }
  };

  yield put(updateFormActionCreator(formUpdateOb));

  if(isFormValid) {
    if(onValid) yield call(onValid);
  } else {
    if(!disableScrolling) scroll.to(firstElemWithError);
    if(onInvalid) yield call(onInvalid);
  };
};

export function* watchValidateAndUpdateProp(){
  yield takeEvery(actions.VALIDATE_AND_UPDATE_PROP, validateAndUpdateProp);
};

export function* watchSubmitForm(){
  yield takeLatest(actions.SUBMIT_FORM, submitForm);
};

export default reduxSagaService.createRootSaga(
  watchValidateAndUpdateProp,
  watchSubmitForm
);