import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import Modal from './../Modal';
import classname from './../../../helpers/classname';
import Button, {buttons} from './../../elements/Button';

const componentName = 'RegularModal';
const cn = classname(componentName);

export const modalSizes = {
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg'
};

export const modalHeaderTypes = {
  DEFAULT: 'DEFAULT',
  CENTERED: 'CENTERED'
};

class RegularModal extends Component {
  render(){
    let {show = true, showHeader = true, header, headerType, className, headerClassName, contentClassName, bodyClassName, onClose, size, children} = this.props;

    if(!show)
      return null;

    className = cn.join(componentName, className);
    headerClassName = cn.join(cn('header'), cn(`header-${headerType}`), headerClassName);
    contentClassName = cn.join(cn('content'), contentClassName, cn(`${size}-content`));
    bodyClassName = cn.join(cn('body'), bodyClassName);

    return (
      <Modal className={className} contentClassName={contentClassName} transparentBackdrop={true} onClose={onClose}>
        {
          showHeader ?
            <header className={headerClassName}>
              {
                typeof header === 'string' ?
                  <h4 className="font-weight-bold mb-0">{header}</h4>
                  :
                  header
              }
              {
                headerType == modalHeaderTypes.DEFAULT ?
                  <Button button={buttons.SIMPLE} className={cn('close')} onClick={onClose}>
                    Lukk
                  </Button>
                  :
                  null
              }
            </header>
            :
            null
        }
        <div className={bodyClassName}>
          {children}
        </div>
      </Modal>
    );
  };
};

RegularModal.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  // optional
  headerType: PropTypes.string,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  size: PropTypes.string,
};

RegularModal.defaultProps = {
  headerType: modalHeaderTypes.DEFAULT,
  size: modalSizes.SMALL,
};

export default RegularModal;