import {take} from 'redux-saga/effects';

import * as favoriteButtonActions from './../../../../components/blocks/VehicleFavoriteButton/redux/actions';
import * as commonActions from './../../../../redux/actions';
import * as reduxSagaService from './../../../redux-saga';
import * as sessionActions from './../../../../redux/session/actions';
import * as registerPageActions from './../../../../../Register/redux/actions';
import * as addObjectPageActions from './../../../../../AddObject/redux/actions';
import * as vehiclePageActions from './../../../../../VehiclePage/redux/actions';

import * as config from './../config';

export const pushEvent = (ob) => window.dataLayer.push({event: 'custom event', ...ob});

export function* watchUserIsLoggedIn(){
  while(true){
    yield take(sessionActions.SUCCESSFUL_LOGIN);

    pushEvent(config.customEvents.SESSION.LOGGED_IN);
  }
};

export function* watchUserIsLoggedInWithFacebook(){
  while(true){
    yield take(sessionActions.SUCCESSFUL_LOGIN_WITH_FACEBOOK);

    pushEvent(config.customEvents.SESSION.LOGGED_IN_WITH_FACEBOOK);
  }
};

export function* watchUserFailedToLoginWithPassword(){
  while(true) {
    yield take(sessionActions.FAILED_TO_LOGIN_WITH_PASSWORD);

    pushEvent(config.customEvents.SESSION.FAILED_TO_LOGIN_WITH_PASSWORD);
  }
};

export function* watchUserIsLoggedOut(){
  while(true){
    yield take(sessionActions.UNSET_SESSION);

    pushEvent(config.customEvents.SESSION.LOGGED_OUT);
  }
};

export function* watchSuccessfulRegistrationWithPhoneNumber(){
  while(true){
    yield take(registerPageActions.SUCCESSFUL_REGISTRATION_WITH_PHONE_NUMBER);

    pushEvent(config.customEvents.REGISTRATION.REGISTERED_WITH_PHONE_NUMBER);
  }
};

export function* watchUserStartedToNewAd(){
  while(true){
    yield take(addObjectPageActions.USER_STARTED_TO_NEW_AD);

    pushEvent(config.customEvents.ADVERTISEMENTS.USER_STARTED_TO_NEW_AD);
  }
};

export function* watchUserSubmittedAd(){
  while(true){
    yield take(addObjectPageActions.USER_SUBMITTED_AD);

    pushEvent(config.customEvents.ADVERTISEMENTS.SUBMITTED);
  }
};

export function* watchNewMessageIsSent(){
  while(true){
    yield take(commonActions.NEW_MESSAGE_IS_SENT);

    pushEvent(config.customEvents.MESSAGES.NEW_MESSAGE_SENT);
  }
};

export function* watchAdIsAddedToFavorites(){
  while(true){
    yield take(favoriteButtonActions.AD_IS_ADDED_TO_FAVORITES);

    pushEvent(config.customEvents.ADVERTISEMENTS.USER_ADDED_TO_FAVORITES);
  }
};

export function* watchUserLookedToPhoneNumber(){
  while(true){
    yield take(vehiclePageActions.USER_LOOKED_TO_PHONE_NUMBER);

    pushEvent(config.customEvents.ADVERTISEMENTS.USER_LOOKED_TO_PHONE_NUMBER);
  }
};

export default reduxSagaService.createRootSaga(
  watchUserIsLoggedIn,
  watchUserIsLoggedInWithFacebook,
  watchUserFailedToLoginWithPassword,
  watchUserIsLoggedOut,
  watchSuccessfulRegistrationWithPhoneNumber,
  watchUserStartedToNewAd,
  watchUserSubmittedAd,
  watchNewMessageIsSent,
  watchAdIsAddedToFavorites,
  watchUserLookedToPhoneNumber
);
