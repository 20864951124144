import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="16px" height="12px" viewBox="0 0 16 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-4.000000, -6.000000)">
            <path d="M19.4984232,8.88905341 L10.7907424,17.5041777 C10.4555027,17.8341618 10.019349,18 9.58148485,18 C9.14362072,18 8.70575659,17.8341618 8.37222727,17.5041777 L4.50157678,13.6746695 C3.83280774,13.0147012 3.83280774,11.9435219 4.50157678,11.2818614 C5.17034582,10.620201 6.25132289,10.620201 6.92009193,11.2818614 L9.58148485,13.9149656 L17.0799081,6.49624537 C17.7486771,5.83458488 18.8296542,5.83458488 19.4984232,6.49624537 C20.1671923,7.15790587 20.1671923,8.22739291 19.4984232,8.88905341" id="path-1">
            </path>
          </g>
        </g>
      </svg>
    );
});

export default Icon;