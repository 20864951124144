import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-4.000000, -4.000000)" fill="#298FCA">
            <path d="M4,4 L8,4 L8,8 L4,8 L4,4 Z M10,4 L20,4 L20,8 L10,8 L10,4 Z M4,10 L8,10 L8,14 L4,14 L4,10 Z M10,10 L20,10 L20,14 L10,14 L10,10 Z M4,16 L8,16 L8,20 L4,20 L4,16 Z M10,16 L20,16 L20,20 L10,20 L10,16 Z" id="Title-mode"></path>
          </g>
        </g>
      </svg>
    );
});

export default Icon;