import React from 'react';

import DefaultButton from './Default';
import SimpleButton from './Simple';

export const buttons = {
  DEFAULT: 'DEFAULT',
  SIMPLE: 'SIMPLE'
};

const mapTypeToComponent = {
  [buttons.DEFAULT]: DefaultButton,
  [buttons.SIMPLE]: SimpleButton,
};

const Button = React.memo(({button = buttons.DEFAULT, ...rest}) => {
  let ButtonComponent = mapTypeToComponent[button];

  return (
    <ButtonComponent
      {...rest}
    />
  )
});

export default Button;