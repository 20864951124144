import {prefix} from './../config';

export const UPDATE_DATA = prefix + 'UPDATE_DATA';
export const UPDATE_UI = prefix + 'UPDATE_UI';

export const GET_FIRST_PAGE = prefix + 'GET_FIRST_PAGE';
export const LOAD_MORE = prefix + 'LOAD_MORE';
export const RECALCULATE_CREATED_TIME = prefix + 'RECALCULATE_CREATED_TIME';
export const DID_MOUNT = prefix + 'DID_MOUNT';
export const WILL_UNMOUNT = prefix + 'WILL_UNMOUNT';

export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});
export const updateUi = (updateOb) => ({type: UPDATE_UI, updateOb});

export const getFirstPage = () => ({type: GET_FIRST_PAGE});
export const loadMore = () => ({type: LOAD_MORE});
export const recalculateCreatedTime = () => ({type: RECALCULATE_CREATED_TIME});
export const didMount = () => ({type: DID_MOUNT});
export const willUnmount = () => ({type: WILL_UNMOUNT});