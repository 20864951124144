import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="9px" height="15px" viewBox="0 0 9 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-8.000000, -5.000000)" fill="#298FCA">
            <path d="M6.55088,8.30229333 C6.00182419,8.30179906 5.50660807,8.6323072 5.29640503,9.13953221 C5.08620199,9.64675723 5.20246462,10.230673 5.59092,10.6187 L11.5176667,16.5451333 C12.0479932,17.0741863 12.9064264,17.0743353 13.4369367,16.5454667 L19.3637033,10.6187 C19.8933196,10.0885382 19.8930985,9.22948641 19.3632094,8.6995973 C18.8333203,8.1697082 17.9742685,8.16948708 17.4441067,8.69910333 L12.4773133,13.6659 L7.51051333,8.69910333 C7.25614835,8.44445773 6.91080433,8.3016574 6.55088,8.30229333 Z" id="Arrow-right" transform="translate(12.476890, 12.622028) rotate(270.000000) translate(-12.476890, -12.622028) "></path>
          </g>
        </g>
      </svg>
    );
});

export default Icon;