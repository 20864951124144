import MainApp from './MainApp/redux/sagas';
import Chat from './Chat/redux/sagas/static';
import Notifications from './Notifications/redux/sagas/static';
import Settings from './Settings/redux/sagas/static';
import facebookService from './common/services/facebook/sagas';
import query from './common/services/query/sagas';
import session from './common/redux/session/sagas';
import data from './common/redux/data/sagas';
import formValidator from './common/services/form-validator/sagas';
import signalrSagas from './common/services/signalr';

const staticSagas = [
  MainApp,
  Chat,
  Notifications,
  Settings,
  facebookService,
  query,
  session,
  data,
  formValidator,
  signalrSagas
];

export default staticSagas;

