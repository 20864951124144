export const componentName = 'Modals';
export const domain = 'Modals';
export const prefix = 'MODALS::';

export const modalTypes = {
  BASIC_MODAL: 'BASIC_MODAL',
  EMAIL_CONFIRMATION_LINK_SENT: 'EMAIL_CONFIRMATION_LINK_SENT',
  EMAIL_IS_CONFIRMED: 'EMAIL_IS_CONFIRMED',
  AD_CREATED: 'AD_CREATED',
  PASSWORD_RESET: 'PASSWORD_RESET',
};