import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-5.000000, -5.000000)">
            <path d="M13.1818182,13.4090909 L14.6889952,17.2727273 L17.2727273,9.09090909 L13.1818182,13.4090909 Z M15.9090909,7.72727273 L7.72727273,10.3110048 L11.5909091,11.8181818 L15.9090909,7.72727273 Z M14.0557154,19.5355856 L11.5499193,13.4500807 L5.4644144,10.9442846 C4.82189292,10.6797169 4.85359997,9.75912052 5.51280169,9.53938662 L19.011619,5.03978087 C19.5978861,4.84435848 20.1556415,5.4021139 19.9602191,5.98838105 L15.4606134,19.4871983 C15.2408795,20.1464 14.3202831,20.1781071 14.0557154,19.5355856 Z" id="path-1"></path>
          </g>
        </g>
      </svg>
    );
});

export default Icon;