import {call, put, takeEvery} from 'redux-saga/effects';

import * as signalrServiceActions from './../../../../common/services/signalr/actions';
import showSnackbar from './../common/showSnackbar';
import {routes} from "../../../../common/config";

export function* handleBroadcast({payload}){
  let text = payload.text;

  yield call(showSnackbar, {
    text,
    withButton: true,
    buttonName: 'Åpne annonse',
    buttonLink: routes.VEHICLE_PAGE.buildPath(({id: payload.foreignObject.shortId}))
  });
}

export function* watchNewBroadCast(){
  yield takeEvery(signalrServiceActions.BROADCAST_OBJECT, handleBroadcast);
}