import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="16px" height="14px" viewBox="0 0 16 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-4.000000, -5.000000)" fill="#FFAB00">
            <path d="M19.8629045,17.5015557 C20.2494544,18.163659 19.7750522,19 18.9843818,19 L4.99830156,19 C4.22520165,19 3.75079943,18.163659 4.13734939,17.5015557 L11.1303895,5.49657747 C11.5169395,4.83447418 12.4833144,4.83447418 12.8698643,5.49657747 L19.8629045,17.5015557 Z M11.7620114,9.02863394 C11.31262,9.14024826 11,9.56810315 11,10.0145604 C11.0195388,10.3121986 11.0390775,10.6284392 11.0586163,10.9260774 C11.1172325,12.0608229 11.1953875,13.1955685 11.2540038,14.3303141 C11.2735425,14.7023618 11.6057013,15 11.9964764,15 C12.4067902,15 12.7194102,14.7023618 12.738949,14.3117117 C12.7584877,14.0698807 12.7584877,13.846652 12.7780265,13.6234234 C12.817104,12.8979303 12.8757203,12.1538349 12.9147978,11.4283418 C12.9343365,10.9446797 12.9538753,10.4796201 12.9929528,10.0145604 C13.0124915,9.86574133 12.9929528,9.69831985 12.9147978,9.54950076 C12.6998715,9.14024826 12.2309414,8.91701962 11.7620114,9.02863394 Z M11.990099,18 C12.5247525,18 12.980198,17.5490196 13,17.0196078 C13,16.4509804 12.5643564,16 12.009901,16 C11.4554455,16 11,16.4509804 11,17 C11,17.5490196 11.4356436,18 11.990099,18 Z" id="Warning"></path>
          </g>
        </g>
      </svg>
    );
});

export default Icon;