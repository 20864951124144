import React, {PureComponent} from 'react';

import Button from './../../elements/Button';

const isDev = process.env.NODE_ENV === 'development';

class ErrorBoundary extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      hasError: false,
      error: null
    }
  };
  static getDerivedStateFromError(error){
    console.log(error);

    return {
      hasError: true,
      error
    };
  };
  render(){
    let {children} = this.props;
    let {hasError, error} = this.state;

    if(hasError){
      let errorMessage = isDev ?
        error.message :
        'Noe gikk galt. Sjekk internettforbindelsen din og prøv igjen.';

      return (
        <div className="py-5 px-3 d-flex justify-content-center align-items-center flex-column">
          <h1 className="text-color1 text-center">
            <b>Noe gikk galt!</b>
          </h1>
          <h4 className="m-5 text-center">
            <b>{errorMessage}</b>
          </h4>
          <Button color="color1" className="mt-5" onClick={() => window.location.reload()}>
            Last inn siden på nytt
          </Button>
        </div>
      )
    } else {
      return children;
    };
  }
}

export default ErrorBoundary;
