import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1146.000000, -265.000000)" fill="#C1C7C9">
            <g transform="translate(100.000000, 248.000000)">
              <g>
                <path d="M1060.90769,17 C1062.06324,17 1063,17.8879013 1063,18.9831831 L1063,28.4033025 C1063.00038,28.6041086 1062.87293,28.7853018 1062.67722,28.8621955 C1062.48151,28.9390893 1062.2562,28.8964972 1062.10658,28.7543259 L1059.12191,25.9243237 L1051.49231,25.9243237 C1050.33676,25.9243237 1049.4,25.0364224 1049.4,23.9411407 L1049.4,18.9831831 C1049.4,17.8879013 1050.33676,17 1051.49231,17 L1060.90769,17 Z M1058.43779,27.1995492 L1059.6,28.3002157 C1059.53904,29.3484836 1058.62599,30.1701382 1057.51744,30.1743237 L1049.88138,30.1743237 L1046.89417,33.0043259 C1046.74443,33.1464972 1046.51892,33.1890893 1046.32305,33.1121955 C1046.12718,33.0353018 1045.99962,32.8541086 1046,32.6533025 L1046,23.2331831 C1046,22.1379013 1046.93755,21.25 1048.09408,21.25 L1048.09408,24.2247746 C1048.09408,25.8676972 1049.50041,27.1995492 1051.2352,27.1995492 L1058.43779,27.1995492 Z" id="Combined-Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
});

export default Icon;