import {prefix, modalTypes} from './../config';

export const UPDATE_UI = prefix + 'UPDATE_UI';

export const updateUi = (updateOb) => ({type: UPDATE_UI, updateOb});
export const showModal = ({modalType, payload}) => updateUi({showModal: modalType, payload});
export const showBasicModal = ({header, content, showOkButton, modalSize}) => updateUi({
  showModal: modalTypes.BASIC_MODAL,
  payload: {
    header,
    content,
    showOkButton,
    modalSize
  }
});