import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="20px" height="17px" viewBox="0 0 20 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-2.000000, -4.000000)" fill="#298FCA">
            <path d="M22,6.31823255 C22,5.03801458 20.9668889,4 19.6922222,4 C18.4177778,4 17.3844444,5.03779135 17.3844444,6.31823255 C17.3844444,7.32611122 18.0286667,8.17527411 18.9228889,8.4944915 L18.9228889,10.1818791 C18.9228889,11.0352833 18.2342222,11.727293 17.3844444,11.727293 L12.7693333,11.727293 L12.7693333,8.4944915 C13.6635556,8.17527411 14.3077778,7.32611122 14.3077778,6.31823255 C14.3077778,5.03801458 13.2746667,4 12,4 C10.7253333,4 9.69222222,5.03779135 9.69222222,6.31823255 C9.69222222,7.32611122 10.3364444,8.17527411 11.2306667,8.4944915 L11.2306667,11.727293 L5.07688889,11.727293 L5.07688889,8.4944915 C5.97111111,8.17527411 6.61533333,7.32611122 6.61533333,6.31823255 C6.61533333,5.03801458 5.58222222,4 4.30755556,4 C3.03288889,4 2,5.03779135 2,6.31823255 C2,7.32611122 2.64422222,8.17527411 3.53844444,8.4944915 L3.53844444,16.5052853 C2.64422222,16.8247259 2,17.6736656 2,18.6815442 C2,19.9617622 3.03311111,20.9997768 4.30777778,20.9997768 C5.58244444,20.9997768 6.61555556,19.9619854 6.61555556,18.6815442 C6.61555556,17.6736656 5.97133333,16.8245027 5.07711111,16.5052853 L5.07711111,13.272707 L11.2308889,13.272707 L11.2308889,16.5055085 C10.3366667,16.8247259 9.69244444,17.6738888 9.69244444,18.6817674 C9.69244444,19.9619854 10.7255556,21 12.0002222,21 C13.2748889,21 14.308,19.9622087 14.308,18.6817674 C14.308,17.6738888 13.6637778,16.8247259 12.7695556,16.5055085 L12.7695556,13.272707 L17.3848889,13.272707 C19.0815556,13.272707 20.4617778,11.8860088 20.4617778,10.1818791 L20.4617778,8.4944915 C21.3557778,8.17505088 22,7.32611122 22,6.31823255 Z" id="Transmission"></path>
          </g>
        </g>
      </svg>
    );
});

export default Icon;