import {prefix} from './../config';

export const UPDATE_UI = prefix + 'UPDATE_UI';
export const UPDATE_DATA = prefix + 'UPDATE_DATA';
export const UPDATE_INSTANCES = prefix + 'UPDATE_INSTANCES';

export const CREATE_INSTANCE = prefix + 'CREATE_INSTANCE';
export const SET_INSTANCE = prefix + 'SET_INSTANCE';
export const SAVE_INSTANCE = prefix + 'SAVE_INSTANCE';
export const UPDATE_EXTRA_OBJECT_PROPS = prefix + 'UPDATE_EXTRA_OBJECT_PROPS';
export const UPLOAD_PHOTOS = prefix + 'UPLOAD_PHOTOS';
export const ROTATE_PHOTO = prefix + 'ROTATE_PHOTO';
export const DELETE_PHOTO = prefix + 'DELETE_PHOTO';
export const REORDER_PHOTO = prefix + 'REORDER_PHOTO';
export const SEARCH_ADDRESS = prefix + 'SEARCH_ADDRESS';
export const GET_PREVIOUS_ADDRESS = prefix + 'GET_PREVIOUS_ADDRESS';
export const APPLY_PREVIOUS_ADDRESS = prefix + 'APPLY_PREVIOUS_ADDRESS';
export const SUBMIT = prefix + 'SUBMIT';
export const RESET_STATE_FOR_ANOTHER_AD = prefix + 'RESET_STATE_FOR_ANOTHER_AD';
export const LAUNCH_SEND_CODE_MODAL = prefix + 'LAUNCH_SEND_CODE_MODAL';
export const SEND_CODE = prefix + 'SEND_CODE';
export const CONFIRM_PHONE_NUMBER = prefix + 'CONFIRM_PHONE_NUMBER';
export const CLOSE_SEND_CODE_MODAL = prefix + 'CLOSE_SEND_CODE_MODAL';
export const USER_STARTED_TO_NEW_AD = prefix + 'USER_STARTED_TO_NEW_AD';
export const USER_SUBMITTED_AD = prefix + 'USER_SUBMITTED_AD';

export const updateUi = (updateOb) => ({type: UPDATE_UI, updateOb});
export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});
export const updateInstances = (updateOb) => ({type: UPDATE_INSTANCES, updateOb});

export const createInstance = ({rn}) => ({type: CREATE_INSTANCE, rn});
export const setInstance = ({id, action}) => ({type: SET_INSTANCE, id, action});
export const saveInstance = () => ({type: SAVE_INSTANCE});
export const updateExtraObjectProps = ({props, newValues}) => ({type: UPDATE_EXTRA_OBJECT_PROPS, props, newValues});
export const uploadPhotos = ({files}) => ({type: UPLOAD_PHOTOS, files});
export const rotatePhoto = ({id}) => ({type: ROTATE_PHOTO, id});
export const deletePhoto = ({id}) => ({type: DELETE_PHOTO, id});
export const reorderPhoto = ({id, oldIndex, newIndex}) => ({type: REORDER_PHOTO, id, oldIndex, newIndex});
export const searchAddress = () => ({type: SEARCH_ADDRESS});
export const getPreviousAddress = () => ({type: GET_PREVIOUS_ADDRESS});
export const applyPreviousAddress = () => ({type: APPLY_PREVIOUS_ADDRESS});
export const submit = () => ({type: SUBMIT});
export const resetStateForAnotherAd = () => ({type: RESET_STATE_FOR_ANOTHER_AD});
export const launchSendCodeModal = () => ({type: LAUNCH_SEND_CODE_MODAL});
export const sendCode = () => ({type: SEND_CODE});
export const confirmPhoneNumber = () => ({type: CONFIRM_PHONE_NUMBER});
export const closeSendCodeModal = () => ({type: CLOSE_SEND_CODE_MODAL});
export const userStartedToNewAd = () => ({type: USER_STARTED_TO_NEW_AD});
export const userSubmittedAd = () => ({type: USER_SUBMITTED_AD});