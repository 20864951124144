export const componentName = 'AddObject';
export const prefix = 'ADD_OBJECT::';
export const domain = componentName;

export const actions = {
  START: 'start',
  CREATE: 'create',
  EDIT: 'edit'
};

export const GENERAL_INFORMATION = {
  name: 'Generell informasjon',
  step: 1
};
export const APPEARANCE = {
  name: 'Utseende',
  step: 2
};
export const EQUIPMENT = {
  name: 'Utstyr',
  step: 3
};
export const CONDITION_AND_PRICE = {
  name: 'Tilstand og pris',
  step: 4
};
export const DESCRIPTION = {
  name: 'Beskrivelse',
  step: 5
};
export const CONTACT = {
  name: 'Kontaktinformasjon',
  step: 6
};

export const mapStepToConfig = {
  [GENERAL_INFORMATION.step]: GENERAL_INFORMATION,
  [APPEARANCE.step]: APPEARANCE,
  [EQUIPMENT.step]: EQUIPMENT,
  [CONDITION_AND_PRICE.step]: CONDITION_AND_PRICE,
  [DESCRIPTION.step]: DESCRIPTION,
  [CONTACT.step]: CONTACT
};

export const progressBarSteps = [
  mapStepToConfig[1],
  mapStepToConfig[2],
  mapStepToConfig[3],
  mapStepToConfig[4],
  mapStepToConfig[5],
  mapStepToConfig[6]
];