import React from 'react';
import {Link} from 'react-router-dom';

import * as commonConfig from '../../../common/config';
import * as userService from '../../../common/services/user';
import classname from '../../../common/helpers/classname';
import Avatar, {sizes as avatarSizes} from '../../../common/components/blocks/Avatar';

import './style.scss';

const componentName = 'SidenavMenu';
const cn = classname(componentName);

const SidenavMenu = React.memo(({isSideNavOpen, session, userAvatarUrl, totalNotifications, totalUnreadMessageCount, logout, toggleSideNav}) => {
  return (
    <div className={cn.join(componentName, isSideNavOpen && cn('open'))}>
      <div
        className={cn('back')}
        onClick={toggleSideNav}
      />
      <div className={cn('menu')}>
        {
          session.isAuthorized ?
            <React.Fragment>
              <div className={cn('menu-header')}>
                <Avatar
                  className={cn('user-avatar')}
                  image={userAvatarUrl}
                  size={avatarSizes.SMALL}
                />
                <h5 className={cn('user-name')}>
                  {userService.getFullName(session)}
                </h5>
              </div>
              <hr/>
            </React.Fragment>
            :
            null
        }
        {
          session.isAuthorized && userService.isAdmin(session) ?
            <React.Fragment>
              <Link
                className={cn('menu-link')}
                to={commonConfig.routes.ADMIN.path}
                onClick={toggleSideNav}
              >
                {commonConfig.routes.ADMIN.name}
              </Link>
              <hr/>
            </React.Fragment>
            :
            null
        }
        <Link
          className={cn('menu-link')}
          to={commonConfig.routes.BROWSE.path}
          onClick={toggleSideNav}>
          Søk
        </Link>
        {
          session.isAuthorized ?
            <React.Fragment>
              <Link
                className={cn('menu-link')}
                to={commonConfig.routes.NOTIFICATIONS.path}
                onClick={toggleSideNav}
              >
                <span>{commonConfig.routes.NOTIFICATIONS.name}</span>
                {
                  totalNotifications ?
                    <span className="text-color2 pl-3">
                      + {totalNotifications}
                    </span>
                    :
                    null
                }
              </Link>
              <Link
                className={cn('menu-link')}
                to={commonConfig.routes.CHAT.path}
                onClick={toggleSideNav}
              >
                <span>{commonConfig.routes.CHAT.name}</span>
                {
                  totalUnreadMessageCount ?
                    <span className="text-color2 pl-3">
                      + {totalUnreadMessageCount}
                    </span>
                    :
                    null
                }
              </Link>
              <Link
                className={cn('menu-link')}
                to={commonConfig.routes.MY_ADS.path}
                onClick={toggleSideNav}
              >
                {commonConfig.routes.MY_ADS.name}
              </Link>
              <Link
                className={cn('menu-link')}
                to={commonConfig.routes.SEARCH_TEMPLATES.path}
                onClick={toggleSideNav}
              >
                {commonConfig.routes.SEARCH_TEMPLATES.name}
              </Link>
              <Link
                className={cn('menu-link')}
                to={commonConfig.routes.SETTINGS.path}
                onClick={toggleSideNav}
              >
                {commonConfig.routes.SETTINGS.name}
              </Link>
            </React.Fragment>
            :
            null
        }
        <hr/>
        <Link
          className={cn('menu-link')}
          to={commonConfig.routes.PRIVACY.path}
          onClick={toggleSideNav}
        >
          {commonConfig.routes.PRIVACY.name}
        </Link>
        <Link
          className={cn('menu-link')}
          to={commonConfig.routes.CONTACT.path}
          onClick={toggleSideNav}
        >
          {commonConfig.routes.CONTACT.name}
        </Link>
        <hr/>
        <a
          className={cn('menu-link')}
          href={commonConfig.externalLinks.BLOG.path}
          target="_blank"
          rel="noopener"
        >
          {commonConfig.externalLinks.BLOG.name}
        </a>
        <hr/>
        {
          session.isAuthorized ?
            <div
              className={cn('menu-link')}
              onClick={() => {toggleSideNav(); logout()}}
            >
              Logg ut
            </div>
            :
            <Link
              className={cn('menu-link')}
              to={commonConfig.routes.LOGIN.path}
              onClick={toggleSideNav}
            >
              Logg inn
            </Link>
        }
      </div>
    </div>
  )
});

export default SidenavMenu;