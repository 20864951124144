import * as reduxSagaService from './../../../common/services/redux-saga';

import {triggerFetch} from './notifications/fetch';
import {watchNewNotification} from './notifications/handleNewNotification';
import {watchNewBroadCast} from './broadcasts/handleNewBroadcast';

export default reduxSagaService.createRootSaga(
  triggerFetch,
  watchNewNotification,
  watchNewBroadCast
);