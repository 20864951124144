import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
  return (
    <svg className={cn.join(componentName, className)} {...rest} width="13px" height="19px" viewBox="0 0 13 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-6.000000, -3.000000)" fill="#298FCA">
          <path d="M12.5,3 C8.90642857,3 6,5.90642857 6,9.5 C6,14.375 12.5,21.5714286 12.5,21.5714286 C12.5,21.5714286 19,14.375 19,9.5 C19,5.90642857 16.0935714,3 12.5,3 L12.5,3 Z M12.5,11.8214286 C11.2185714,11.8214286 10.1785714,10.7814286 10.1785714,9.5 C10.1785714,8.21857143 11.2185714,7.17857143 12.5,7.17857143 C13.7814286,7.17857143 14.8214286,8.21857143 14.8214286,9.5 C14.8214286,10.7814286 13.7814286,11.8214286 12.5,11.8214286 L12.5,11.8214286 Z" id="Location"></path>
        </g>
      </g>
    </svg>
  );
});

export default Icon;