const _prefix = 'QUERY_SERVICE::';

export const SYNC_REDUX_STORE_WITH_URL = _prefix + 'SYNC_REDUX_STORE_WITH_URL';
export const UPDATE_REDUX_STORE_AND_URL = _prefix + 'UPDATE_REDUX_STORE_AND_URL';

export const syncReduxStoreWithUrl = ({querySelector, queryUpdateActionCreator, extraArgs, urlProps, onEmptySearchString}) => ({
  type: SYNC_REDUX_STORE_WITH_URL,
  querySelector,
  queryUpdateActionCreator,
  extraArgs, // extra because first argument will be updateOb,
  urlProps,
  onEmptySearchString
});

export const updateReduxStoreAndUrl = ({querySelector, queryUpdateActionCreator, args, urlProps}) => ({
  type: UPDATE_REDUX_STORE_AND_URL,
  querySelector,
  queryUpdateActionCreator,
  args,
  urlProps
});