import {all, spawn, call, put} from 'redux-saga/effects';

import * as snackbarActions from './../../Snackbars/redux/actions';

const isDev = process.env.NODE_ENV === 'development';

// see: https://redux-saga.js.org/docs/advanced/RootSaga.html
export const createRootSaga = (...sagas) => {
  // root saga function
  return function*(){
    yield all(sagas.map(saga => {
      return spawn(function* (){
        while(true){
          try {
            yield call(saga);
            break;
          } catch(e){
            yield put(snackbarActions.pushErrorMessage({text: e.message}));
            if(isDev){
              console.log(e);
              break;
            }
          }
        }
      })
    }))
  }
};