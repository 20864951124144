import {prefix} from './../config';

export const UPDATE_UI = prefix + 'UPDATE_UI';

export const MAIN_APP_COMPONENT_DID_MOUNT = prefix + 'MAIN_APP_COMPONENT_DID_MOUNT';
export const REDIRECT_TO_LOGIN_PAGE = prefix + 'REDIRECT_TO_LOGIN_PAGE';

export const updateUi = (updateOb) => ({type: UPDATE_UI, updateOb});

export const mainAppComponentDidMount = () => ({type: MAIN_APP_COMPONENT_DID_MOUNT});
export const redirectToLoginPage = () => ({type: REDIRECT_TO_LOGIN_PAGE});