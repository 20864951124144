import {take, put} from 'redux-saga/effects';

import * as actions from './actions';
import * as reduxSagaService from './../../common/services/redux-saga';

export function* watchRedirectToLoginPage(){
  while(true){
    yield take(actions.REDIRECT_TO_LOGIN_PAGE);

    yield put(actions.updateUi({
      redirectToLoginPage: true
    }))
  }
}

export default reduxSagaService.createRootSaga(
  watchRedirectToLoginPage
);