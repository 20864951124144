import {select} from 'redux-saga/effects';

import {FIRST_PAGE, LOAD_MORE} from './../config';
import * as config from './../../../config';
import * as selectors from './../../selectors';

export default function* prepareParamsForGrid(options){
  let params = {};

  // page size

  if(options.type === FIRST_PAGE){
    params.pageSize = config.getBy * 2;
  } else if(options.type === LOAD_MORE){
    params.pageSize = config.getBy;
  }

  // current page

  let ui = yield select(selectors.getUi);

  if(options.type === LOAD_MORE){
    params.currentPage = ++ui.currentPage;
  } else if(options.type === FIRST_PAGE){
    params.currentPage = 0;
  }

  // * sorting

  params.sorting = [
    {
      columnName: 'date',
      direction: 'desc'
    }
  ];

  return params;
};