const _prefix = 'COMMONDATA::';

export const UPDATE_DATA = _prefix + 'UPDATE_DATA';

export const FETCH_MAKES = _prefix + 'FETCH_MAKES';
export const FETCH_MODELS = _prefix + 'FETCH_MODELS';
export const FETCH_BODY_TYPES = _prefix + 'FETCH_BODY_TYPES';
export const FETCH_WHEEL_DRIVES = _prefix + 'FETCH_WHEEL_DRIVES';
export const FETCH_EXTERIOR_COLORS = _prefix + 'FETCH_EXTERIOR_COLORS';
export const FETCH_EQUIPMENTS = _prefix + 'FETCH_EQUIPMENTS';
export const FETCH_WARRANTY_TYPES = _prefix + 'FETCH_WARRANTY_TYPES';
export const FETCH_TRANSMISSIONS = _prefix + 'FETCH_TRANSMISSIONS';
export const FETCH_ENGINE_FUELS = _prefix + 'FETCH_ENGINE_FUELS';
export const FETCH_CHARGER_CONNECTORS = _prefix + 'FETCH_CHARGER_CONNECTORS';
export const FETCH_AUTONOMY_LEVELS = _prefix + 'FETCH_AUTONOMY_LEVELS';
export const FETCH_REGISTRATION_CLASSES = _prefix + 'FETCH_REGISTRATION_CLASSES';
export const FETCH_OBJECT_LOCATIONS = _prefix + 'FETCH_OBJECT_LOCATIONS';
export const FETCH_LOCATION_COUNTRIES = _prefix + 'FETCH_LOCATION_COUNTRIES';
export const FETCH_LOCATION_DISTRICTS = _prefix + 'FETCH_LOCATION_DISTRICTS';
export const FETCH_MAKE_MODEL_TREE = _prefix + 'FETCH_MAKE_MODEL_TREE';

export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});

export const fetchMakes = () => ({type: FETCH_MAKES});
export const fetchModels = ({makeId}) => ({type: FETCH_MODELS, makeId});
export const fetchBodyTypes = () => ({type: FETCH_BODY_TYPES});
export const fetchWheelDrives = () => ({type: FETCH_WHEEL_DRIVES});
export const fetchExteriorColors = () => ({type: FETCH_EXTERIOR_COLORS});
export const fetchEquipments = () => ({type: FETCH_EQUIPMENTS});
export const fetchWarrantyTypes = () => ({type: FETCH_WARRANTY_TYPES});
export const fetchTransmissions = () => ({type: FETCH_TRANSMISSIONS});
export const fetchEngineFuels = () => ({type: FETCH_ENGINE_FUELS});
export const fetchChargerConnectors = () => ({type: FETCH_CHARGER_CONNECTORS});
export const fetchAutonomyLevels = () => ({type: FETCH_AUTONOMY_LEVELS});
export const fetchRegistrationClasses = () => ({type: FETCH_REGISTRATION_CLASSES});
export const fetchObjectLocations = () => ({type: FETCH_OBJECT_LOCATIONS});
export const fetchLocationCountries = () => ({type: FETCH_LOCATION_COUNTRIES});
export const fetchLocationDistricts = () => ({type: FETCH_LOCATION_DISTRICTS});
export const fetchMakeModelTree = () => ({type: FETCH_MAKE_MODEL_TREE});
