import {prefix} from  './../config';

export const UPDATE_UI = prefix + 'UPDATE_UI';
export const UPDATE_DATA = prefix + 'UPDATE_DATA';

export const SEND_CODE = prefix + 'SEND_CODE';
export const CONFIRM_PHONE_NUMBER = prefix + 'CONFIRM_PHONE_NUMBER';
export const REGISTER = prefix + 'REGISTER';
export const SUCCESSFUL_REGISTRATION_WITH_PHONE_NUMBER = prefix + 'SUCCESSFUL_REGISTRATION_WITH_PHONE_NUMBER';

export const updateUi = (updateOb) => ({type: UPDATE_UI, updateOb});
export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});

export const sendCode = () => ({type: SEND_CODE});
export const confirmPhoneNumber = () => ({type: CONFIRM_PHONE_NUMBER});
export const register = () => ({type: REGISTER});
export const successfulRegistrationWithPhoneNumber = () => ({type: SUCCESSFUL_REGISTRATION_WITH_PHONE_NUMBER});