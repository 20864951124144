import * as immutable from "./../../common/helpers/immutable";
import {rules} from './../../common/services/form-validator/config';
import inputTextModel from "./../../common/components/elements/InputText/models";

export const data = {
  grid: {}
};
export const ui = {
  currentPage: 0,
  showUntil: 0,
  blockHandleScrollFluid: false,
  isPageMounted: false,
  editSearchModal: {
    id: null,
    show: false,
    form: {
      description: immutable.updateObjectProps(inputTextModel, {
        id: 'description',
        type: 'textarea',
        label: 'Beskrivelse',
        rules: [rules.REQUIRED]
      }),
      frequency: 2
    }
  }
};