import superagent from 'superagent';
import {put, call, select} from 'redux-saga/effects';
import {delay} from 'redux-saga';
import jwtDecoder from 'jwt-decode';

import * as mainAppCompActions from './../../MainApp/redux/actions';
import * as processesActions from './../redux/processes/actions';
import * as processesSelectors from './../redux/processes/selectors';
import * as sessionActions from './../redux/session/actions';
import * as sessionSelectors from './../redux/session/selectors';
import * as snackbarActions from './../../Snackbars/redux/actions';

export const LOGIN = 'login';
export const REFRESH_TOKEN = 'refreshToken';
export const LOGIN_WITH_ONE_TIME_PASSWORD = 'loginWithOneTimePassword';
export const LOGIN_AFTER_REGISTRATION = 'loginAfterRegistration';
export const LOGIN_AFTER_RESET = 'loginAfterReset';
export const LOGIN_AFTER_EMAIL_IS_CONFIRMED = 'loginAfterEmailIsConfirmed';
export const LOGIN_WITH_FACEBOOK = 'loginWithFacebook';
export const loginTypes = [LOGIN, LOGIN_WITH_ONE_TIME_PASSWORD, LOGIN_AFTER_REGISTRATION, LOGIN_AFTER_RESET, LOGIN_AFTER_EMAIL_IS_CONFIRMED, LOGIN_WITH_FACEBOOK];

const customErrorMessages = {
  NETWORK_IS_OFFLINE: 'Kunne ikke koble til server. Prøv å logge ut og inn på nytt eller prøv igjen senere',
  OPEN_ID_ERROR: 'Du har blitt logget ut av Bilmarked og må logge inn igjen'
};

const doRequest = ({method, path, fullPath, isFormUrlencoded, params, session}) => {
  let req = superagent[method](fullPath || `${window.config.apiUrl}/${path}`);

  if(session && session.isAuthorized) req.set('Authorization', `Bearer ${session.access_token}`);

  if(isFormUrlencoded) req = req.type('form');

  if(params) req = method == 'get' ? req.query(params) : req.send(params);

  req.withCredentials();

  return new Promise((resolve) => {
    req.end((err, res) => {
      resolve([res, err]);
    })
  });
};

const checkForError = (response, error) => {
  let hasError = false;

  // synthetic error

  let e = new Error();

  e.payload = {};

  // * in case of server side generated error

  if(response && response.body && response.body.hasOwnProperty('errorCode')){
    hasError = true;

    let {errorCode, message, validationErrors} = response.body;

    e.message = message;
    e.status = response.status;
    e.payload = {
      isServerSideError: true,
      errorCode,
      validationErrors
    }

  // * in case of open id error

  } else if(response && response.body && response.body.hasOwnProperty('error') && response.body.hasOwnProperty('error_description')){
    hasError = true;

    let {error: errorType, error_description} = response.body;

    e.message = error_description;
    e.status = response.status;
    e.payload = {
      isOpenIdError: true,
      errorType
    }

  // * in case of normal error

  } else if(error && error instanceof Error){
    hasError = true;

    e.message = error.message;
    e.status = error.status;
    e.payload = {
      isHttpError: true,
    };

    if(e.message.includes('the network is offline, Origin is not allowed')){
      e.message = customErrorMessages.NETWORK_IS_OFFLINE;
      e.payload.isNetworkOfflineError = true;
    }
  }

  return {hasError, error: e};
};

const prepareSession = (openidResponse) => {

  // try to decode jwt

  let decodedIdToken;

  try {
    decodedIdToken = jwtDecoder(openidResponse.id_token);
  } catch (e) {
    return e;
  }

  let expirationTime = new Date().getTime() + (openidResponse.expires_in * 1000);

  // save results

  let session = {
    ...openidResponse,
    isAuthorized: true,
    expirationTime,
    decodedIdToken
  };

  return session;
};

function* getSession({type, username, password, currentSession, grantType, token}){

  let processName = 'getSession';

  // refresh tokens are one-time use and when refreshing them in parallel only one will "win"
  // so we must prevent new calls to openid when getSession process already is running

  let processes = yield select(processesSelectors.getProcesses, [processName]);

  if(processes[processName].inProcess){
    while(true){

      // sleep for some time

      yield delay(100/*ms*/);

      // check process one more time

      processes = yield select(processesSelectors.getProcesses, [processName]);

      // if process still running repeat

      if(processes[processName].inProcess) continue;

      // return result: error or session

      if(processes[processName].hasError){
        let error = new Error(processes[processName].errorMessage);
        error.status = processes[processName].status;
        return error;
      };

      return yield select(sessionSelectors.getSession);
    };
  };

  // do request

  yield put(processesActions.addProcess(processName));

  let params;

  if(type === LOGIN){
    params = {
      client_id: 'web',
      grant_type: 'password',
      scope: 'openid offline_access',
      username,
      password
    }
    // see: https://www.oauth.com/oauth2-servers/access-tokens/refreshing-access-tokens/
  } else if(type === REFRESH_TOKEN){
    params = {
      client_id: 'web',
      grant_type: 'refresh_token',
      refresh_token: currentSession.refresh_token
    }
  } else if(type === LOGIN_WITH_ONE_TIME_PASSWORD){
    params = {
      client_id: 'web',
      grant_type: 'onetimepassword',
      scope: 'openid offline_access',
      username,
      password
    };
  } else if([LOGIN_AFTER_RESET, LOGIN_AFTER_REGISTRATION, LOGIN_AFTER_EMAIL_IS_CONFIRMED].includes(type)){
    params = {
      client_id: 'web',
      scope: 'openid offline_access',
      grant_type: grantType,
      username,
      token
    }
  } else if(type === LOGIN_WITH_FACEBOOK){
    params = {
      client_id: 'web',
      scope: 'openid offline_access',
      grant_type: 'facebook_token',
      access_token: token,
      assertion: 'dummy'
    }
  }

  let [response, error] = yield call(doRequest, {
    method: 'post',
    isFormUrlencoded: true,
    fullPath: `${window.config.serverAddress}/connect/token`,
    processName,
    params
  });

  // check for error and insufficient response

  let errorResult = checkForError(response, error);

  if(errorResult.hasError){
    let {error: e} = errorResult;

    let currentSession = yield select(sessionSelectors.getSession);

    if(e.payload.isOpenIdError && currentSession.isAuthorized && ['invalid_grant', 'expired_token', 'invalid_token'].includes(e.payload.errorType)){
      yield put(sessionActions.unsetSession());
      yield put(mainAppCompActions.redirectToLoginPage());
      yield call(showErrorInSnackbar, {error: e});
    }

    yield put(processesActions.setError(processName, e.status, e.message, e.payload));

    return e;
  }

  let result = response.body;

  if(!result.id_token || !result.access_token || !result.refresh_token){
    let message = `Insufficient response type in ${processName} process`;
    yield put(processesActions.setError(processName, null, message));
    return new Error(message);
  }

  // prepare session

  let session = yield call(prepareSession, result);

  if(session instanceof Error){
    yield put(processesActions.setError(processName, null, session.message));
    return session;
  }

  // save it

  yield put(sessionActions.setSession(session));
  localStorage.setItem('session', JSON.stringify(session));

  // set success of process

  yield put(processesActions.setSuccess(processName, response.status));

  // dispatch successful login action

  if(loginTypes.includes(type))
    yield put(sessionActions.successfulLogin());

  return session;
};

export function* login({type = LOGIN, grantType, username, password, token, showError}){
  yield put(processesActions.addProcess(type));

  let result = yield call(getSession, {type, grantType, username, password, token});

  if(result instanceof Error){
    yield put(processesActions.setError(type, result));

    if(showError && !result.isOpenIdError)
      yield call(showErrorInSnackbar, {error: result});
  } else {
    yield put(processesActions.setSuccess(type, 200))
  }

  return result;
}

export function* showErrorInSnackbar({error, errorMessage}){
  if(error && !errorMessage){
    errorMessage = error.payload && error.payload.validationErrors ?
      error.payload.validationErrors[0].errorMessage :
      error.message
  }

  yield put(snackbarActions.pushErrorMessage({
    text: errorMessage
  }));
}

function* handleRegularRequest(descriptor){

  let {processName, returnWholeResponse, showError} = descriptor;

  // add process to processes if processName supplied

  if(processName) yield put(processesActions.addProcess(processName));

  // get current session

  let session = yield select(sessionSelectors.getSession);

  // refresh token if necessary

  let currentTime = new Date().getTime() - /*minus 5 min additionally*/(5*60*1000);

  if(session.isAuthorized && session.expirationTime < currentTime){

    let newSession = yield call(getSession, {type: REFRESH_TOKEN, currentSession: session});

    if(newSession instanceof Error){
      if(processName) yield put(processesActions.setError(processName, newSession.status, newSession.message));

      if(showError && !newSession.isOpenIdError)
        yield call(showErrorInSnackbar, {error: newSession});

      return newSession;
    }

    session = newSession;
  }

  // do request

  let [response, error] =  yield call(doRequest, {...descriptor, session});

  // check for error

  let errorResult = yield call(checkForError, response, error);

  if(errorResult.hasError){
    let {error: e} = errorResult;

    if(processName) yield put(processesActions.setError(processName, e));

    if(showError)
      yield call(showErrorInSnackbar, {error: e});

    return e;
  }

  // on success

  if(processName) yield put(processesActions.setSuccess(processName, response.status));

  if(returnWholeResponse)
    return response;
  else
    return response.body;
};

export const requestService = {
  get: function*(descriptor) { return yield call(handleRegularRequest, { method: 'get', ...descriptor}) },
  post: function* (descriptor) { return yield call(handleRegularRequest, { method: 'post', ...descriptor})},
  put: function* (descriptor) { return yield call(handleRegularRequest, { method: 'put', ...descriptor})},
  del: function* (descriptor) { return yield call(handleRegularRequest, { method: 'del', ...descriptor})},
  login: function* ({username, password}){
    return yield call(login, {
      type: LOGIN,
      username,
      password
    });
  },
  loginAfterRegistration: function* ({grantType, username, token}) {
    return yield call(login, {
      type: LOGIN_AFTER_REGISTRATION,
      grantType,
      username,
      token
    });
  },
  loginAfterReset: function* ({grantType, username, token}){
    return yield call(login, {
      type: LOGIN_AFTER_RESET,
      grantType,
      username,
      token
    });
  },
  loginAfterEmailIsConfirmed: function* ({grantType, username, token}){
    return yield call(login, {
      type: LOGIN_AFTER_EMAIL_IS_CONFIRMED,
      grantType,
      username,
      token
    });
  },
  loginWithFacebook: function* ({accessToken, showError}) {
    return yield call(login, {
      type: LOGIN_WITH_FACEBOOK,
      token: accessToken,
      showError
    });
  }
};

export default requestService;