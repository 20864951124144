import React, { Component } from 'react'
import {Switch, Route} from 'react-router-dom';
import 'react-tippy/dist/tippy.css';
// for devextreme react grid
import 'open-iconic/font/css/open-iconic-bootstrap.css';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

import './../common/styles/base.scss';
import gtm from './../common/services/seo/gtm';
import MainApp from './../MainApp';
import CookieConsent from './../common/components/blocks/CookieConsent';

import './style.scss';
import Footer from './Footer';

class App extends Component {
  render(){
    return (
      <div className="App">
        <Switch>
          {/*
          <Route path="/" exact strict component={Landing}/>
          */}
          <Route component={MainApp}/>
        </Switch>
        <CookieConsent/>
        <Footer/>
      </div>
    );
  }
};

export default App;
