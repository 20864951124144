export const pushToArray = (arr, value, toStart = false) => toStart ? [value, ...(arr || [])] : [...(arr || []), value];
export const updateValueInArrayByIndex = (arr, value, index) => {
  arr = [...(arr || [])];

  arr[index] = value;

  return arr;
};
export const removeFromArrayByIndex = (arr, index) => {
  if(index != -1){
    return [...(arr.slice(0, index) || []), ...(arr.slice(index+1) || [])];
  }

  return [...arr];
};
export const removeFromArray = (arr, value) => {
  let index = arr.indexOf(value);

  return removeFromArrayByIndex(arr, index);
};
export const moveValueInArray = (arr, from, to) => {
  let value = arr[from];
  let clearedArray = [...arr.slice(0, from), ...arr.slice(from+1)];
  let withMovedValue = [...clearedArray.slice(0, to), value, ...clearedArray.slice(to)];

  return withMovedValue;
};

/*
 arguments:

  (<ob>, <updateOb> [, <updateOb>, ...])

 returns:

  Object

 usage:

  <updateOb> contains values for properties that need to be updated/inserted/deleted in <ob> or previous derived object.
  for example: `updateObjectProps({a: 1, b: 2}, {a: 100, c: 3, d: 4}, {b: null, d: 400})` will return `{a: 100, c: 3, d: 400}`.

  To make `updateObjectProps` method return empty object, provide <updateOb> as null or undefined.
  for example: `updateObjectProps({a: 1, b: 2}, null)` will return `{}`.
*/

export const updateObjectProps = (...args) => {
  let ob = args[0];

  for(let i = 1; i < args.length; i++){

    let updateOb = args[i];

    // if updateOb is null or undefined return empty object

    let type = Object.prototype.toString.call(updateOb);

    if(type == '[object Undefined]' || type == '[object Null]'){
      return {};
    };

    ob = Object.assign(

      // first reducer that creates new object inheriting properties of <ob>
      // and ignoring properties that needs to be deleted

      Object.keys(ob).reduce(
        (wrapperOb, prop) => {

          let type = Object.prototype.toString.call(updateOb[prop]);

          // In fact we could write just `if(updateOb.hasOwnProperty(prop)){return wrapperOb} else {...}`
          // because in result current prop will be overridden by value of <updateOb>
          // BUT! we ignore value just when value is undefined or null
          // so order of keys in result object will remain in the same way

          if(updateOb.hasOwnProperty(prop) && (type == '[object Undefined]' || type == '[object Null]')){
            return wrapperOb;
          } else {
            wrapperOb[prop] = ob[prop];
          };

          return wrapperOb;
        },
        {}
      ),

      // second reducer that creates new object preparing values for properties that need to be updated
      // and ignoring properties that needs to be deleted

      Object.keys(updateOb).reduce(
        (obWithUpdatedProps, prop) => {

          let type = Object.prototype.toString.call(updateOb[prop]);

          // if prop`s value is undefined or null ignore it
          // we also ignored such properties in first reducer
          // so in result these properties are deleted from result object

          if(type == '[object Undefined]' || type == '[object Null]')
            return obWithUpdatedProps;

          // handle prop and if value type is object call current method recursively

          obWithUpdatedProps[prop] =
            type == '[object Object]' ?
              updateObjectProps(ob[prop] || {}, updateOb[prop]) :
              updateOb[prop];

          return obWithUpdatedProps;
        },
        {}
      )
    );
  };

  return ob;
};