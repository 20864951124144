import * as actions from './actions';
import * as immutable from './../../helpers/immutable';

let initState;

try {
  initState = JSON.parse(localStorage.getItem('session'));
} catch (e) {
  alert(e.message);
}

const session = (state = initState || {isAuthorized: false}, action) => {
  switch(action.type){
    case actions.UPDATE_SESSION:
      return immutable.updateObjectProps(state, action.updateOb);
    case actions.SET_SESSION:
      return action.session;
    case actions.UNSET_SESSION:
      return {isAuthorized: false};
    default:
      return state;
  }
};

export default session;