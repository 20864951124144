import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="16px" height="21px" viewBox="0 0 16 21" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-4.000000, -1.000000)" fill="#298FCA">
            <path d="M11.6666665,3.87499994 L11.6666665,1 L7.83333325,4.83333325 L11.6666665,8.6666665 L11.6666665,5.79166656 C14.8387498,5.79166656 17.4166664,8.36958317 17.4166664,11.5416664 C17.4166664,12.5095831 17.177083,13.4295831 16.7458331,14.2249997 L18.1449997,15.6241663 C18.8924997,14.4454164 19.333333,13.0462497 19.333333,11.5416664 C19.333333,7.3058332 15.9024997,3.87499994 11.6666665,3.87499994 L11.6666665,3.87499994 Z M11.6666665,17.2916663 C8.49458324,17.2916663 5.91666663,14.7137497 5.91666663,11.5416664 C5.91666663,10.5737498 6.15624995,9.65374981 6.58749994,8.85833316 L5.18833331,7.45916653 C4.44083332,8.6379165 4,10.0370831 4,11.5416664 C4,15.7774997 7.43083326,19.2083329 11.6666665,19.2083329 L11.6666665,22.0833329 L15.4999998,18.2499996 L11.6666665,14.4166664 L11.6666665,17.2916663 L11.6666665,17.2916663 Z" id="Update"></path>
          </g>
        </g>
      </svg>
    );
});

export default Icon;