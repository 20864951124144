import React from 'react';
import {Link} from 'react-router-dom';

import './styles.scss';
import classname from './../../common/helpers/classname';
import Button, {buttons} from './../../common/components/elements/Button';

const componentName = 'NewNotificationSnackbarContent';
const cn = classname(componentName);

const SnackbarContent = React.memo(({text, withButton, buttonName, buttonLink}) => {
  return (
    <React.Fragment>
      <p>
        {text}
      </p>
      {
        withButton ?
          <Button
            className={cn('open-button')}
            component={Link}
            to={buttonLink}
            button={buttons.SIMPLE}
          >
            {buttonName}
          </Button>
          :
          null
      }
    </React.Fragment>
  )
});

export default SnackbarContent;