import React from 'react';
import {put} from 'redux-saga/effects';

import * as snackbarsActions from './../../../../Snackbars/redux/actions';
import SnackbarContent from './../../../SnackbarContent';

export default function* showSnackbar({header, faIcon, text, withButton, buttonName, buttonLink}){
  header = header || 'Nytt varsel';

  yield put(snackbarsActions.pushInfoMessage({
    lifeTime: 5000,
    withBeep: true,
    header,
    faIcon,
    text: (
      <SnackbarContent
        text={text}
        withButton={withButton}
        buttonName={buttonName}
        buttonLink={buttonLink}
      />
    )
  }))
}