import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import './style.scss';
import * as helpers from './../../../helpers';
import classname from './../../../helpers/classname';

const componentName = 'CustomModal';
const cn = classname(componentName);

class CustomModal extends Component {
  constructor(props){
    super(props);
    this.rootElement = document.getElementById('root');
  };
  onWrapperClick = (e) => {
    let {closeOnBackdropClick = true, onClose} = this.props;

    if(closeOnBackdropClick && e.target.id === cn('wrapper') && onClose)
      onClose();
  };
  componentDidMount(){
    document.body.style.overflowY = 'hidden';
    document.body.style.marginRight = `${helpers.getScrollbarWidth()}px`;
  };
  componentWillUnmount(){
    document.body.style.overflowY = 'scroll';
    document.body.style.marginRight = 0;
  };
  render(){
    let {
      className,
      backdropClassName,
      contentClassName,
      translucentBackdrop = true,
      transparentBackdrop = false,
      children
    } = this.props;

    className = cn.join(componentName, className);
    backdropClassName = cn.join(
      cn('backdrop'),
      backdropClassName,
      translucentBackdrop && cn('backdrop-translucent'),
      transparentBackdrop && cn('backdrop-transparent')
    );
    contentClassName = cn.join(cn('content'), contentClassName, 'fade-in-with-scale');

    return (
      ReactDOM.createPortal(
        <div className={className}>
          <div className={backdropClassName}/>
          <div id={cn('wrapper')} className={cn('wrapper')} onClick={this.onWrapperClick}>
            <div className={contentClassName}>
              {children}
            </div>
          </div>
        </div>
        ,
        this.rootElement
      )
    );
  };
};

export default CustomModal;