import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="11px" height="14px" viewBox="0 0 11 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-554.000000, -431.000000)" fill="#298FCA">
            <g transform="translate(535.000000, 368.000000)">
              <g transform="translate(0.000000, 19.000000)">
                <path d="M27.5770925,44.2317881 L29.7577093,46.3178808 C29.9515419,46.5033113 30,46.6887417 30,46.9205298 L30,57.2119205 C30,57.6754967 29.660793,58 29.1762115,58 L19.8237885,58 C19.3876652,58 19,57.6754967 19,57.2119205 L19,44.7880795 C19,44.3708609 19.3876652,44 19.8237885,44 L26.9955947,44 C27.1894273,44 27.3832599,44.0927152 27.5770925,44.2317881 Z M26.9471366,44.9735099 L26.9471366,46.410596 L28.4977974,46.410596 L26.9471366,44.9735099 Z M29.030837,47.3377483 L26.8017621,47.3377483 C26.3656388,47.3377483 25.9779736,46.9668874 25.9779736,46.5496689 L25.9779736,44.9271523 L19.969163,44.9271523 L19.969163,57.0728477 L29.030837,57.0728477 L29.030837,47.3377483 Z M21.9649231,48.9411765 L27.0350769,48.9411765 C27.6036923,48.9411765 27.6036923,49.7647059 27.0350769,49.7647059 L21.9649231,49.7647059 C21.3963077,49.7647059 21.3963077,48.9411765 21.9649231,48.9411765 Z M21.9649231,51.4117647 L27.0350769,51.4117647 C27.6036923,51.4117647 27.6036923,52.2352941 27.0350769,52.2352941 L21.9649231,52.2352941 C21.3963077,52.2352941 21.3963077,51.4117647 21.9649231,51.4117647 Z M21.9649231,53.8823529 L27.0350769,53.8823529 C27.6036923,53.8823529 27.6036923,54.7058824 27.0350769,54.7058824 L21.9649231,54.7058824 C21.3963077,54.7058824 21.3963077,53.8823529 21.9649231,53.8823529 Z" id="File"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
});

export default Icon;