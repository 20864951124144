import MainApp from './MainApp/redux/reducer';
import Chat from './Chat/redux/reducer';
import Notifications from './Notifications/redux/reducer';
import Settings from './Settings/redux/reducer';
import CookieConsent from './common/components/blocks/CookieConsent/redux/reducer';
import processes from './common/redux/processes/reducer';
import session from './common/redux/session/reducer';
import data from './common/redux/data/reducer';

const staticReducers = {
  MainApp,
  Chat,
  Notifications,
  Settings,
  CookieConsent,
  data,
  processes,
  session
};

export default staticReducers;