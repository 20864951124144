import {call} from 'redux-saga/effects';
import {DateTime} from 'luxon';

import * as actions from './../../actions';
import * as helpers from './../../../../common/helpers';
import * as service from './../../../../common/services';
import * as immutable from './../../../../common/helpers/immutable';
import {messageSendStatuses} from './../../../config';

export function* setSendStatus({messageOb, status}){
  return immutable.updateObjectProps(messageOb, {
    derived: {
      sendStatus: status
    }
  })
}

export function* setSendStatusToFailed({messageOb}){
  return yield call(setSendStatus, {messageOb, status: messageSendStatuses.FAILED})
}

export default function* extend(message, {isNewMessage = false} = {}){
  let date = DateTime.fromISO(message.createdDate).setLocale('nb');

  let derived = {
    isImageFile: false,
    thumbnail: null,
    createdDateText: '',
    sendStatus: ''
  };

  if(isNewMessage){
    derived.sendStatus = messageSendStatuses.SENDING;
  } else {
    derived.sendStatus = messageSendStatuses.SENT;
  }

  if(message.file && helpers.isImageFile(message.file.fileName)){
    derived.isImageFile = true;
    derived.thumbnail = service.prepareFileLink(message.file.urlThumbnail);
  };

  if(date.year === DateTime.local().year){
    derived.createdDateText = date.toLocaleString({month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit'});
  } else {
    derived.createdDateText = date.toLocaleString({year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit'});
  };

  message.derived = derived;

  return message;
};