import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
  return (
    <svg className={cn.join(componentName, className)} {...rest} width="22px" height="19px" viewBox="0 0 22 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons-/-Interface-/-Like" transform="translate(-1.000000, -3.000000)" fill="#EF7564">
          <path d="M21.2230015,4.76392573 C20.0710008,3.63226429 18.5458825,3.01374488 16.9181568,3.01374488 C15.2904311,3.01374488 13.7606488,3.63684591 12.6086481,4.76850735 L12.0069959,5.35953701 L11.3960157,4.7593441 C10.244015,3.62768266 8.70956874,3 7.08184304,3 C5.45878131,3 3.928999,3.62310104 2.78166226,4.75018085 C1.62966155,5.8818423 0.995361568,7.38461538 1.00002554,8.9836026 C1.00002554,10.5825898 1.63898949,12.0807813 2.7909902,13.2124427 L11.5499268,21.816735 C11.67119,21.9358572 11.834429,22 11.993004,22 C12.1515789,22 12.3148179,21.9404389 12.4360811,21.8213166 L21.2136736,13.2307692 C22.3656743,12.0991078 22.9999743,10.5963347 22.9999743,8.99734748 C23.0046382,7.39836026 22.3750022,5.89558717 21.2230015,4.76392573 Z M20.3275192,12.3556788 L11.993004,20.5109718 L3.67714459,12.3419339 C2.76300638,11.4439354 2.25929757,10.2527128 2.25929757,8.9836026 C2.25929757,7.7144924 2.75834241,6.52326983 3.67248062,5.62985291 C4.58195486,4.73643598 5.79458719,4.24162045 7.08184304,4.24162045 C8.37376286,4.24162045 9.59105916,4.73643598 10.5051974,5.63443453 L11.5592547,6.66988184 C11.8064451,6.91270798 12.2028826,6.91270798 12.4500731,6.66988184 L13.4948024,5.64359778 C14.4089407,4.74559923 15.626237,4.2507837 16.9134928,4.2507837 C18.2007487,4.2507837 19.413381,4.74559923 20.3275192,5.63901616 C21.2416574,6.53701471 21.7407022,7.72823728 21.7407022,8.99734748 C21.7453662,10.2664577 21.2416574,11.4576803 20.3275192,12.3556788 Z" id="Like"></path>
        </g>
      </g>
    </svg>
  );
});

export default Icon;