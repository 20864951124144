import {types} from './../config';

const _prefix = 'SNACKBARS::';

export const UPDATE_UI = _prefix + 'UPDATE_UI';

export const PUSH = _prefix + 'PUSH';
export const PUSH_SUCCESS_MESSAGE = _prefix + 'PUSH_SUCCESS_MESSAGE';
export const PUSH_ERROR_MESSAGE = _prefix + 'PUSH_ERROR_MESSAGE';

export const updateUi = (updateOb) => ({type: UPDATE_UI, updateOb});

export const push = ({header, type, text, lifeTime, withBeep = false, icon, faIcon}) => {
  return {type: PUSH, sType: type, header, text, lifeTime, withBeep, icon, faIcon};
};
export const pushInfoMessage = (params) => push({type: types.INFO, ...params});
export const pushWarningMessage = (params) => push({type: types.WARNING, ...params});
export const pushSuccessMessage = (params) => push({type: types.SUCCESS, ...params});
export const pushErrorMessage = (params) => push({type: types.ERROR, ...params});