import {take, takeEvery, call, select, put} from 'redux-saga/effects';
import {delay, eventChannel} from 'redux-saga';
import {HubConnectionBuilder as SignalrHubConnectionBuilder} from '@aspnet/signalr';

import * as helpers from './../../helpers';
import * as reduxService from './../redux-saga';
import * as sessionSelectors from './../../redux/session/selectors';
import * as actions from './actions';
import * as sessionActions from './../../redux/session/actions';
import * as mainAppCompActions from './../../../MainApp/redux/actions';
import * as snackbarActions from './../../../Snackbars/redux/actions';
import {texts as snackbarTexts} from './../../../Snackbars/config';

const events = {
  NEW_THREAD_MESSAGE: {
    name: 'NewThreadMessage',
    action: actions.newThreadMessage
  },
  DISMISSED_THREAD_MESSAGE: {
    name: 'DismissedThreadMessages',
    action: actions.dismissedThreadMessages
  },
  TYPING_IN_THREAD: {
    name: 'TypingInThread',
    action: actions.typingInThread
  },
  NOTIFICATION: {
    name: 'Notification',
    action: actions.notification
  },
  BROADCAST_OBJECT: {
    name: 'BroadcastObject',
    action: actions.broadcastObject
  },
  PROFILE_IS_UPDATED: {
    name: 'ProfileUpdated',
    action: actions.profileIsUpdated
  },
  MAKE_MODEL_IS_UPDATED: {
    name: 'MakeModelUpdated',
    action: actions.makeModelIsUpdated
  },
  RECOUNT_OBJECT_STATUS: {
    name: 'RecountObjectStatus',
    action: actions.recountObjectStatus
  }
};

let {CONNECTION_ERROR, CHANNEL_IS_CLOSED} = actions;
let {SUCCESSFUL_LOGIN, UNSET_SESSION} = sessionActions;
let {MAIN_APP_COMPONENT_DID_MOUNT} = mainAppCompActions;

let channel;
let connection;
let connectionIsEstablished = false;

export function createEventChannel({accessToken}){
  return eventChannel(emit => {
    let url = !!accessToken ?
      `${window.config.hubAddress}?access_token=${encodeURIComponent(accessToken)}` :
      window.config.hubAddress;

    // create connection

    connection = new SignalrHubConnectionBuilder()
      .withUrl(url)
      .build();

    // attach listeners

    for(let key in events){
      connection.on(events[key].name, function (payload){
        emit(events[key].action({payload}));
      });
    }

    // on close (will be called only when connection is closed by backend)

    connection.onclose((e) => emit(e && e.message ? e : new Error('Tjener avsluttet socket-forbindelse'))); // Socket connection is closed by backend

    // start connection

    connection.start()
      .then(() => {
        console.log('Socket connection is started');
        connectionIsEstablished = true;
      })
      .catch((e) => {
        emit(e && e.message ? e : new Error('Uventet feil på socket-forbindelse')) // Unexpected error in socket connection
      });

    // unsubscribe function

    return () => {
      for(let key in events)
        connection.off(events[key].name);

      connection.stop()
        .then(() => {
          console.log('Socket connection is stopped');
          connectionIsEstablished = false;
        })
        .catch((e) => emit(e && e.message ? e : new Error('Uventet feil ved avslutting av socket-forbindelse'))); // Unexpected error when closing socket connection
    };
  })
};

export function* openEventChannel(){
  let session = yield select(sessionSelectors.getSession);

  if(channel)
    return;

  // create channel

  channel = yield call(createEventChannel, {accessToken: session.access_token});

  // listen for events

  let hasError = false;
  let error = null;

  try {
    while(true){
      let actionObject = yield take(channel);

      yield put(actionObject);
    }

    // whenever error is occurred catch block will be executed

  } catch(e){
    hasError = true;
    error = e;

    // whenever channel is closed or error is occurred finally block will be executed
    // for example, `yield take(channel)` will receive the `END` whenever `emit(END)` or `channel.close()` is executed
    // this means that channel is closed and this will cause the saga to terminate by jumping to the finally block

  } finally {
    if(hasError){
      yield put(actions.connectionError({error}));
    } else {
      yield put(actions.channelIsClosed())
    }
  }
};

export function* restartEventChannel({delayTime = 3000}){
  yield delay(delayTime);
  yield call(openEventChannel);
}

export function* closeEventChannel(){
  if(!channel)
    return;

  channel.close();
  channel = null;
}

export function* manageEventChannel(action){
  if([MAIN_APP_COMPONENT_DID_MOUNT].includes(action.type)){
    yield call(openEventChannel);
  } else if([SUCCESSFUL_LOGIN, UNSET_SESSION].includes(action.type)){
    yield call(closeEventChannel);
    yield call(restartEventChannel, {delayTime: 0});
  } else if([CONNECTION_ERROR].includes(action.type)){
    yield call(closeEventChannel);
    yield call(restartEventChannel, {delayTime: 3000});
    yield put(snackbarActions.pushErrorMessage({
      text: helpers.isDev ? action.error.message : snackbarTexts.e1
    }));
  }
}

export function* watchForTriggerActions(){
  yield takeEvery([
      MAIN_APP_COMPONENT_DID_MOUNT,
      SUCCESSFUL_LOGIN,
      UNSET_SESSION,
      CHANNEL_IS_CLOSED,
      CONNECTION_ERROR
    ],
    manageEventChannel
  );
}

export function* watchSendTypingInThread(){
  while(true){
    let {threadId} = yield take(actions.SEND_TYPING_IN_THREAD);

    if(!connectionIsEstablished)
      continue;

    try {
      yield call([connection, connection.invoke], events.TYPING_IN_THREAD.name, threadId);
    } catch(error){
      yield put(actions.connectionError({error}));
    }
  }
}

export default reduxService.createRootSaga(
  watchForTriggerActions,
  watchSendTypingInThread
);