const _prefix = 'CHAT::';

export const UPDATE_DATA = _prefix + 'UPDATE_DATA';
export const UPDATE_UI = _prefix + 'UPDATE_UI';

export const FETCH_THREADS = _prefix + 'FETCH_THREADS';
export const SELECT_THREAD = _prefix + 'SELECT_THREAD';
export const SEND_MESSAGE = _prefix + 'SEND_MESSAGE';
export const LOAD_MORE_MESSAGES = _prefix + 'LOAD_MORE_MESSAGES';
export const LOAD_MORE_THREADS = _prefix + 'LOAD_MORE_THREADS';
export const HANDLE_FILE = _prefix + 'HANDLE_FILE';
export const USER_IS_TYPING = _prefix + 'USER_IS_TYPING';
export const SET_MESSAGES_AS_READ = _prefix + 'SET_MESSAGES_AS_READ';

export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});
export const updateUi = (updateOb) => ({type: UPDATE_UI, updateOb});

export const fetchThreads = () => ({type: FETCH_THREADS});
export const selectThread = ({thread}) => ({type: SELECT_THREAD, thread});
export const sendMessage = () => ({type: SEND_MESSAGE});
export const loadMoreMessages = () => ({type: LOAD_MORE_MESSAGES});
export const loadMoreThreads = () => ({type: LOAD_MORE_THREADS});
export const handleFile = ({file}) => ({type: HANDLE_FILE, file});
export const userIsTyping = () => ({type: USER_IS_TYPING});
export const setMessagesAsRead = ({thread, messages} = {}) => ({type: SET_MESSAGES_AS_READ, thread, messages});
