import React, {PureComponent} from 'react';

import Plus from './Plus';
import ChevronRightLight from './ChevronRightLight';
import ChevronLeftLight from './ChevronLeftLight';
import ChevronDownLight from './ChevronDownLight';
import ChevronUpLight from './ChevronUpLight';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import MyAnnouncements from './MyAnnouncements';
import Settings from './Settings';
import Like from './Like';
import Search from './Search';
import List from './List';
import Map from './Map';
import Location from './Location';
import LikePressed from './LikePressed';
import LongArrowLeft from './LongArrowLeft';
import LongArrowRight from './LongArrowRight';
import Bars from './Bars';
import Close from './Close';
import Warning from './Warning';
import Exit from './Exit';
import Circle from './Circle';
import Photos from './Photos';
import Fuel from './Fuel';
import Transmission from './Transmission';
import Speed from './Speed';
import Check from './Check';
import Phone from './Phone';
import Chat from './Chat';
import Bell from './Bell';
import Send from './Send';
import User from './User';
import File from './File';
import Attachment from './Attachment';
import Car from './Car';
import Update from './Update';

export const icons = {
  PLUS: Plus,
  CHEVRON_DOWN_LIGHT: ChevronDownLight,
  CHEVRON_UP_LIGHT: ChevronUpLight,
  CHEVRON_RIGHT_LIGHT: ChevronRightLight,
  CHEVRON_LEFT_LIGHT: ChevronLeftLight,
  CHEVRON_LEFT: ChevronLeft,
  CHEVRON_RIGHT: ChevronRight,
  MY_ANNOUNCEMENTS: MyAnnouncements,
  SETTINGS: Settings,
  SEARCH: Search,
  LIKE: Like,
  LIST: List,
  MAP: Map,
  LOCATION: Location,
  LIKE_PRESSED: LikePressed,
  LONG_ARROW_LEFT: LongArrowLeft,
  LONG_ARROW_RIGHT: LongArrowRight,
  BARS: Bars,
  CLOSE: Close,
  WARNING: Warning,
  EXIT: Exit,
  CIRCLE: Circle,
  PHOTOS: Photos,
  FUEL: Fuel,
  SPEED: Speed,
  TRANSMISSION: Transmission,
  CHECK: Check,
  PHONE: Phone,
  CHAT: Chat,
  BELL: Bell,
  SEND: Send,
  USER: User,
  FILE: File,
  ATTACHMENT: Attachment,
  CAR: Car,
  UPDATE: Update,
};

class Icon extends PureComponent {
  render(){
    let {icon: I, ...rest} = this.props;

    return (
      <I {...rest}/>
    )
  }
};

export default Icon;