import {DateTime} from "luxon";

export const getDate = (date) => {
  date = new Date(date);

  let day = date.getDate();
  day = day < 10 ? '0' + day : day;
  let month = date.getMonth() + 1;
  month = month < 10 ? '0' + month : month;
  let year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

// ---------

const isToday = ({date}) => {
 return date.toRelativeCalendar({locale: 'en'}) === 'today';
};

const wasYesterday = ({date}) => {
  return date.toRelativeCalendar({locale: 'en'}) === 'yesterday';
};

export const getPrettyTime = ({isoDate}) => {
  let end = DateTime.local();
  let start = DateTime.fromISO(isoDate);

  let diffInHours = Math.abs(end.diff(start, ['hours']).hours);

  if(isToday({date: start})){
    if(diffInHours > 3){
      return `I dag klokken ${start.hour}:${start.minute <= 9 ? `0${start.minute}` : start.minute}`; // 'Today at ...'
    } else {
      if(diffInHours > 1){
        let total = Math.floor(diffInHours);

        return total == 1 ? `For en time siden` : `For ${total} timer siden`;
      } else if(diffInHours * 60 > 1){
        let total = Math.floor(diffInHours * 60);

        return total == 1 ? `For et minutt siden` : `For ${total} minutter siden`;
      } else {
        let total = Math.floor(diffInHours*60*60) || 1;

        return total == 1 ? `For et sekund siden` : `For ${total} sekunder siden`;
      }
    }
  } else if(wasYesterday({date: start})){
    return `I går klokka ${start.hour}:${start.minute <= 9 ? `0${start.minute}` : start.minute}`;
  } else {
    let date = DateTime.fromISO(isoDate).setLocale('nb');

    if(date.year === DateTime.local().year){
      return date.toLocaleString({month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit'});
    } else {
      return date.toLocaleString({year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit'});
    };
  }
};