import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import RegularModal, {modalHeaderTypes, modalSizes} from './../../common/components/blocks/RegularModal';
import Button, {buttons} from './../../common/components/elements/Button';

class BasicModal extends PureComponent {
  render(){
    let {
      payload: {
        header = 'Basic Header',
        content = 'Content',
        showOkButton = true,
        modalSize =  modalSizes.SMALL
      } = {},
      onClose
    } = this.props;

    return (
      <RegularModal
        header={header}
        onClose={onClose}
        size={modalSize}
      >
        <div className={`${showOkButton ? 'mb-2rem' : ''} font-weight-custom-bold`}>
          {content}
        </div>
        {
          showOkButton ?
            <div className="text-center">
              <Button
                className="px-5"
                color="color1"
                onClick={onClose}
              >
                Ok
              </Button>
            </div>
            :
            null
        }
      </RegularModal>
    )
  }
};

export default connect()(BasicModal);