import React from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSlidersH, faRss} from "@fortawesome/free-solid-svg-icons";

import * as userService from '../../../common/services/user';
import * as commonConfig from '../../../common/config';
import classname from '../../../common/helpers/classname';
import DropdownMenu, {DropdownItem} from '../../../common/components/elements/DropdownMenu';
import Icon, {icons} from '../../../common/components/elements/Icon';
import Avatar, {sizes as avatarSizes} from '../../../common/components/blocks/Avatar';

import './style.scss';

let componentName = 'UserDropdownMenu';
let cn = classname(componentName);

const UserDropdown = ({className, session, userAvatarUrl, logout}) => {
  if(!session.isAuthorized){
    return (
      <Link className={`${componentName}-login-button`} to="/login">
        Logg inn
      </Link>
    )
  }

  return (
    <DropdownMenu
      name={componentName}
      className={cn.join(componentName, className)}
      toggleButtonClassName="font-weight-custom-bold"
      buttonText={
        <div className="d-flex align-items-center">
          <Avatar image={userAvatarUrl} size={avatarSizes.SMALL} className="text-color1"/>
          <div className="ml-2 line-height-1">{userService.getFullName(session)}
          </div>
        </div>
      }
      withMargin={false}
    >
      {
        userService.isAdmin(session) ?
          <React.Fragment>
            <DropdownItem
              className={cn('menu-item')}
              component={Link}
              to={commonConfig.routes.ADMIN.path}>
              <FontAwesomeIcon
                icon={faSlidersH}
                className={cn('menu-item-icon')}
              />
              <span>{commonConfig.routes.ADMIN.name}</span>
            </DropdownItem>
            <hr/>
          </React.Fragment>
          :
          null
      }
      <DropdownItem
        className={cn('menu-item')}
        component={Link}
        to={commonConfig.routes.MY_ADS.path}>
        <Icon
          icon={icons.MY_ANNOUNCEMENTS}
          className={cn('menu-item-icon')}
        />
        <span>{commonConfig.routes.MY_ADS.name}</span>
      </DropdownItem>
      <DropdownItem
        className={cn('menu-item')}
        component={Link}
        to={commonConfig.routes.SEARCH_TEMPLATES.path}>
        <FontAwesomeIcon
          className={cn('menu-item-icon')}
          icon={faRss}
        />
        <span>{commonConfig.routes.SEARCH_TEMPLATES.name}</span>
      </DropdownItem>
      <DropdownItem
        className={cn('menu-item')}
        component={Link}
        to={commonConfig.routes.SETTINGS.path}>
        <Icon
          icon={icons.SETTINGS}
          className={cn('menu-item-icon')}
        />
        <span>{commonConfig.routes.SETTINGS.name}</span>
      </DropdownItem>
      <hr/>
      <DropdownItem
        className={cn('menu-item')}
        onClick={logout}
      >
        <Icon
          icon={icons.EXIT}
          className={cn('menu-item-icon')}
        />
        <span>Logg ut</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

export default UserDropdown;