import {put, select, takeEvery} from 'redux-saga/effects';

import * as sessionSelectors from '../../../../common/redux/session/selectors';
import * as actions from '../../actions';
import * as models from '../../models';
import * as immutable from '../../../../common/helpers/immutable';
import * as processesActions from './../../../../common/redux/processes/actions';
import {processNames} from './../config';

export function* resetProfileForm(){
  let session = yield select(sessionSelectors.getSession);

  if(!session || !session.decodedIdToken)
    return;

  yield put(actions.updateUi({
    profileForm: immutable.updateObjectProps(models.ui.profileForm, {
      firstName: {value: session.decodedIdToken.firstName},
      lastName: {value: session.decodedIdToken.lastName},
      email: {value: session.decodedIdToken.email},
      phoneNumber: {value: session.decodedIdToken.phoneNumber},
    })
  }));

  yield put(processesActions.update({[processNames.UPDATE_PROFILE]: null}))
};

export function* watchResetProfileForm(){
  yield takeEvery(actions.RESET_PROFILE_FORM, resetProfileForm);
}