import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="18px" height="17px" viewBox="0 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-3.000000, -4.000000)" fill="#298FCA">
            <path d="M18.9461538,14.0615385 L20.8384615,20.0615385 L3,20.0615385 L4.86923077,14.0828908 L9.32307692,14.0828908 C10.4307692,15.7697235 11.5384615,17.1576239 11.6307692,17.2857377 L11.9076923,17.6273748 L12.1846154,17.2643854 C12.2769231,17.1576239 13.3615385,15.7483712 14.4923077,14.0615385 L18.9461538,14.0615385 Z M11.8846154,9.81538462 C11.0664336,9.81538462 10.3846154,9.13356643 10.3846154,8.31538462 C10.3846154,7.4972028 11.0664336,6.81538462 11.8846154,6.81538462 C12.7027972,6.81538462 13.3846154,7.4972028 13.3846154,8.31538462 C13.3846154,9.13356643 12.7027972,9.81538462 11.8846154,9.81538462 Z M11.9076923,4 C14.3076923,4 16.2461538,5.82940109 16.2461538,8.09437387 C16.2461538,10.0544465 12.9,14.6715064 11.9076923,16 C10.9153846,14.6715064 7.56923077,10.076225 7.56923077,8.09437387 C7.56923077,5.82940109 9.50769231,4 11.9076923,4 Z M11.9076923,10.7949183 C13.4769231,10.7949183 14.7692308,9.61887477 14.7692308,8.11615245 C14.7692308,6.61343013 13.4769231,5.41560799 11.9076923,5.41560799 C10.3384615,5.41560799 9.04615385,6.59165154 9.04615385,8.09437387 C9.04615385,9.59709619 10.3384615,10.7949183 11.9076923,10.7949183 Z" id="Map"></path>
          </g>
        </g>
      </svg>
    );
});

export default Icon;