const colors = {
  beige: 'beige',
  blå: 'blue',
  bronse: '#cd7f32',
  brun: 'brown',
  grå: 'gray',
  grønn: 'green',
  gul: 'yellow',
  gull: 'gold',
  hvit: 'white',
  lilla: 'purple',
  oransje: 'orange',
  rød: 'red',
  rosa: 'pink',
  sølv: 'silver',
  svart: 'black',
  turkis: 'turquoise',
};

export const get = (name) => name && colors[name.toLowerCase()];