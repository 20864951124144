import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

import './style.scss';
import className from './../../../helpers/classname';

const componentName = 'Spinner';
const cn = className(componentName);

export const spinnerSizes = {
  BIG: 'big',
  MEDIUM: 'medium',
  SMALL: 'small'
};

const Spinner = React.memo(({size = spinnerSizes.BIG, className, iconClassName}) => {
  className = cn.join(componentName, cn(size), className, 'fade-in');

  return (
    <div className={className}>
      <FontAwesomeIcon
        className={cn.join(cn('icon'), iconClassName)}
        icon={faSpinner}
        spin={true}
      />
    </div>
  )
});

export default Spinner;