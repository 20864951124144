import React from 'react';
import {Link} from 'react-router-dom';

import * as commonConfig from '../common/config';
import Button, {buttons} from './../common/components/elements/Button';

export const types = {
  SUCCESS: 'SUCCESS',
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR'
};

export const mapTypeToConfig = {
  [types.SUCCESS]: {
    name: 'Vellykket'
  },
  [types.INFO]: {
    name: 'Informasjon'
  },
  [types.WARNING]: {
    name: 'Advarsel'
  },
  [types.ERROR]: {
    name: 'Feil'
  }
};

export const texts = {
  // success texts
  s1: `Nytt lagret søk er opprettet`, // `New search template is successfully created`
  s2: `Meldingen er sendt`, // `Your message is successfully sent`
  s3: `Lagret søk er oppdatert`, // `Search template is successfully updated`
  s4: `Lagret søk er slettet`, // `Search template is successfully deleted`
  s5: `Raden er oppdatert`, // `Row is successfully updated`
  s6: `Annonsen er oppdatert`, // `Ad is successfully updated`
  s7: `Annonsen er slettet`, // `Ad is successfully deleted`
  s8: `Ny kode er sendt til ditt mobiltelefonnummer`, // `New code is successfully sent to your phone number`
  s9: `Passordet er oppdatert`, // 'Password is successfully updated'
  s10: `Informasjon er oppdatert`, // 'Information is successfully updated'
  s11: `Ny bekreftelsese-post er sendt`, // 'New email with confirmation link is successfully sent'
  s12: `Telefonnummeret er bekreftet`, // 'Phone number is successfully verified'
  s13: `Annonsen har blitt innrapportert`, // `Your report is successfully sent`
  s14: `Your notification is successfully sent`, // `Your notification is successfully sent`
  // info texts
  i1: ( // 'Your last draft is saved in drafts'
    <React.Fragment>
      Din påbegynte annonse er lagret som{' '}
      <Button
        className="d-inline text-color1"
        button={buttons.SIMPLE}
        component={Link}
        to={commonConfig.routes.MY_ADS.buildPath({tab: commonConfig.mapStatusNameToConfig.DRAFTS.value})}
      >
        Kladd
      </Button>
    </React.Fragment>
  ),
  i2: 'Prøv igjen etter at nedtellingen er over', // 'Try again after timer is over'
  // warning texts
  w1: 'Velg filter først', // 'Choose filters first'
  w2: ( // you should be authorized to perform this action
    <React.Fragment>
      <p>Du må være logget inn for å utføre dette</p>
      <p className="mb-0">
        <Button
          className="d-inline text-color1"
          button={buttons.SIMPLE}
          component={Link}
          to={commonConfig.routes.LOGIN.path}
        >
          logg inn
        </Button>
        {' eller '}
        <Button
          className="d-inline text-color1"
          button={buttons.SIMPLE}
          component={Link}
          to={commonConfig.routes.REGISTER.path}
        >
          meld deg inn
        </Button>
      </p>
    </React.Fragment>
  ),
  w3: 'Filen kan ikke ha størrelse over 10 mb', // 'File size should not exceed 10 mb'
  w4: 'Du kan ikke legge til mer enn en fil pr. melding', // 'You can attach no more than one file per message'
  w5: 'Denne funksjonen er ikke tilgjengelig i denne modusen', // 'This action is not available in current mode'
  w6: ( // You should provide email and confirm it to perform this action
    <React.Fragment>
      Du må oppgi e-post og bekrefte den før du kan utføre denne handlingen
      <br/>
      <br/>
      Du kan endre e-postadressen eller sende ny bekreftelse e-post under {' '}
      <Button
        className="d-inline text-color1"
        button={buttons.SIMPLE}
        component={Link}
        to={commonConfig.routes.SETTINGS.path}
      >
        profilinnstillinger
      </Button>
    </React.Fragment>
  ),
  // error texts
  e1: 'Noe gikk galt, sjekk at du er koblet til internett', // Something went wrong, check your internet connection
  e2: 'Du må tillate å dele e-postadressen din', // You must allow to share your email address
};