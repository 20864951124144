export const prepareFileLink = (str, {isThumbnail = false, width} = {}) => {
  if(!str) return '';

  if(!/^http/.test(str)){
    if(/api\/File/.test(str))
      str = `${window.config.serverAddress}/${str}`;
    else
      str = `${window.config.serverAddress}/api/File/${str}`;
  }

  if(isThumbnail){
    if(!/thumbnail/.test(str))
      str = `${str}/thumbnail`;
    if(!!width)
      str = `${str}/${width}`
  }

 return str;
};

export const isPrerenderMode = () => /prerender/i.test(window.location.href);

