import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-24.000000, -43.000000)" fill="#333333">
            <g id="Group">
              <g id="UI-/-Components-/-Header" transform="translate(0.000000, 20.000000)">
                <path d="M24,36.9543974 L42,36.9543974 L42,34.6286645 L24,34.6286645 L24,36.9543974 Z M24,31.1400651 L42,31.1400651 L42,28.8143322 L24,28.8143322 L24,31.1400651 Z M24,23 L24,25.3257329 L42,25.3257329 L42,23 L24,23 Z" id="Menu"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
});

export default Icon;