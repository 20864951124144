import {select, put} from 'redux-saga/effects';

import * as actions from './../../actions';
import * as selectors from './../../selectors';
import * as config from './../../../config';

export function* increaseNewThreadsCounter(){
  let {threadsList} = yield select(selectors.getUi);

  let currentCount = threadsList.newThreadsCounter;
  let currentCurrentPage = threadsList.currentPage;
  let currentShowUntil = threadsList.showUntil;

  if(currentCount/config.getThreadsBy >= 1){
    yield put(actions.updateUi({
      threadsList: {
        currentPage: currentCurrentPage + 1,
        showUntil: currentShowUntil + 1,
        newThreadsCounter: currentCount % config.getThreadsBy
      }
    }))
  } else {
    yield put(actions.updateUi({
      threadsList: {
        newThreadsCounter: currentCount + 1
      }
    }))
  }
}

export function* decreaseNewThreadsCounter(){
  let {threadsList} = yield select(selectors.getUi);

  let currentCount = threadsList.newThreadsCounter;

  yield put(actions.updateUi({
    threadsList: {
      newThreadsCounter: currentCount - 1
    }
  }))
}