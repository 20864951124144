import * as actions from './actions';
import * as immutable from './../../helpers/immutable';
import * as models from './models';

const data = (state = models.data, action) => {
  switch (action.type) {
    case actions.UPDATE_DATA:
      return immutable.updateObjectProps(state, action.updateOb);
    default:
      return state;
  }
};

export default data;

