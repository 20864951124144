import {texts as snackbarsTexts} from './../../../Snackbars/config';

let IsFacebookSDKInitiated = false;

export function setUpFacebookSdk({appId}){
  let d = document;
  let s = 'script';
  let id = 'facebook-jssdk';

  let js;
  let fjs = d.getElementsByTagName(s)[0];

  if(d.getElementById(id))
    return;

  js = d.createElement(s);

  js.id = id;
  js.src = 'https://connect.facebook.net/en_US/sdk.js';
  js.onload = () => initFacebookSDK({appId});

  fjs.parentNode.insertBefore(js, fjs);

  window.config.facebookAppId = appId;
}

export function initFacebookSDK({appId}){
  window.FB.init({
    appId      : appId,
    cookie     : true,
    xfbml      : true,
    version    : 'v5.0'
  });
}

export function facebookSDKIsInitiated(){
  IsFacebookSDKInitiated = true;
}

function withFacebookSDKInitiated(func){
  if(!IsFacebookSDKInitiated)
    return new Error(snackbarsTexts.e1);

  return func();
}

export function login({auth_type, scope = 'public_profile,email', return_scopes = true} = {}){
  return withFacebookSDKInitiated(function(){
    return new Promise((resolve, reject) => {
      window.FB.login(function(loginResponse){
        if(loginResponse.authResponse){
          window.FB.api('/me/permissions', function(permissionsResponse) {
            resolve({
              loginResponse,
              permissionsResponse
            })
          });
        } else {
          resolve(new Error('Innlogging ble avbrutt eller nødvendig autorisasjon ble ikke gitt.')) // User cancelled login or did not fully authorize
        }
      }, {
        auth_type,
        scope,
        return_scopes
      });
    })
  })
}