import {call} from 'redux-saga/effects';

import {getPrettyTime} from './../../../../common/helpers/date';

export default function* extendItem({item}){
  let timeText = yield call(getPrettyTime, {isoDate: item.date});

  item.derived = {
    timeText
  };

  return item;
};