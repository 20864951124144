import {fork, take, put, call, all, select, takeLatest} from 'redux-saga/effects';
import queryString from 'query-string';
import {createBrowserHistory as createHistory} from 'history';

import * as actions from './actions';
import * as immutable from './../../helpers/immutable';
import * as reduxSagaService from './../../services/redux-saga';

const typeValidator = {
  Boolean: ({searchValue}) => ['true', 'false'].includes(searchValue),
  Number: ({searchValue}) => !Number.isNaN(+searchValue),
  Array: ({searchValue}) => Array.isArray(searchValue),
  String: ({searchValue}) => Object.prototype.toString.call(searchValue) == '[object String]'
};
const searchValueNormalizer = {
  Boolean: ({searchValue}) => searchValue == 'true' ? true : false,
  Number: ({searchValue}) => +searchValue,
  Array: ({searchValue, valuesType}) => searchValue.map(v => searchValueNormalizer[valuesType.name || 'String']({searchValue: v})),
  String: ({searchValue}) => searchValue,
};
const defaultValues = {
  Boolean: false,
  Number: null,
  Array: [],
  String: '',
};

export function* watchSync(){
  while(true){
    let {querySelector, queryUpdateActionCreator, extraArgs, urlProps, onEmptySearchString} = yield take(actions.SYNC_REDUX_STORE_WITH_URL);

    let history = createHistory();

    let currentQueryState = yield select(querySelector);

    let searchString = history.location.search;

    // if search string is not empty we reflect search params to redux store

    if(searchString){
      let ob = {};

      let searchOb = queryString.parse(searchString, {arrayFormat: 'index'});

      for(let urlProp of urlProps){
        let urlPropName = urlProp.name;

        // type property can have multiple types like [Boolean, Number]

        let availableTypes = Array.isArray(urlProp.type) ? urlProp.type : [urlProp.type];

        let searchValue = searchOb[urlPropName];

        // if search value for some property is not presented, assign default value to it

        if(searchValue === null || searchValue === undefined){
          ob[urlPropName] = urlProp.hasOwnProperty('defaultValue') ? urlProp.defaultValue : defaultValues[availableTypes[0].name];

        // else, check search value for proper type and assign it if its has proper type (there can be multiple types)

        } else {
          for(let type of availableTypes){
            if(typeValidator[type.name]({searchValue})){
              ob[urlPropName] = searchValueNormalizer[type.name]({searchValue, valuesType: urlProp.valuesType});
            };
          };
        };
      };

      // update store

      yield put(queryUpdateActionCreator(ob, ...(extraArgs || [])));

    // else, if search string is empty we reflect redux store to url

    } else {
      let ob = {};

      for(let urlProp of urlProps){
        let value = currentQueryState[urlProp.name];

        if(value !== undefined && value !== null && value !== '')
          ob[urlProp.name] = value;
      };

      let newSearchString = queryString.stringify(ob, {arrayFormat: 'index'});

      // update url

      history.push({
        pathname: history.location.pathname,
        search: newSearchString
      })

      if(onEmptySearchString)
        yield call(onEmptySearchString);
    };
  };
};

export function* watchUpdate(){
  while(true){
    let {querySelector, queryUpdateActionCreator, urlProps, args} = yield take(actions.UPDATE_REDUX_STORE_AND_URL);

    // update state of query

    yield put(queryUpdateActionCreator(...args));

    // get updated state of query

    let newQueryState = yield select(querySelector);

    // reflect state to url

    let ob = {};

    for(let prop of urlProps){
      let value = newQueryState[prop.name];

      if(value !== undefined && value !== null && value !== '')
        ob[prop.name] = value;
    };

    let searchString = queryString.stringify(ob, {arrayFormat: 'index'});

    let history = createHistory();

    history.push({
      pathname: history.location.pathname,
      search: searchString
    })
  }
};

export default reduxSagaService.createRootSaga(
  watchSync,
  watchUpdate
);