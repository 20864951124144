import React from 'react';
import {Link} from 'react-router-dom';

import {routes} from '../../common/config';

import './style.scss';

const Logo = React.memo(() => {
  return (
    <Link to={routes.HOME.path} className="text-decoration-none">
      <h4 className="Logo logo">
        <span className="text-color1">bil</span>
        <span className="text-color15">marked</span>
      </h4>
    </Link>
  )
});

export default Logo;