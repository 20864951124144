import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import * as commonService from './../../../services';
import Button, {buttons} from './../../elements/Button';

import './style.scss';
import * as config from '../../../config';
import * as actions from './redux/actions';
import * as selectors from './redux/selectors';

const mapStateToProps = (state) => ({
  cookieConsent: selectors.getCookieConsent(state)
});

class CookieConsent extends PureComponent {
  ok = () => {
    let {dispatch} = this.props;

    window.localStorage.setItem('CookieConsent', 'ok');

    dispatch(actions.update({
      isOk: true
    }));

    dispatch(actions.isOk());
  };
  componentDidMount(){
    let {dispatch} = this.props;

    if(commonService.isPrerenderMode())
      return;

    let isOk = window.localStorage.getItem('CookieConsent');

    dispatch(actions.update({
      isOk
    }));

    if(isOk)
      dispatch(actions.isOk());
  };
  render(){
    let {cookieConsent: {isOk}} = this.props;

    if(isOk || commonService.isPrerenderMode())
      return null;

    return (
      <div className="CookieConsent">
        <p className="text-center mb-0">
          Denne websiden bruker informasjonskapsler for å forbedre brukeropplevelsen
          <br/>
          For mer informasjon les vår {' '}
          <Link to={config.routes.PRIVACY.path} className="d-inline-block text-decoration-none">
            <Button button={buttons.SIMPLE} className="text-color1">
              personvernserklæring
            </Button>
          </Link>
        </p>
        <Button
          color="color1"
          className="ml-3 flex-shrink-0"
          onClick={this.ok}
        >
          Aksepter
        </Button>
      </div>
    );
  };
};

export default connect(mapStateToProps)(CookieConsent);