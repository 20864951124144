import React, {PureComponent} from 'react';

import InformationModal from './../InformationModal';

class EmailIsConfirmed extends PureComponent {
  render(){
    let {onClose} = this.props;

    return (
      <InformationModal
        header="Bekreftet e-postadresse"
        onClose={onClose}
      >
        <p className="mb-0 text-center">
        E-postadressen din har blitt bekreftet!
        </p>
      </InformationModal>
    )
  }
};

export default EmailIsConfirmed;