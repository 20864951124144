import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import * as config from './config';
import * as actions from './redux/actions';
import * as selectors from './redux/selectors';
import store from './../store';
import modalsReducer from './redux/reducer';
import BasicModal from './BasicModal';
import EmailConfirmationLinkSent from './EmailConfirmationLinkSent';
import AdCreated from './AdCreated';
import PasswordReset from './PasswordReset';
import EmailIsConfirmed from './EmailIsConfirmed';

store.injectReducer('Modals', modalsReducer);

const mapTypeToComponent = {
  [config.modalTypes.BASIC_MODAL]: BasicModal,
  [config.modalTypes.EMAIL_CONFIRMATION_LINK_SENT]: EmailConfirmationLinkSent,
  [config.modalTypes.AD_CREATED]: AdCreated,
  [config.modalTypes.PASSWORD_RESET]: PasswordReset,
  [config.modalTypes.EMAIL_IS_CONFIRMED]: EmailIsConfirmed,
};

const mapStateToProps = (state) => ({
  ui: selectors.getUi(state)
});

class Modals extends PureComponent {
  onClose = () => {
    let {dispatch} = this.props;

    dispatch(actions.updateUi({showModal: null}))
  };
  render(){
    let {ui: {showModal, payload}} = this.props;

    if(!showModal) return null;

    let ShowModalComponent = mapTypeToComponent[showModal];

    return (
      <ShowModalComponent
        payload={payload}
        onClose={this.onClose}
      />
    );
  }
};

export default connect(mapStateToProps)(Modals);