import React, {PureComponent} from 'react';
import {Container} from 'reactstrap';
import {Link} from 'react-router-dom';

import './style.scss';
import * as config from './config';
import classname from './../../common/helpers/classname';
import CustomLink from './../../common/components/other/Link';

const currentYear = new Date().getFullYear();
const componentName = 'Footer';
const cn = classname(componentName);

class Footer extends PureComponent {
  render(){
    let {className} = this.props;

    return (
      <footer className={cn.join(componentName, className)}>
        <Container>
          <div className={cn('links')}>
            {
              config.links.map(ob => {
                if(/http/.test(ob.path))
                  return (
                    <a key={ob.name} className={cn.join(cn('link'), 'simple-link')} href={ob.path} target="_blank" rel="noopener">
                      {ob.name}
                    </a>
                  );
                else
                  return (
                    <CustomLink
                      key={ob.name}
                      className={cn.join(cn('link'), 'simple-link')}
                      route={ob}
                    >
                      {ob.name}
                    </CustomLink>
                  );
              })
            }
          </div>
          <div className={cn('last-row')}>
            <div>Bilmarked &copy; {currentYear}</div>
            <Link className="text-decoration-none" to="/">
              <h4 className={cn.join(cn('logo'), 'logo')}>bilmarked</h4>
            </Link>
          </div>
        </Container>
      </footer>
    )
  }
}

export default Footer;