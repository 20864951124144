import React, {Component} from 'react';
import {connect} from 'react-redux';

import classname from './../common/helpers/classname';
import store from './../store';

import './style.scss';
import * as actions from './redux/actions';
import * as selectors from './redux/selectors';
import snackbarsReducer from './redux/reducer';
import snackbarsSagas from './redux/sagas';
import Snackbar from './Snackbar';

store.injectReducer('Snackbars', snackbarsReducer);
store.injectSaga('Snackbars', snackbarsSagas);

let componentName = 'Snackbars';
let cn = classname(componentName);

const mapStateToProps = state => ({
  ui: selectors.getUi(state)
});

class Snackbars extends Component {
  deleteByKey = (key) => {
    let {ui, dispatch} = this.props;

    let updatedItems = ui.items.filter(item => item.key !== key);

    dispatch(actions.updateUi({items: updatedItems}));
  };
  /*componentDidMount(){
    let {dispatch} = this.props;

    dispatch(actions.pushErrorMessage({
      text: 'Test messsage',
      withBeep: true
    }))
  }*/
  render(){
    let {ui} = this.props;

    return (
      <div className={componentName}>
        {
          ui.items.map(item => {
            return (
              <Snackbar
                key={item.key}
                item={item}
                deleteByKey={this.deleteByKey}
              />
            )
          })
        }
      </div>
    )
  }
};

export default connect(mapStateToProps)(Snackbars);