import {select} from 'redux-saga/effects';

import * as selectors from './../../selectors';
import {FIRST_REQUEST, LOAD_MORE} from './../config';
import {getThreadsBy} from './../../../config';

export default function* prepareParamsForGrid({type} = {}){
  let ui = yield select(selectors.getUi);

  let params = {};

  // page size

  if(type === FIRST_REQUEST){
    params.pageSize = getThreadsBy * 2;
  } else if(type === LOAD_MORE){
    params.pageSize = getThreadsBy;
  }

  // current page

  if(type === LOAD_MORE){
    params.currentPage = ui.threadsList.currentPage + 1;
  } else if(type === FIRST_REQUEST){
    params.currentPage = 0;
  };

  //  sorting

  /*params.sorting = [
    {
      columnName: 'createdDate',
      direction: 'desc'
    }
  ];*/

  return params;
};