import {take, call, put, select} from 'redux-saga/effects';

import * as actions from './../../actions';
import * as mainAppCompActions from './../../../../MainApp/redux/actions';
import * as sessionActions from './../../../../common/redux/session/actions';
import * as sessionSelectors from './../../../../common/redux/session/selectors';
import {processNames} from './../../../config';
import {FIRST_REQUEST} from './../config';
import request from './../../../../common/services/request';
import extend from './../common/extendThread';
import prepareParamsForGrid from './prepareParamsForGrid';

export function* watchFetchThreads(){
  while(true){
    let action = yield take([mainAppCompActions.MAIN_APP_COMPONENT_DID_MOUNT, sessionActions.SUCCESSFUL_LOGIN]);

    let session = yield select(sessionSelectors.getSession);

    if(action.type === mainAppCompActions.MAIN_APP_COMPONENT_DID_MOUNT && !session.isAuthorized)
      continue;

    let params = yield call(prepareParamsForGrid, {type: FIRST_REQUEST});

    let result = yield call(request.post, {
      path: 'Message/threads/grid',
      processName: processNames.FETCH_THREADS,
      params
    });

    if(result instanceof Error)
      continue;

    // extend threads objects

    for(let i = 0; i < result.rows.length; i++){
      result.rows[i] = yield call(extend, result.rows[i]);
    };

    // save `currentPage` & `showUntil`

    yield put(actions.updateUi({
      threadsList: {
        currentPage: 1,
        showUntil: 0
      }
    }));

    // put data

    yield put(actions.updateData({
      threads: result
    }));
  }
};