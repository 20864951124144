import {put, select, takeEvery} from 'redux-saga/effects';

import * as sessionSelectors from '../../../../common/redux/session/selectors';
import * as actions from '../../actions';
import * as models from '../../models';
import * as immutable from '../../../../common/helpers/immutable';

export function* resetCommunicationForm(){
  let session = yield select(sessionSelectors.getSession);

  if(!session || !session.decodedIdToken)
    return;

  yield put(actions.updateUi({
    communicationForm: immutable.updateObjectProps(models.ui.communicationForm, {
      notifyEmailSearchSubscription: {value: session.decodedIdToken.notifyEmailSearchSubscription},
      notifySmsSearchSubscription: {value: session.decodedIdToken.notifySmsSearchSubscription},
      notifyEmailNewMessage: {value: session.decodedIdToken.notifyEmailNewMessage},
      notifySmsNewMessage: {value: session.decodedIdToken.notifySmsNewMessage},
      allowEmailInformation: {value: session.decodedIdToken.allowEmailInformation},
      allowSmsInformation: {value: session.decodedIdToken.allowSmsInformation},
      allowEmailMarketing: {value: session.decodedIdToken.allowEmailMarketing},
      allowSmsMarketing: {value: session.decodedIdToken.allowSmsMarketing},
    })
  }))
};

export function* watchResetCommunicationForm(){
  yield takeEvery(actions.RESET_COMMUNICATION_FORM, resetCommunicationForm);
}