const _prefix = 'FORM_VALIDATOR_SERVICE::';

export const VALIDATE_AND_UPDATE_PROP = _prefix + 'VALIDATE_AND_UPDATE_PROP';
export const SUBMIT_FORM = _prefix + 'SUBMIT_FORM';

export const validateAndUpdateProp = ({event, prop, value, payload, formSelector, updateFormActionCreator, onValid, onEnd, isRecursiveCall}) => ({
  type: VALIDATE_AND_UPDATE_PROP,
  event,
  value,
  payload,
  prop,
  formSelector,
  updateFormActionCreator,
  onValid,
  onEnd,
  isRecursiveCall
});

export const submitForm = ({event, props, formSelector, updateFormActionCreator, onValid, onInvalid, disableScrolling, readyValidationResult}) => ({
  type: SUBMIT_FORM,
  event,
  props,
  formSelector,
  updateFormActionCreator,
  onValid,
  onInvalid,
  disableScrolling,
  readyValidationResult
});