import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import RegularModal, {modalHeaderTypes} from './../../common/components/blocks/RegularModal';
import Button, {buttons} from './../../common/components/elements/Button';

class InformationModal extends PureComponent {
  render(){
    let {header, children, onClose} = this.props;

    return (
      <RegularModal
        header={header}
        headerType={modalHeaderTypes.CENTERED}
        onClose={onClose}
      >
        {
          children ?
            <div className="mb-2rem font-weight-custom-bold">
              {children}
            </div>
            :
            null
        }
        <div className="text-center">
          <Button
            className="px-5"
            color="color1"
            onClick={onClose}
          >
            Ok
          </Button>
        </div>
      </RegularModal>
    )
  }
};

export default connect()(InformationModal);