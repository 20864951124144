import {fork, take, put, call, all, select, cancel, takeEvery} from 'redux-saga/effects';

import * as helpers from './../../common/helpers';
import * as immutable from './../../common/helpers/immutable';
import * as actions from './actions';
import * as selectors from './selectors';
import * as reduxSagaService from './../../common/services/redux-saga';

export function* push({sType, header, text, lifeTime, withBeep, icon, faIcon}){
  let ui = yield select(selectors.getUi);

  let newItem = {
    key: helpers.generateId(),
    type: sType,
    header,
    text,
    lifeTime,
    withBeep,
    icon,
    faIcon
  };

  if(sType )

  yield put(actions.updateUi({
    items: [newItem]//immutable.pushToArray(ui.items, newItem, false)
  }))
};

export function* watchPush(){
  yield takeEvery(actions.PUSH, push);
};

export default reduxSagaService.createRootSaga(
  watchPush
);