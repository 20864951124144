import React, {Component, Suspense} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

import * as config from '../common/config';
import ProtectedRoute from './../common/components/other/ProtectedRoute';
import ErrorBoundary from './../common/components/blocks/ErrorBoundary';
import Snackbars from './../Snackbars';
import Modals from './../Modals';

import './style.scss';
import * as actions from './redux/actions';
import * as selectors from './redux/selectors';
import Navigation from './../Navigation';

const mapStateToProps = (state) => ({
  ui: selectors.getUi(state)
});

class Main extends Component {
  componentDidMount(){
    let {dispatch} = this.props;

    dispatch(actions.mainAppComponentDidMount());
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    let {ui: {redirectToLoginPage}, dispatch} = this.props;

    if(redirectToLoginPage)
      dispatch(actions.updateUi({
        redirectToLoginPage: false
      }))
  }
  render(){
    let {ui: {redirectToLoginPage}} = this.props;

    if(redirectToLoginPage)
      return <Redirect to={config.routes.LOGIN.path}/>;

    return (
      <div className="MainApp">
        <ErrorBoundary>
          <Snackbars/>
          <Modals/>
          <Navigation/>
          <Suspense fallback={null}>
            <Switch>
              {
                Object.keys(config.routes).map(key => {
                  let {path, component, isProtected, role} = config.routes[key];

                  return isProtected ?
                    <ProtectedRoute key={key} path={path} component={component} role={role}/>
                    :
                    <Route key={key} path={path} component={component}/>
                })
              }
              <Redirect
                to={{
                  pathname: config.redirectToPath,
                  search: this.props.location.pathname == '/' ? this.props.location.search : ''
                }}
              />
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Main);
