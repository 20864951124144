import React, {PureComponent} from 'react';

import './style.scss';
import classname from './../../../helpers/classname';
import Popper, {triggers as popperTriggers, animations as popperAnimations} from './../Popper';
import Icon, {icons} from './../../elements/Icon';

let componentName = 'DropdownMenu';
let cn = classname(componentName);

class DropdownMenu extends PureComponent {
  constructor(props){
    super(props);
  };
  render(){
    let {
      name,
      className,
      toggleButtonClassName,
      buttonText,
      showArrow = true,
      withMargin = true,
      toggleOnTargetClick = true,
      toggleOnPopperClick = true,
      popperJsOptions,
      children
    } = this.props;

    let toggleButtonId = `${name}-toggle-button`;

    return (
      <div className={cn.join(componentName, className)}>
        <div id={toggleButtonId} className={cn.join(cn('toggle-button'), toggleButtonClassName)}>
          <div>{buttonText}</div>
          {
            showArrow ?
              <Icon
                className={cn('chevron')}
                icon={icons.CHEVRON_DOWN_LIGHT}
              />
              :
              null
          }
        </div>
        <Popper
          target={toggleButtonId}
          on={popperTriggers.CLICK}
          animation={popperAnimations.FADE_IN_WITH_TRANSLATE}
          toggleOnTargetClick={toggleOnTargetClick}
          toggleOnPopperClick={toggleOnPopperClick}
          withMargin={withMargin}
          popperJsOptions={popperJsOptions}
        >
          <div className={cn.join(cn('menu'))}>
            {children}
          </div>
        </Popper>
      </div>
    )
  }
};

export const DropdownItem = React.memo(({component: C = 'div', className, useHoverEffect = true, children, ...rest}) => {
  className = cn.join(cn('menu-item'), className, useHoverEffect && cn('hover-effect'));

  return (
    <C className={className} {...rest}>
      {children}
    </C>
  )
});

export default React.memo(DropdownMenu);