import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Popper from 'popper.js';
import {CSSTransition} from 'react-transition-group';

import './style.scss';
import * as immutable from './../../../helpers/immutable';

const defaultOptions = {
  placement: 'bottom'
};

export const triggers = {
  CLICK: 'click',
  HOVER: 'hover'
};

export const animations = {
  FADE_IN_WITH_SCALE: 'css-transition-fade-with-scale',
  FADE_IN_WITH_TRANSLATE: 'css-transition-fade-with-translate',
};

class CustomPopper extends Component {
  constructor(props){
    super(props);
    this.popperRef = React.createRef();
    this.state = {
      show: false
    };
  };
  handleClickOutside = (e) => {
    let {toggleOnTargetClick = false, toggleOnPopperClick = false} = this.props;

    let isElemRemoved = !document.body.contains(e.target);
    let isClickWasOnTarget = e.target == this.targetElem || this.targetElem.contains(e.target);
    let isClickWasOnPopper = e.target == this.popperRef.current || this.popperRef.current.contains(e.target);

    if(isElemRemoved || (isClickWasOnPopper && !toggleOnPopperClick)) return;
    if(isClickWasOnTarget && !toggleOnTargetClick && this.state.show) return;
    if(isClickWasOnTarget && e.PopperIsJustLaunched) return;

    this.hidePopper();
  };
  launchPopper = () => {
    let {on = triggers.CLICK, onShow, onChange, popperJsOptions = {}} = this.props;

    this.popper = new Popper(
      this.targetElem,
      this.popperRef.current,
      immutable.updateObjectProps(defaultOptions, popperJsOptions)
    );

    if(on === triggers.CLICK){
      document.addEventListener('click', this.handleClickOutside);
    };

    this.setState({show: true});

    if(onChange) onChange({show: true});
    if(onShow) onShow();
  };
  hidePopper = () => {
    let {on = triggers.CLICK, onChange, onHide} = this.props;

    // update state

    this.setState({show: false});

    if(onChange) onChange({show: false});
    if(onHide) onHide();

    // remove listener

    if(on === triggers.CLICK){
      document.removeEventListener('click', this.handleClickOutside);
    };
  };
  destroyPopper = () => {
    let {onDestroy} = this.props;

    if(this.popper) this.popper.destroy();

    if(onDestroy) onDestroy();
  };
  componentDidMount(){
    let {target, on = triggers.CLICK, showByDefault} = this.props;

    // get target elem

    this.targetElem = document.getElementById(target);

    // add event listener

    if(on === triggers.CLICK){
      let onClick = (e) => {
        if(!this.state.show){
          e.PopperIsJustLaunched = true;
          this.launchPopper();
        }
      };

      // save handler

      this.handlers = {onClick};

      // create listener

      this.targetElem.addEventListener('click', onClick);

      // show by default if necessary

      if(showByDefault){
        this.launchPopper();
      };
    };
  };
  componentWillUnmount(){
    // clear everything

    let {on = triggers.CLICK} = this.props;

    // hide popper

    this.hidePopper();

    // cancel handlers that are attached to target element

    if(this.ref){
      if(on === triggers.CLICK){
        this.ref.removeEventListener('click', this.handlers.onClick);
      };
    };

    // destroy popper

    this.destroyPopper();
  };
  render(){
    let {renderProp, children, className, animation = animations.FADE_IN_WITH_SCALE, withMargin = false} = this.props;

    className = ['CustomPopper', className || '', animation, withMargin ? 'CustomPopper-with-margin' : ''].join(' ');

    return (
      <div
        className={className}
        ref={this.popperRef}
      >
        <CSSTransition
          in={this.state.show}
          classNames="fade"
          timeout={500}
          unmountOnExit
          onExited={this.destroyPopper}
        >
          {
            /*see: https://reactjs.org/docs/render-props.html*/
            renderProp ?
              renderProp({hidePopper: this.hidePopper})
              :
              children
          }
        </CSSTransition>
      </div>
    );
  };
};

export default CustomPopper;