const _prefix = 'SIGNALR_SERVICE::';

export const CHANNEL_IS_CLOSED = `${_prefix}CHANNEL_IS_CLOSED`;
export const CONNECTION_ERROR = `${_prefix}CONNECTION_ERROR`;

// actions for socket events

export const NEW_THREAD_MESSAGE = `${_prefix}NEW_THREAD_MESSAGE`;
export const DISMISSED_THREAD_MESSAGES = `${_prefix}DISMISSED_THREAD_MESSAGES`;
export const TYPING_IN_THREAD = `${_prefix}TYPING_IN_THREAD`;
export const NOTIFICATION = `${_prefix}NOTIFICATION`;
export const BROADCAST_OBJECT = `${_prefix}BROADCAST_OBJECT`;
export const PROFILE_IS_UPDATED = `${_prefix}PROFILE_IS_UPDATED`;
export const MAKE_MODEL_IS_UPDATED = `${_prefix}MAKE_MODEL_IS_UPDATED`;
export const RECOUNT_OBJECT_STATUS = `${_prefix}RECOUNT_OBJECT_STATUS`;
export const SEND_TYPING_IN_THREAD = `${_prefix}SEND_TYPING_IN_THREAD`;

export const channelIsClosed = () => ({type: CHANNEL_IS_CLOSED});
export const connectionError = ({error}) => ({type: CONNECTION_ERROR, error});

export const newThreadMessage = ({payload}) => ({type: NEW_THREAD_MESSAGE, payload});
export const dismissedThreadMessages = ({payload}) => ({type: DISMISSED_THREAD_MESSAGES, payload});
export const typingInThread = ({payload}) => ({type: TYPING_IN_THREAD, payload});
export const notification = ({payload}) => ({type: NOTIFICATION, payload});
export const broadcastObject = ({payload}) => ({type: BROADCAST_OBJECT, payload});
export const profileIsUpdated = ({payload}) => ({type: PROFILE_IS_UPDATED, payload});
export const makeModelIsUpdated = ({payload}) => ({type: MAKE_MODEL_IS_UPDATED, payload});
export const recountObjectStatus = ({payload}) => ({type: RECOUNT_OBJECT_STATUS, payload});
export const sendTypingInThread = ({threadId}) => ({type: SEND_TYPING_IN_THREAD, threadId});