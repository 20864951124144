const _prefix = 'VEHICLE_PAGE::';

export const UPDATE_DATA = _prefix + 'UPDATE_DATA';
export const UPDATE_UI = _prefix + 'UPDATE_UI';

export const FETCH = _prefix + 'FETCH';
export const USER_LOOKED_TO_PHONE_NUMBER = _prefix + 'USER_LOOKED_TO_PHONE_NUMBER';

export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});
export const updateUi = (updateOb) => ({type: UPDATE_UI, updateOb});

export const fetch = ({id, idType}) => ({type: FETCH, id, idType});
export const userLookedToPhoneNumber = () => ({type: USER_LOOKED_TO_PHONE_NUMBER});