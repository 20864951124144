import React from 'react';

import './style.scss';
import Button from './../Button';

// TODO: replace `color1/2/...` values with something more meaningful and general

const DefaultButton = React.memo(({className = '', color = 'color1', ...rest}) => {
  className = `DefaultButton DefaultButton-${color} ${className}`;

  return (
    <Button
      className={className}
      animate={true}
      {...rest}
    />
  )
});

export default DefaultButton;