import {createStore, applyMiddleware, combineReducers} from 'redux';
import createSagaMiddleware from 'redux-saga'

import * as helpers from './common/helpers';

import staticReducers from './static-reducers';
import staticSagas from './static-sagas';

let createLogger;

// if you will use `if(helpers.isDev){...}`
// instead of `if(process.env.NODE_ENV === 'development'){...}`
// webpack will include `redux-logger` to build bundle
if(process.env.NODE_ENV === 'development')
  ({createLogger} = require('redux-logger'));

function prepareMiddlewares(){
  let middlewares = [];

  // create saga middleware

  let sagaMiddleware = createSagaMiddleware();

  middlewares.push(sagaMiddleware);

  // create logger middleware

  if(helpers.isDev){
    const logger = createLogger({
      collapsed: true,
      duration: true
    });

    middlewares.push(logger);
  }

  return [middlewares, sagaMiddleware]
}

function createReducer(asyncReducers){
  return combineReducers({
    ...staticReducers,
    ...asyncReducers
  })
};

// runSaga is middleware.run function
// rootSaga is a your root saga for static saagas

function createSagaInjector(runSaga) {
  // Create a dictionary to keep track of injected sagas
  const injectedSagas = new Map();

  const isInjected = key => injectedSagas.has(key);

  const injectSaga = (key, saga) => {
    // We won't run saga if it is already injected
    if (isInjected(key)) return;

    // Sagas return task when they executed, which can be used
    // to cancel them
    const task = runSaga(saga);

    // Save the task if we want to cancel it in the future
    injectedSagas.set(key, task);
  };

  return injectSaga;
}

function configureStore(middlewares){
  const store = createStore(createReducer(), applyMiddleware(...middlewares));

  // run static sagas

  for(let staticSaga of staticSagas)
    sagaMiddleware.run(staticSaga);

  // Add injectSaga method to our store

  store.injectSaga = createSagaInjector(sagaMiddleware.run);

  // Add a dictionary to keep track of the registered async reducers

  store.asyncReducers = {};

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer

  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers))
  };

  // Return the modified store

  return store
};

let [middlewares, sagaMiddleware] = prepareMiddlewares();

const store = configureStore(middlewares);

export default store;