import React from 'react';
import {Container} from 'reactstrap';
import {Link, NavLink, withRouter} from 'react-router-dom';

import * as commonConfig from '../../common/config';
import {routes} from '../../common/config';
import classname from '../../common/helpers/classname';
import Button, {buttons} from '../../common/components/elements/Button';
import Icon, {icons} from '../../common/components/elements/Icon';

import './style.scss';
import UserDropdownMenu from './UserDropdownMenu';
import Logo from './../Logo';
import NewItem from './../NewItem';

const componentName = 'DesktopNavigation';
const cn = classname(componentName);

const BrowseButton = React.memo(() => {
  return (
    <Button
      className={cn('browse-button')}
      button={buttons.SIMPLE}
      component={Link}
      /*Link props*/
      to={routes.BROWSE.path}
    >
      <span>Søk</span>
      <Icon
        icon={icons.LONG_ARROW_RIGHT}
      />
    </Button>
  )
});

// dont use `React.memo(...)` here, otherwise IconButton will NOT be rerendered whenever route changes,
// that means `activeClassname` will not work for the NavLink component
const IconButtons = (({isAuthorized, totalNotifications, totalUnreadMessageCount}) => {
  if(!isAuthorized)
    return null;

  return (
    <div className={cn('icon-buttons')}>
      <NavLink
        to={commonConfig.routes.NOTIFICATIONS.path}
        className={cn('icon-button')}
        activeClassName={cn('icon-button-active')}
      >
        <Button button={buttons.SIMPLE}>
          <Icon icon={icons.BELL}/>
        </Button>
        {
          totalNotifications ?
            <div className={cn('notifications-count')}>
              +{totalNotifications}
            </div>
            :
            null
        }
      </NavLink>
      <NavLink
        to={commonConfig.routes.CHAT.path}
        className={cn('icon-button')}
        activeClassName={cn('icon-button-active')}
      >
        <Button button={buttons.SIMPLE}>
          <Icon icon={icons.CHAT}/>
        </Button>
        {
          totalUnreadMessageCount ?
            <div className={cn('notifications-count')}>
              +{totalUnreadMessageCount}
            </div>
            :
            null
        }
      </NavLink>
    </div>
  )
});

const DesktopNavigation = React.memo(({session, userAvatarUrl, totalNotifications, totalUnreadMessageCount, logout}) => {
  return (
    <Container className={componentName}>
      <div className={cn('top-left')}>
        <Logo/>
        <BrowseButton/>
      </div>
      <div className={cn('top-right')}>
        <NewItem/>
        <IconButtons
          isAuthorized={session.isAuthorized}
          totalNotifications={totalNotifications}
          totalUnreadMessageCount={totalUnreadMessageCount}
        />
        <UserDropdownMenu
          userAvatarUrl={userAvatarUrl}
          session={session}
          logout={logout}
        />
      </div>
    </Container>
  )
});

// see: https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/withRouter.md#important-note
export default withRouter(DesktopNavigation);