import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import './style.scss';
import classname from './../../../helpers/classname';
import Icon, {icons} from './../../../components/elements/Icon';

const componentName = 'Avatar';
const cn = classname(componentName);

export const sizes = {
  BIG: 'big',
  MEDIUM: 'medium',
  SMALL: 'small',
};

const Avatar = React.memo(({image, faIcon, icon = icons.USER, size = sizes.MEDIUM, className}) => {
  if(image){
    return (
      <div
        className={cn.join(componentName, cn(size), cn('image'), className)}
        style={{backgroundImage: `url(${image})`}}
      />
    )
  } else if(faIcon){
    return (
      <FontAwesomeIcon
        className={cn.join(componentName, cn(size), cn('icon'), className)}
        icon={faIcon}
      />
    )
  } else {
    return (
      <Icon
        className={cn.join(componentName, cn(size), cn('icon'), className)}
        icon={icon}
      />
    )
  }
});

export default Avatar;