export const data = {
  threads: null
  // [threadId]: <messages>
  // [threadId]: <messages>
  // ...
};

export const ui = {
  currentThread: null,
  message: '',
  file: null,
  isPageMounted: false,
  threadsList: {
    blockScrollFluid: false,
    currentPage: 0,
    showUntil: 0,
    newThreadsCounter: 0
  },
  messagesList: {
    /**
     * [threadId]: <messagesListItem>,
     * [threadId] <messagesListItem>,
     * ...
     */
  },
  userIsTypingThreadIds: [/*<threadId>, <threadId>, ...*/]
};

export const messagesListItem = {
  currentPage: 0,
  showUntil: 0,
  // Whenever new message is sent or new message comes via socket event `newMessagesCounter` is being increased,
  // value of this property is used in calculation of number of rows that is shown in messages list
  newMessagesCounter: 0,
  blockScrollFluid: false
};