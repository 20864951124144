import {call, select, put} from 'redux-saga/effects';

import * as selectors from './../../selectors';
import * as actions from './../../actions';
import * as immutable from './../../../../common/helpers/immutable';
import {LOAD_MORE} from './../config';
import {processesNames} from './../../../config';
import request from './../../../../common/services/request';
import prepareParamsForGrid from './prepareParamsForGrid';
import extendItem from './extendItem';

export function* loadMore(){

  let ui = yield select(selectors.getUi);
  let data = yield select(selectors.getData);

  // block handle scroll fluid

  yield put(actions.updateUi({blockHandleScrollFluid: true}));

  // load reserve data if possible

  if(ui.currentPage > ui.showUntil){
    yield put(actions.updateUi({
      showUntil: ui.showUntil + 1
    }));
  };

  // load more data if possible

  if(data.grid.rows.length >= data.grid.totalCount)
    return;

  // prepare params

  let params = yield call(prepareParamsForGrid, {type: LOAD_MORE});

  // do request

  let result = yield call(request.post, {
    path: 'Notification/grid',
    processName: processesNames.loadMore,
    params
  });

  if(result instanceof Error)
    return;

  // save current page

  yield put(actions.updateUi({currentPage: params.currentPage}));

  // save result

  for(let i = 0; i < result.rows.length; i++){
    result.rows[i] = yield call(extendItem, {item: result.rows[i]})
  }

  result = immutable.updateObjectProps(result, {
    rows: data.grid.rows.concat(result.rows)
  });

  yield put(actions.updateData({
    grid: {
      // !!! updating just rows and `totalCount`, without updating `notDismissedCount`
      // because updating it will break algorithm of showing of count of not dismissed notifications
      rows: result.rows,
      totalCount: result.totalCount
    },
  }));

  // unblock handle scroll fluid only if reserve data is left or more data can be fetched

  ui = yield select(selectors.getUi);

  if(params.currentPage > ui.showUntil || result.rows.length < result.totalCount){
    yield put(actions.updateUi({blockHandleScrollFluid: false}));
  };
};