import {call, take} from 'redux-saga/effects';
import {delay} from 'redux-saga';

import * as helpers from './../../helpers';
import * as commonService from './../../services';
import * as facebookService from './index';
import * as reduxSagaService from './../redux-saga';
import * as mainAppCompActions from './../../../MainApp/redux/actions';
import requestService from './../request';

export function* initFacebookApp(){
  yield take(mainAppCompActions.MAIN_APP_COMPONENT_DID_MOUNT);

  if(commonService.isPrerenderMode())
    return;

  let facebookAppId;

  while(!facebookAppId){
    let result = yield call(requestService.get, {
      path: 'authorization/facebookAppId',
      returnWholeResponse: true,
      //showError: true
    });

    if(result instanceof Error){
      if(helpers.isDev)
        console.log('Will try again to get facebook app id after 3 seconds');
      yield delay(3000);
    } else {
      facebookAppId = result.text;
    }
  }

  yield call(facebookService.setUpFacebookSdk, {appId: facebookAppId});
  yield call(facebookService.facebookSDKIsInitiated);
}

export default reduxSagaService.createRootSaga(
  initFacebookApp
);

