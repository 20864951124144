export const rules = {
  REQUIRED: 'REQUIRED',
  POSTAL_CODE: 'POSTAL_CODE',
  EMAIL: 'EMAIL',
  NUMBER: 'NUMBER',
  NUMBER_MIN_MAX: 'NUMBER_MIN_MAX',
  ALPHANUMERIC: 'ALPHANUMERIC',
  PHONE_NUMBER: 'PHONE_NUMBER',
  LENGTH: 'LENGTH',
  EQUAL: 'EQUAL',
  NOT_EQUAL: 'NOT_EQUAL',
  SELECT_FROM_LIST: 'SELECT_FROM_LIST',
};

export const sideRules = {
  POSTAL_CODE: 'POSTAL_CODE'
};

export const validators = {
  [rules.REQUIRED]: {
    func: (value) => {
      if(!value) return false;

      // using just pattern like /^\s*\S+/ is not enough
      // because for example /^\s*\S+/.test(undefined) returns true

      return /^\s*\S+/.test(value);
    }
  },
  [rules.EMAIL]: {pattern: /^$|^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/},
  [rules.POSTAL_CODE]: {pattern: /^$|^\d{4}$/},
  [rules.NUMBER]: {pattern: /^$|^\d{1,}((\.|\,)\d{1,})?$/},
  [rules.NUMBER_MIN_MAX]: {
    func: (value, min, max) => {
      if((min && value < min) || (max && value > max)){
        return false;
      } else {
        return true;
      }
    }
  },
  [rules.ALPHANUMERIC]: {pattern: /^$|^[a-zA-Z0-9]+$/},
  [rules.PHONE_NUMBER]: {pattern: /^$|^\+?\d{7,18}$/},
  [rules.LENGTH]: {
    func: (value, size, type) => {
      value = String(value);

      if(type === 'gte' || !type) return typeof(value) === "string" && value.length >= size;
      else if(type === 'lte') return typeof(value) === "string" && value.length <= size;
      else if(type === 'eq') return typeof(value) === "string" && value.length === size;
    }
  },
  [rules.EQUAL]: {func: (value, withValue) => value == withValue},
  [rules.NOT_EQUAL]: {func: (value, withValue) => value != withValue},
  [rules.SELECT_FROM_LIST]: {func: (form, prop) => form[prop].value === "" || form[prop].isValueFromList}
};

export const errorMessages = {
  [rules.REQUIRED]: 'Feltet er påkrevd',
  [rules.EMAIL]: 'E-postadressen er ugyldig',
  [rules.POSTAL_CODE]: 'Postnummer må bestå av 4 siffer',
  [rules.NUMBER]: 'Verdien må være et tall',
  [rules.ALPHANUMERIC]: 'Verdien kan kun bestå av tall og bokstaver',
  [rules.PHONE_NUMBER]: 'Skriv inn gyldig telefonnummer',
   [rules.LENGTH]: (size, type) => {
    if(type === 'gte' || !type) return `Verdien må ha minst ${size} tegn`;
    else if(type === 'lte') return `Verdien kan ikke bestå av mer enn ${size} tegn`;
    else if(type === 'eq') return `Verdien må bestå av ${size} tegn`;
  },
  [rules.NUMBER_MIN_MAX]: (value, min, max) => {
    if(min && value < min) return `Minimumverdien er ${min}`;
    else if(max && value > max) return `Maksimumverdien er  ${max}`;
  },
  [rules.EQUAL]: (label1, label2) => `'${label1}' er ikke lik '${label2}'`,
  [rules.NOT_EQUAL]: (label1, label2) => `'${label1}' er lik '${label2}'`,
  [rules.SELECT_FROM_LIST]: 'Velg verdi i listen'
};