import {take, call} from 'redux-saga/effects';

import * as sessionActions from './../../../../common/redux/session/actions';
import {resetProfileForm} from './../profile/reset';
import {resetCommunicationForm} from './../communication/reset';

// when application is started and `Main` component is mounted we fetch session profile info,
// as soon as session profile info is fetched we reset our profile form and communication form

export function* watchSessionProfileIsUpdated(){
  while(true){
    yield take(sessionActions.PROFILE_IS_UPDATED);

    yield call(resetProfileForm);
    yield call(resetCommunicationForm);
  }
};