import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
  return (
    <svg className={cn.join(componentName, className)} {...rest} width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-3.000000, -3.000000)" fill="#298FCA">
          <path d="M18.7037838,4.12523684 C19.8774324,4.12523684 20.8321622,5.05507895 20.8321622,6.19760526 L20.8321622,18.7502368 C20.8321622,19.8927632 19.8774324,20.8226053 18.7037838,20.8226053 L9.09567568,20.8226053 C7.92202703,20.8226053 6.9672973,19.8927632 6.9672973,18.7502368 L6.9672973,12.7981579 C6.9672973,12.216 7.22148649,11.6565789 7.66443243,11.2636579 L8.39537838,10.6625526 C8.3712973,10.6511842 8.3477027,10.6379211 8.32775676,10.6185 L7.36694595,9.68297368 C7.31902703,9.63607895 7.293,9.57165789 7.29591892,9.50557895 C7.29859459,9.43926316 7.32997297,9.37744737 7.38202703,9.33457895 L9.82637838,7.32402632 C9.92294595,7.24468421 10.0664595,7.25036842 10.1557297,7.33776316 L11.1141081,8.27328947 C11.144027,8.30265789 11.1615405,8.33984211 11.1724865,8.37915789 L11.3675676,8.21881579 L11.3675676,6.19760526 C11.3675676,5.05484211 12.3222973,4.12523684 13.4959459,4.12523684 L18.7037838,4.12523684 Z M18.704027,6.31886842 C18.704027,6.08913158 18.5118649,5.90202632 18.2759189,5.90202632 L13.9337838,5.90202632 C13.6975946,5.90202632 13.5056757,6.08913158 13.5056757,6.31886842 L13.5056757,7.20228947 C13.5056757,7.43202632 13.6975946,7.61913158 13.9337838,7.61913158 L18.2759189,7.61913158 C18.5121081,7.61913158 18.704027,7.43202632 18.704027,7.20228947 L18.704027,6.31886842 Z M7.36524324,8.36234211 L7.02616216,8.00447368 C5.63018919,6.52965789 4.42443243,5.25568421 3.39624324,5.19552632 C3.26805405,5.18794737 3.16783784,5.08444737 3.16783784,4.95915789 L3.16783784,3.41494737 C3.16783784,3.28657895 3.27267568,3.18165789 3.40427027,3.17810526 L3.46483784,3.17739474 C5.02110811,3.17739474 6.66445946,4.80734211 8.56710811,6.6945 L8.92127027,7.04526316 C8.9687027,7.09239474 8.994,7.15657895 8.99083784,7.22242105 C8.98767568,7.28826316 8.95654054,7.34984211 8.90472973,7.39247368 L7.70067568,8.38247368 C7.65518919,8.41989474 7.59948649,8.43836842 7.54378378,8.43836842 C7.47835135,8.43836842 7.41316216,8.41255263 7.36524324,8.36234211 Z" id="Fuel"></path>
        </g>
      </g>
    </svg>
  );
});

export default Icon;