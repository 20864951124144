import React, {PureComponent} from 'react';

import * as addObjectConfig from './../../AddObject/config';
import InformationModal from './../InformationModal';

const mapActionToConfig = {
  [addObjectConfig.actions.CREATE]: {
    header: 'Ny annonse er opprettet', // 'New ad is successfully created'
    body: 'Annonsen er sendt til gjennomgang og vil bli publisert så snart som mulig.' // 'Your ad on review now and will be published as soon as possible.'
  },
  [addObjectConfig.actions.EDIT]: {
    header: 'Annonsen er endret', //' Ad is successfully edited'
    body: 'En ny versjon av annonsen er sendt til gjennomgang og vil bli publisert så snart som mulig. Eventull tidligere versjon vil i mellomtiden fortsatt være publisert.'
  }
};

class AdCreated extends PureComponent {
  render(){
    let {onClose, payload} = this.props;

    return (
      <InformationModal
        header={mapActionToConfig[payload.action].header}
        onClose={onClose}
      >
        <div className="text-center">
          {mapActionToConfig[payload.action].body}
        </div>
      </InformationModal>
    )
  }
};

export default AdCreated;