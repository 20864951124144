import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-3.000000, -3.000000)" fill="#298FCA">
            <path d="M18.8642433,15.4649425 C21.7119479,12.6171995 21.7118899,7.98355028 18.8642433,5.13580726 C16.0164808,2.28806425 11.3828579,2.28806425 8.53515334,5.13580726 C6.11921439,7.55172967 5.75332118,11.1448228 7.43648794,13.950642 C7.43648794,13.950642 7.55738926,14.1534765 7.39409999,14.3166487 C6.46255095,15.2481334 3.66772987,18.0429933 3.66772987,18.0429933 C2.9260278,18.7846323 2.74945968,19.8217092 3.40719766,20.4795587 L3.5204448,20.5926891 C4.17818279,21.2505385 5.21532479,21.0740296 5.95696887,20.3323326 C5.95696887,20.3323326 8.74587536,17.5433872 9.67551086,16.6138161 C9.84662827,16.4426999 10.0494062,16.5636004 10.0494062,16.5636004 C12.8551867,18.2466976 16.4483044,17.8808649 18.8642433,15.4649425 Z M9.88356551,14.1164816 C7.77936061,12.0122911 7.7794186,8.58857463 9.88362349,6.48438415 C11.9878284,4.38025165 15.4115683,4.38019367 17.5157152,6.48438415 C19.6199201,8.58851664 19.6199201,12.0122911 17.5157152,14.1164816 C15.4115103,16.2205561 11.9878284,16.2205561 9.88356551,14.1164816 Z" id="Search" transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "></path>
          </g>
        </g>
      </svg>
    );
});

export default Icon;