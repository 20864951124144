import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="20px" height="16px" viewBox="0 0 12 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="BilMarked" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="26_BilMarked_Home-page_Dropdown-menu" transform="translate(-1120.000000, -164.000000)" fill="#298FCA">
            <g id="Group-9" transform="translate(1100.000000, 39.000000)">
              <g id="Group-8">
                <path d="M29.9127997,130.818896 L28.0259409,128.932037 C27.7986988,128.704795 27.7986988,128.336363 28.0259409,128.109121 C28.2531829,127.881879 28.621615,127.881879 28.848857,128.109121 L31.7290635,130.989327 C31.9563055,131.216569 31.9563055,131.585001 31.7290635,131.812243 L28.848857,134.69245 C28.621615,134.919692 28.2531829,134.919692 28.0259409,134.69245 C27.7986988,134.465208 27.7986988,134.096776 28.0259409,133.869534 L29.9127997,131.982675 L23.1984397,131.982675 C22.8781496,131.982675 22.6185031,131.724395 22.6185031,131.400785 C22.6185031,131.079417 22.8802698,130.818896 23.1984397,130.818896 L29.9127997,130.818896 Z M20.5818896,137.801571 C20.2605208,137.801571 20,137.54105 20,137.219681 L20,125.58189 C20,125.260521 20.2605208,125 20.5818896,125 C20.5823694,125 20.5828492,125.000001 20.5833288,125.000002 L26.3993462,125 C26.7215098,125 26.982675,125.25828 26.982675,125.58189 C26.982675,125.903258 26.7216899,126.163779 26.3993462,126.163779 L21.1637792,126.163779 L21.1637792,136.637792 L26.4007854,136.637792 C26.7221541,136.637792 26.982675,136.898312 26.982675,137.219681 C26.982675,137.54105 26.7221541,137.801571 26.4007854,137.801571 L20.5818896,137.801571 Z" id="Combined-Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
});

export default Icon;