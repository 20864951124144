import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
  return (
    <svg className={cn.join(componentName, className)} {...rest} width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-5.000000, -5.000000)" fill="#298FCA">
          <path d="M17.75,10.75 L13.5,10.75 C13.3619385,10.75 13.25,10.6380615 13.25,10.5 L13.25,6.25 C13.25,5.55969238 12.6903076,5 12,5 C11.3096924,5 10.75,5.55969238 10.75,6.25 L10.75,10.5 C10.75,10.6380615 10.6380615,10.75 10.5,10.75 L6.25,10.75 C5.55969238,10.75 5,11.3096924 5,12 C5,12.6903076 5.55969238,13.25 6.25,13.25 L10.5,13.25 C10.6380615,13.25 10.75,13.3619385 10.75,13.5 L10.75,17.75 C10.75,18.4403076 11.3096924,19 12,19 C12.6903076,19 13.25,18.4403076 13.25,17.75 L13.25,13.5 C13.25,13.3619385 13.3619385,13.25 13.5,13.25 L17.75,13.25 C18.4403076,13.25 19,12.6903076 19,12 C19,11.3096924 18.4403076,10.75 17.75,10.75 Z" id="Plus"></path>
        </g>
      </g>
    </svg>
  );
});

export default Icon;