const model = {
  id: '',
  className: '',
  type: '',
  label: '',
  value: '',
  placeholder: '',
  hasError: false,
  errorMessage: '',
  rules: [],
  extraText: '',
  isReadonly: false,
  isDisabled: false,
  debounceTimeout: 500,
  cancelDebounce: false,
  tabIndex: 0,
  topRightHtml: null,
  leftInnerHtml: null,
  rightInnerHtml: null,
  hideLeftInnerHtmlOnValue: false,
  hideRightInnerHtmlOnValue: false,
};

export class InputTextModel {
  constructor(args){
    for(let prop in model){
      this[prop] = args.hasOwnProperty(prop) ? args[prop] : model[prop]
    }
  }
};

export default model;