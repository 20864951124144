import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';

import * as commonConfig from '../../common/config';
import * as settingsConfig from './../../Settings/config'
import InformationModal from './../InformationModal';
import Button, {buttons} from './../../common/components/elements/Button';

class EmailConfirmationLinkSentModal extends PureComponent {
  render(){
    let {onClose} = this.props;

    return (
      <InformationModal
        header="Bekreft e-postadresse"
        onClose={onClose}
      >
        Vi har sendt deg en e-post med en link du må trykke på for å verifisere din e-postadresse til din e-postadresse.
        <br/>
        <br/>
        Hvis du ikke mottok noen e-post fra oss, så kan du sende en ny e-post fra{' '}
        <Button
          className="d-inline text-color1"
          button={buttons.SIMPLE}
          component={Link}
          to={commonConfig.routes.SETTINGS.buildPath({tab: settingsConfig.PROFILE.value})}
          onClick={onClose}
        >
          profilinnstillinger
        </Button>.
      </InformationModal>
    )
  }
};

export default EmailConfirmationLinkSentModal;