import {prefix} from './../config';

export const UPDATE_UI = prefix + 'UPDATE_UI';
export const UPDATE_DATA = prefix + 'UPDATE_DATA';
export const UPDATE_QUERY = prefix + 'UPDATE_QUERY';

export const RESET_PROFILE_FORM = prefix + 'RESET_PROFILE_FORM';
export const UPDATE_PROFILE = prefix + 'UPDATE_PROFILE';
export const UPDATE_PROFILE_COMMUNICATION = prefix + 'UPDATE_PROFILE_COMMUNICATION';
export const RESEND_CONFIRMATION_EMAIL = prefix + 'RESEND_CONFIRMATION_EMAIL';
export const UPDATE_PASSWORD = prefix + 'UPDATE_PASSWORD';
export const RESET_COMMUNICATION_FORM = prefix + 'RESET_COMMUNICATION_FORM';
export const CLOSE_SEND_CODE_MODAL = prefix + 'CLOSE_SEND_CODE_MODAL';
export const SEND_CODE = prefix + 'SEND_CODE';
export const CONFIRM_PHONE_NUMBER = prefix + 'CONFIRM_PHONE_NUMBER';
export const UNBIND_FB = prefix + 'UNBIND_FB';

export const updateUi = (updateOb) => ({type: UPDATE_UI, updateOb});
export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});
export const updateQuery = (updateOb) => ({type: UPDATE_QUERY, updateOb});

export const resetProfileForm = () => ({type: RESET_PROFILE_FORM});
export const updateProfile = () => ({type: UPDATE_PROFILE});
export const updateProfileCommunication = () => ({type: UPDATE_PROFILE_COMMUNICATION});
export const resendConfirmationEmail = () => ({type: RESEND_CONFIRMATION_EMAIL});
export const updatePassword = () => ({type: UPDATE_PASSWORD});
export const resetCommunicationForm = () => ({type: RESET_COMMUNICATION_FORM});
export const closeSendCodeModal = () => ({type: CLOSE_SEND_CODE_MODAL});
export const sendCode = () => ({type: SEND_CODE});
export const confirmPhoneNumber = () => ({type: CONFIRM_PHONE_NUMBER});
export const unbindFb = () => ({type: UNBIND_FB});