import * as config from '../config';

const getPropFromDecodedIdToken = ({session, propName}) => {
  return session && session.decodedIdToken && session.decodedIdToken[propName];
};
export const getUserId = (session) => {
  return getPropFromDecodedIdToken({session, propName: 'sub'});
};
export const getFirstName = (session) => {
  return getPropFromDecodedIdToken({session, propName: 'firstName'});
};
export const getLastName = (session) => {
  return getPropFromDecodedIdToken({session, propName: 'lastName'});
};
export const getFullName = (session) => {
  return getFirstName(session) && getLastName(session) ? `${getFirstName(session)} ${getLastName(session)}` : '';
};
export const getFacebookFullName = (session) => {
  let firstName = getPropFromDecodedIdToken({session, propName: 'facebookFirstName'});
  let lastName = getPropFromDecodedIdToken({session, propName: 'facebookLastName'});

  return firstName && lastName ? `${firstName} ${lastName}` : '';
};
export const getEmail = (session) => {
  return getPropFromDecodedIdToken({session, propName: 'name'});
};
export const getRole = (session) => {
  return getPropFromDecodedIdToken({session, propName: 'role'});
};
export const isAdmin = (session) => {
  return getRole(session) && getRole(session).includes('Admin');
};
export const isRegisteredViaPhoneNumber = (session) => {
  return getPropFromDecodedIdToken({session, propName: 'SelfRegisterPhoneNumber'});
};
export const isRegisteredViaFacebook = (session) => {
  return getPropFromDecodedIdToken({session, propName: 'SelfRegisterFacebook'});
};
export const getUserAvatar = (session) => {
  return getPropFromDecodedIdToken({session, propName: 'avatarUrl'});
};
export const mapRoleToMethod = {
  [config.roles.ADMIN] : isAdmin
};