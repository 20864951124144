export const data = {
  makes: null,
  models: null,
  bodyTypes: null,
  wheelDrives: null,
  exteriorColors: null,
  equipments: null,
  mapIdToEquipment: null,
  warrantyTypes: null,
  transmissions: null,
  engineFuels: null,
  chargerConnectors: null,
  autonomyLevels: null,
  registrationClasses: null,
  objectLocations: null,
  locationCountries: null,
  locationDistricts: null,
};