import {faBell, faAt, faRss} from '@fortawesome/free-solid-svg-icons';

export const prefix = 'NOTIFICATIONS::';
export const domain = 'Notifications';
export const componentName = 'Notifications';
export const getBy = 30;
export const processesNames = {
  getFirstPage: `${prefix}getFirstPage`,
  loadMore: `${prefix}loadMore`
};
export const mapTypeToConfig = {
  1: { // general
    faIcon: faBell,
    withButton: false,
  },
  2: { // email confirmed
    faIcon: faAt,
    withButton: false,
  },
  3: { // search subscription
    faIcon: faRss,
    withButton: true,
    buttonName: 'Åpne lagret søk'
  },
  4: { // object published
    faIcon: faBell,
    withButton: true,
    buttonName: 'Åpne annonse'
  }
};