import React from 'react';
import {Container} from 'reactstrap';
import {Link} from 'react-router-dom';

import * as commonConfig from '../../common/config';
import classname from '../../common/helpers/classname';
import Button, {buttons} from '../../common/components/elements/Button';
import Icon, {icons} from '../../common/components/elements/Icon';

import './style.scss';
import SidenavMenu from './SidenavMenu';
import Logo from './../Logo';
import NewItem from './../NewItem';

const componentName = 'MobileNavigation';
const cn = classname(componentName);

const MobileNavigation = React.memo(({session, totalNotifications, totalUnreadMessageCount, isSideNavOpen, userAvatarUrl, toggleSideNav, logout}) => {
  return (
    <Container className={componentName}>
      <div className={cn('top-left')}>
        <Button button={buttons.SIMPLE} className={cn('bars-button')}>
          <Icon
            icon={icons.BARS}
            onClick={toggleSideNav}
          />
        </Button>
        <Logo/>
      </div>
      <div className={cn('top-right')}>
        <NewItem/>
        {
          !session.isAuthorized ?
            <Link className={cn('login-button')} to={commonConfig.routes.LOGIN.path}>
              Logg inn
            </Link>
            :
            null
        }
      </div>
      <SidenavMenu
        session={session}
        totalNotifications={totalNotifications}
        totalUnreadMessageCount={totalUnreadMessageCount}
        isSideNavOpen={isSideNavOpen}
        userAvatarUrl={userAvatarUrl}
        toggleSideNav={toggleSideNav}
        logout={logout}
      />
    </Container>
  )
});

export default MobileNavigation;