const _prefix = 'SESSION::';

export const SET_SESSION = _prefix + 'SET_SESSION';
export const UNSET_SESSION = _prefix + 'UNSET_SESSION';
export const UPDATE_SESSION = _prefix + 'UPDATE_SESSION';

export const SUCCESSFUL_LOGIN = _prefix + 'SUCCESSFUL_LOGIN';
export const SUCCESSFUL_LOGIN_WITH_FACEBOOK = _prefix + 'SUCCESSFUL_LOGIN_WITH_FACEBOOK';
export const FAILED_TO_LOGIN = _prefix + 'FAILED_TO_LOGIN';
export const FAILED_TO_LOGIN_WITH_PASSWORD = _prefix + 'FAILED_TO_LOGIN_WITH_PASSWORD';
export const FETCH_PROFILE = _prefix + 'FETCH_PROFILE';
export const PROFILE_IS_UPDATED = _prefix + 'PROFILE_IS_UPDATED';

export const setSession = (session) => ({type: SET_SESSION, session});
export const unsetSession = () => ({type: UNSET_SESSION});
export const updateSession = (updateOb) => ({type: UPDATE_SESSION, updateOb});

export const successfulLogin = () => ({type: SUCCESSFUL_LOGIN});
export const successfulLoginWithFacebook = () => ({type: SUCCESSFUL_LOGIN_WITH_FACEBOOK});
export const failedToLogin = () => ({type: FAILED_TO_LOGIN});
export const failedToLoginWithPassword = () => ({type: FAILED_TO_LOGIN_WITH_PASSWORD});
export const fetchProfile = () => ({type: FETCH_PROFILE});
export const profileIsUpdated = () => ({type: PROFILE_IS_UPDATED});