import * as reduxSagaService from './../../../common/services/redux-saga';

import {watchFetchThreads} from './threads/fetch';
import {watchNewMessage} from './messages/onNewMessage';
import {watchDismissedMessages} from './messages/onDismissedThreadMessages';

export default reduxSagaService.createRootSaga(
  watchFetchThreads,
  watchNewMessage,
  watchDismissedMessages,
);