import {take} from 'redux-saga/effects';

import * as commonService from './../../../index';
import * as reduxSagaService from '../../../redux-saga';
import * as cookieConsentActions from '../../../../components/blocks/CookieConsent/redux/actions';

import * as config from '../config';

const loadTag = () => {
  // NOTE: we do not use noscript code, which must be added according to the installation instructions, because we do not need it
  // see: https://www.analyticsmania.com/post/google-tag-manager-noscript/

  // load gtm tag

  let scriptElem = document.createElement('script');

  let inlineScript = document.createTextNode(`
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${config.gtmId}');
  `);

  scriptElem.appendChild(inlineScript);

  document.head.appendChild(scriptElem);
};

export function* watchCookieConsentIsOk(){
  yield take(cookieConsentActions.IS_OK);

  if(commonService.isPrerenderMode())
    return;

  loadTag();
};

export default reduxSagaService.createRootSaga(
  watchCookieConsentIsOk
);