import {call, put, takeLatest} from 'redux-saga/effects';

import * as actions from './actions';
import * as immutable from './../../helpers/immutable';
import * as colorsHelper from '../../services/colors';
import * as reduxSagaService from './../../services/redux-saga';
import * as signalrActions from './../../services/signalr/actions';
import request from './../../services/request';

export const processNames = {
  FETCH_MAKES: 'fetchMakes',
  FETCH_EQUIPMENTS: 'fetchEquipments',
};

export function* fetchMakes(){
  let result = yield call(request.get, {
    path: 'Value/objecttype/Car/list/MakeModel',
    processName: processNames.FETCH_MAKES
  });

  if(result instanceof Error) return;

  yield put(actions.updateData({makes: result}));

  return result;
};

export function* fetchModels({id, makeId}){

  // 'makeId' is provided when 'fetchModels' function is called directly
  // 'id' is provided on 'MAKE_MODEL_UPDATED' action

  makeId = makeId || id;

  if(!makeId)
    return;

  let result = yield call(request.get, {
    path: `Value/objecttype/Car/list/MakeModel/byparent/${makeId}`,
    processName: 'fetchModels'
  });

  if(result instanceof Error) return result;

  yield put(actions.updateData({models: {[makeId]: result}}));
  
  return result;
};

export function* fetchBodyTypes(){
  let result = yield call(request.get, {
    path: `Value/list/BodyType`,
    processName: 'fetchBodyTypes'
  });

  if(result instanceof Error) return;

  yield put(actions.updateData({bodyTypes: result}));

  return result;
};

export function* fetchWheelDrives(){
  let result = yield call(request.get, {
    path: `Value/list/wheelDrive`,
    processName: 'fetchWheelDrives'
  });

  if(result instanceof Error) return;

  yield put(actions.updateData({wheelDrives: result}));

  return result;
};

export function* fetchExteriorColors(){
  let result = yield call(request.get, {
    path: `Value/list/exteriorColor`,
    processName: 'fetchExteriorColors'
  });

  if(result instanceof Error) return;

  // extend objects with css colors

  result = result.map(c => immutable.updateObjectProps(c, {color: colorsHelper.get(c.value)})).filter(c => c);

  yield put(actions.updateData({exteriorColors: result}));
  
  return result;
};

export function* fetchEquipments(){
  let result = yield call(request.get, {
    path: `Value/objecttype/Car/list/Equipment`,
    processName: processNames.FETCH_EQUIPMENTS
  });

  if(result instanceof Error) return;

  let mapIdToEquipment = result.reduce((ob, eq) => {ob[eq.id] = eq; return ob;}, {});

  yield put(actions.updateData({equipments: result, mapIdToEquipment}));

  return result;
};

export function* fetchWarrantyTypes(){
  let result = yield call(request.get, {
    path: `Value/list/warrantyType`,
    processName: 'fetchWarrantyTypes'
  });

  if(result instanceof Error) return;

  yield put(actions.updateData({warrantyTypes: result}));

  return result;
};

export function* fetchTransmissions(){
  let result = yield call(request.get, {
    path: `Value/list/transmission`,
    processName: 'fetchTransmissions'
  });

  if(result instanceof Error) return;

  yield put(actions.updateData({transmissions: result}));

  return result;
};

export function* fetchEngineFuels(){
  let result = yield call(request.get, {
    path: `Value/list/engineFuel`,
    processName: 'fetchEngineFuels'
  });

  if(result instanceof Error) return result;

  yield put(actions.updateData({engineFuels: result}));

  return result;
};

export function* fetchChargerConnectors(){
  let result = yield call(request.get, {
    path: `Value/list/ChargerConnector`,
    processName: 'fetchChargerConnectors'
  });

  if(result instanceof Error) return;

  yield put(actions.updateData({chargerConnectors: result}));

  return result;
};

export function* fetchAutonomyLevels(){
  let result = yield call(request.get, {
    path: `Value/list/AutonomyLevel`,
    processName: 'fetchAutonomyLevels'
  });

  if(result instanceof Error) return;

  yield put(actions.updateData({autonomyLevels: result}));

  return result;
};

export function* fetchRegistrationClasses(){
  let result = yield call(request.get, {
    path: `Value/list/registrationClass`,
    processName: 'fetchRegistrationClasses'
  });

  if(result instanceof Error) return;

  yield put(actions.updateData({registrationClasses: result}));

  return result;
};

export function* fetchObjectLocations(){
  let result = yield call(request.get, {
    path: `Value/list/objectLocation`,
    processName: 'fetchObjectLocations'
  });

  if(result instanceof Error) return;

  yield put(actions.updateData({objectLocations: result}));

  return result;
};

export function* fetchLocationCountries(){
  let result = yield call(request.get, {
    path: `Value/list/locationCountry`,
    processName: 'fetchLocationCountries'
  });

  if(result instanceof Error) return;

  yield put(actions.updateData({locationCountries: result}));

  return result;
};

export function* fetchLocationDistricts(){
  let result = yield call(request.get, {
    path: `Value/list/locationDistrict`,
    processName: 'fetchLocationDistricts'
  });

  if(result instanceof Error) return;

  yield put(actions.updateData({locationDistricts: result}));

  return result;
};

export function* fetchMakeModelTree(){
  let result = yield call(request.get, {
    path: `Value/objecttype/Car/list/MakeModel/includechildren`,
    processName: 'fetchMakeModelTree'
  });

  if(result instanceof Error) return;

  yield put(actions.updateData({makeModelTree: result}));

  return result;
};

export function* watchFetchMakes(){
  yield takeLatest([actions.FETCH_MAKES, signalrActions.MAKE_MODEL_IS_UPDATED], fetchMakes);
};

export function* watchFetchModels(){
  yield takeLatest([actions.FETCH_MODELS, signalrActions.MAKE_MODEL_IS_UPDATED], fetchModels);
};

export function* watchFetchBodyTypes(){
  yield takeLatest(actions.FETCH_BODY_TYPES, fetchBodyTypes);
};

export function* watchFetchWheelDrives(){
  yield takeLatest(actions.FETCH_WHEEL_DRIVES, fetchWheelDrives);
};

export function* watchFetchExteriorColors(){
  yield takeLatest(actions.FETCH_EXTERIOR_COLORS, fetchExteriorColors);
};

export function* watchFetchEquipments(){
  yield takeLatest(actions.FETCH_EQUIPMENTS, fetchEquipments);
};

export function* watchFetchWarrantyTypes(){
  yield takeLatest(actions.FETCH_WARRANTY_TYPES, fetchWarrantyTypes);
};

export function* watchFetchTransmission(){
  yield takeLatest(actions.FETCH_TRANSMISSIONS, fetchTransmissions);
};

export function* watchFetchEngineFuels(){
  yield takeLatest(actions.FETCH_ENGINE_FUELS, fetchEngineFuels);
};

export function* watchFetchChargerConnectors(){
  yield takeLatest(actions.FETCH_CHARGER_CONNECTORS, fetchChargerConnectors);
};

export function* watchFetchAutonomyLevels(){
  yield takeLatest(actions.FETCH_AUTONOMY_LEVELS, fetchAutonomyLevels);
};

export function* watchFetchRegistrationClasses(){
  yield takeLatest(actions.FETCH_REGISTRATION_CLASSES, fetchRegistrationClasses);
};

export function* watchFetchObjectLocations(){
  yield takeLatest(actions.FETCH_OBJECT_LOCATIONS, fetchObjectLocations);
};

export function* watchFetchLocationCountries(){
  yield takeLatest(actions.FETCH_OBJECT_LOCATIONS, fetchLocationCountries);
};

export function* watchFetchLocationDistricts(){
  yield takeLatest(actions.FETCH_LOCATION_DISTRICTS, fetchLocationDistricts);
};

export function* watchFetchMakeModelTree(){
  yield takeLatest(actions.FETCH_MAKE_MODEL_TREE, fetchMakeModelTree);
};

export default reduxSagaService.createRootSaga(
  watchFetchMakes,
  watchFetchModels,
  watchFetchBodyTypes,
  watchFetchWheelDrives,
  watchFetchExteriorColors,
  watchFetchEquipments,
  watchFetchWarrantyTypes,
  watchFetchTransmission,
  watchFetchEngineFuels,
  watchFetchChargerConnectors,
  watchFetchAutonomyLevels,
  watchFetchRegistrationClasses,
  watchFetchObjectLocations,
  watchFetchLocationCountries,
  watchFetchLocationDistricts,
  watchFetchMakeModelTree
);