import React, {PureComponent} from 'react';

import InformationModal from './../InformationModal';

class PasswordReset extends PureComponent {
  render(){
    let {onClose, payload} = this.props;

    return (
      <InformationModal
        header="Passordet er tilbakestilt"
        onClose={onClose}
      >
      </InformationModal>
    )
  }
};

export default PasswordReset;