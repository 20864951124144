export const mapStatusNameToConfig = {
  INITIAL: {
    name: 'Initiell',
    value: 0
  },
  DRAFTS: {
    name: 'Kladd',
    value: 1
  },
  ON_REVIEW: {
    name: 'Til gjennomgang',
    actionName: 'Publiser',
    hint: 'Sender til gjennomgang, blir søkbar etter kort tid',
    value: 2
  },
  REPLACED: {
    name: 'Gammel versjon',
    value: 3
  },
  ACTIVE: {
    name: 'Aktiv',
    actionName: 'Aktive',
    hint: 'blir søkbar igjen',
    value: 4
  },
  SOLD: {
    name: 'Solgt',
    actionName: 'Marker som solgt',
    hint: 'Blir ikke lenger søkbar',
    value: 5
  },
  EXPIRED: {
    name: 'Deaktivert',
    actionName: 'Deaktiver',
    hint: 'Blir ikke lenger søkbar',
    value: 6
  },
  DELETED: {
    name: 'Slettet',
    actionName: 'Slett',
    hint: 'Kan ikke omgjøres',
    value: 7
  }
};

export const mapStatusToConfig = {
  [mapStatusNameToConfig.INITIAL.value]: mapStatusNameToConfig.INITIAL,
  [mapStatusNameToConfig.DRAFTS.value]: mapStatusNameToConfig.DRAFTS,
  [mapStatusNameToConfig.ON_REVIEW.value]: mapStatusNameToConfig.ON_REVIEW,
  [mapStatusNameToConfig.REPLACED.value]: mapStatusNameToConfig.REPLACED,
  [mapStatusNameToConfig.ACTIVE.value]: mapStatusNameToConfig.ACTIVE,
  [mapStatusNameToConfig.SOLD.value]: mapStatusNameToConfig.SOLD,
  [mapStatusNameToConfig.EXPIRED.value]: mapStatusNameToConfig.EXPIRED,
  [mapStatusNameToConfig.DELETED.value]: mapStatusNameToConfig.DELETED,
};