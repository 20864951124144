import React from 'react';

import * as commonConfig from '../../common/config';
import Button from '../../common/components/elements/Button';
import Icon, {icons} from '../../common/components/elements/Icon';
import CustomLink from '../../common/components/other/Link';

import './style.scss';

const NewItem = React.memo(() => {
  return (
    <Button
      color="color1"
      className="NewItemButton"
      component={CustomLink}
      to={commonConfig.routes.ADD_OBJECT.buildPath()}
      isProtected={commonConfig.routes.ADD_OBJECT.isProtected}
    >
      <Icon
        icon={icons.PLUS}
      />
      Selg bil
    </Button>
  );
});

export default NewItem;