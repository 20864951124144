import {lazy} from 'react';

import {roles} from './roles';

const Home = lazy(() => import(/* webpackChunkName: "HomePage" */'./../../Home'));
const Login = lazy(() => import(/* webpackChunkName: "LoginPage" */'./../../Login'));
const Register = lazy(() => import(/* webpackChunkName: "RegisterPage" */'./../../Register'));
const ResetPassword = lazy(() => import(/* webpackChunkName: "ResetPasswordPage" */'./../../ResetPassword'));
const Browse = lazy(() => import(/* webpackChunkName: "BrowsePage" */'./../../Browse'));
const AddObject = lazy(() => import(/* webpackChunkName: "AddObjectPage" */'./../../AddObject'));
const VehiclePage = lazy(() => import(/* webpackChunkName: "VehiclePage" */'./../../VehiclePage'));
const Chat = lazy(() => import(/* webpackChunkName: "ChatPage" */'./../../Chat'));
const Privacy = lazy(() => import(/* webpackChunkName: "PrivacyPage" */'./../../Privacy'));
//const Terms = lazy(() => import(/* webpackChunkName: "TermsPage" */'./../../Terms'));
const MyAds = lazy(() => import(/* webpackChunkName: "MyAdsPage" */'./../../MyAds'));
const SearchTemplates = lazy(() => import(/* webpackChunkName: "SearchTemplatesPage" */'./../../SearchTemplates'));
const Settings = lazy(() => import(/* webpackChunkName: "SettingsPage" */'./../../Settings'));
const ConfirmEmail = lazy(() => import(/* webpackChunkName: "ConfirmEmailPage" */'./../../ConfirmEmail'));
const Contact = lazy(() => import(/* webpackChunkName: "ContactPage" */'./../../Contact'));
const Notifications = lazy(() => import(/* webpackChunkName: "NotificationsPage" */'./../../Notifications'));
const Admin = lazy(() => import(/* webpackChunkName: "AdminPage" */'./../../Admin'));
const AdminAds = lazy(() => import(/* webpackChunkName: "AdminAdsPage" */'./../../AdminAds'));
const AdminUsers = lazy(() => import(/* webpackChunkName: "AdminUsersPage" */'./../../AdminUsers'));

export const routes = {
  HOME: {
    path: '/home',
    name: 'Kjøp og selg bruktbil',
    shortName: 'Forside',
    component: Home,
    description: 'Gratis markedsplass for kjøp og salg av brukte biler eller andre typer kjøretøy'
  },
  REGISTER: {
    path: '/register',
    name: 'Register',
    component: Register,
    description: 'Registrer ny bruker'
  },
  LOGIN: {
    path: '/login',
    name: 'Logg inn',
    component: Login,
    description: 'Logg inn på siden'
  },
  RESET_PASSWORD: {
    path: '/reset-password',
    name: 'Tilbakestill passord',
    component: ResetPassword,
    description: 'Tilbakestill passord hvis det er glemt'
  },
  PRIVACY: {
    path: '/privacy',
    name: 'Personvernserklæring',
    component: Privacy,
    description: 'Personvernserklæring'
  },
  //TERMS: {
  //  path: '/terms',
  //  name: 'Vilkår',
  //  description: 'Description of the page',
  //  component: Terms,
  //},
  VEHICLE_PAGE: {
    path: '/browse/:id/:idType?',
    name: 'Annonse',
    description: 'Annonsedetaljer',
    component: VehiclePage,
    buildPath: ({id, isObjectId = false}) => `/browse/${id}${isObjectId ? '/objectId' : ''}`
  },
  BROWSE: {
    path: '/browse',
    name: 'Søk',
    description: 'Søk etter kjøretøy',
    component: Browse,
  },
  CONFIRM_EMAIL: {
    path: '/confirm-email/:email/:code',
    name: 'Bekreft e-post',
    description: 'Bekreft e-postadressen din',
    component: ConfirmEmail
  },
  CONTACT: {
    path: '/contact',
    name: 'Kontakt oss',
    description: 'Kontakt oss hvis du har spørsmål eller kommentarer',
    component: Contact,
  },
  // protected routes
  ADD_OBJECT: {
    path: '/ad/:action?/:id?',
    name: 'Selg kjøretøy',
    description: 'Selg kjøretøy',
    component: AddObject,
    isProtected: true,
    buildPath: ({action, id} = {}) => {
      if(!action) return '/ad';

      return id ? `/ad/${action}/${id}` : `/ad/${action}`
    },
  },
  CHAT: {
    path: '/chat',
    name: 'Meldinger',
    component: Chat,
    isProtected: true
  },
  MY_ADS: {
    path: '/my-ads',
    name: 'Mine annonser',
    component: MyAds,
    isProtected: true,
    buildPath: ({tab}) => tab ? `/my-ads?tab=${tab}` : '/my-ads'
  },
  SEARCH_TEMPLATES: {
    path: '/search-templates',
    name: 'Lagrede søk',
    component: SearchTemplates,
    isProtected: true
  },
  NOTIFICATIONS: {
    path: '/notifications',
    name: 'Varsler',
    component: Notifications,
    isProtected: true
  },
  SETTINGS: {
    path: '/settings',
    name: 'Innstillinger',
    component: Settings,
    buildPath: ({tab}) => tab ? `/settings?tab=${tab}` : `/settings`,
    isProtected: true
  },
  ADMIN_ADS: {
    path: '/admin/ads',
    name: 'Administrer annonser',
    component: AdminAds,
    isProtected: true,
    role: roles.ADMIN
  },
  ADMIN_USERS: {
    path: '/admin/users',
    name: 'Administrer brukere',
    component: AdminUsers,
    isProtected: true,
    role: roles.ADMIN
  },
  ADMIN: {
    path: '/admin',
    name: 'Administrasjon',
    component: Admin,
    isProtected: true,
    role: roles.ADMIN
  },
};