export const getThreadsBy = 10;
export const getMessagesBy = 20;
export const countOfBunchesForFirstRequest = 5;

export const processNames = {
  SELECT_THREAD: 'SELECT_THREAD',
  FETCH_THREADS: 'FETCH_THREADS',
  LOAD_MORE_MESSAGES: 'LOAD_MORE_MESSAGES',
  LOAD_MORE_THREADS: 'LOAD_MORE_THREADS',
  LOAD_MESSAGE_FILE: 'LOAD_MESSAGE_FILE',
  sendMessage: (id) => `SEND_MESSAGE_${id}`
};

export const messageSendStatuses = {
  SENDING: 'SENDING',
  SENT: 'SENT',
  FAILED: 'FAILED',
};

export const messageStatuses = {
  ...messageSendStatuses,
  READ: 'READ'
};

export const mapStatusToConfig = {
  [messageStatuses.SENDING]: {
    title: 'Melding sendes ...'
  },
  [messageStatuses.SENT]: {
    title: 'Sendt inn'
  },
  [messageStatuses.FAILED]: {
    title: 'Det oppstod en feil under sending av meldingen'
  },
  [messageStatuses.READ]: {
    title: 'Lest'
  }
};