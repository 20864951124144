import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="18px" height="20px" viewBox="0 0 18 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-544.000000, -475.000000)" fill="#8BBDD9">
            <path d="M553.313708,495.313708 C550.005137,495.313708 547.313708,493.070851 547.313708,490.313708 L547.313708,478.885137 C547.313708,476.916566 549.237137,475.313708 551.599423,475.313708 C553.963423,475.313708 555.885137,476.916566 555.885137,478.885137 L555.885137,488.885137 C555.885137,490.066566 554.731423,491.027994 553.313708,491.027994 C551.895994,491.027994 550.74228,490.066566 550.74228,488.885137 L550.74228,480.313708 C550.74228,479.919423 551.12628,479.599423 551.599423,479.599423 C552.072566,479.599423 552.456566,479.919423 552.456566,480.313708 L552.456566,488.885137 C552.456566,489.277994 552.84228,489.599423 553.313708,489.599423 C553.785137,489.599423 554.170851,489.277994 554.170851,488.885137 L554.170851,478.885137 C554.170851,477.703708 553.017137,476.74228 551.599423,476.74228 C550.181708,476.74228 549.027994,477.703708 549.027994,478.885137 L549.027994,490.313708 C549.027994,492.283708 550.951423,493.885137 553.313708,493.885137 C555.677708,493.885137 557.599423,492.283708 557.599423,490.313708 L557.599423,480.313708 C557.599423,479.919423 557.983423,479.599423 558.456566,479.599423 C558.929708,479.599423 559.313708,479.919423 559.313708,480.313708 L559.313708,490.313708 C559.313708,493.070851 556.62228,495.313708 553.313708,495.313708 Z" id="Attachment" transform="translate(553.313708, 485.313708) rotate(45.000000) translate(-553.313708, -485.313708) "></path>
          </g>
        </g>
      </svg>
    );
});

export default Icon;