import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-4.000000, -4.000000)">
            <path d="M13.6159423,11.9950462 L19.6420128,5.96644104 C20.0877112,5.52055521 20.0877112,4.7968482 19.6420128,4.34981906 C19.1951716,3.90393323 18.4717689,3.90393323 18.0260705,4.34981906 L12,10.3784242 L5.94764473,4.32352323 C5.92707403,4.30294388 5.89850362,4.2960841 5.87679011,4.27664805 C5.40252134,3.89478685 4.74997322,3.90621982 4.33170244,4.32352323 C3.91457448,4.74196994 3.90314632,5.39479253 4.26770472,5.84410826 L10.3714867,12.0064792 L4.33513089,18.0465173 C3.8882897,18.4935464 3.8882897,19.2172535 4.33513089,19.6631393 C4.78082926,20.1101684 5.50423199,20.1101684 5.95107318,19.6631393 L11.9885718,13.6242445 L18.0374987,19.6757156 C18.495768,20.1101684 19.2191707,20.1101684 19.6648691,19.6631393 C20.1117103,19.2172535 20.1117103,18.4935464 19.6648691,18.0465173 L13.6159423,11.9950462 Z" id="path-1"></path>
          </g>
        </g>
      </svg>
    );
});

export default Icon;