import React from 'react';
import {take, put, select, call, takeEvery} from 'redux-saga/effects';

import * as actions from './../../actions';
import * as selectors from './../../selectors';
import {dismissById} from './../common/dismiss';
import {mapTypeToConfig} from './../../../config';
import {routes} from './../../../../common/config';
import * as signalrServiceActions from './../../../../common/services/signalr/actions';
import extendItem from './extendItem';
import showSnackbar from './../common/showSnackbar';

/***
 model: {
    count: null
    createdDate: "2019-11-12T11:15:31.7618267Z"
    date: "2019-11-12T11:15:31.7618267Z"
    dismissedDate: null
    foreignKey: null
    id: "7c6e14a4-ac86-4d3b-b6c3-2129c996a39e"
    isDismissed: false
    modifiedDate: null
    text: "The text in notification"
    type: 1
    __proto__: Object
 }
 */

function* _showSnackbar({item}){
  let header = item.type === 3 ? 'Nye biler tilgjengelig' : '';
  let faIcon = mapTypeToConfig[item.type] && mapTypeToConfig[item.type].faIcon;
  let text = item.text;

  yield call(showSnackbar, {
    header,
    faIcon,
    text,
    withButton: true,
    buttonName: 'Åpne varsler',
    buttonLink: routes.NOTIFICATIONS.path
  });
}

export function* handleNewNotification({payload}){
  let data = yield select(selectors.getData);

  if(!data.grid.rows)
    return;

  let extendedItem = yield call(extendItem, {item: payload});

  yield put(actions.updateData({
    grid: {
      rows: [
        extendedItem,
        ...data.grid.rows
      ],
      totalCount: data.grid.totalCount + 1,
      notDismissedCount: data.grid.notDismissedCount + 1
    }
  }));

  yield call(_showSnackbar, {item: extendedItem});

  let ui = yield select(selectors.getUi);

  if(ui.isPageMounted)
    yield call(dismissById, {id: extendedItem.id});
}

export function* watchNewNotification(){
  yield takeEvery(signalrServiceActions.NOTIFICATION, handleNewNotification);
}