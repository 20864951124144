import {put} from 'redux-saga/effects';
import {delay} from 'redux-saga';

const _reverseString = (str) => str.split('').reverse().join('');

export const isDev = process.env.NODE_ENV === 'development';

export const isImageFile = (name) => /\.(jpg)|(jpeg)|(png)$/.test(name);

export const isNumber = (value) => /^\d{1,}((\.|\,)\d{1,})?$/.test(value);

export const prettyNumber = (number, {unit} = {}) => {
  if(!isNumber(number))
    return '';

  let str = String(number);

  str = str.replace(/(\s+)/g, '');
  str = _reverseString(str);
  str = str.replace(/(\d{3})/g, '$1 ');
  str = _reverseString(str);

  if(!!unit)
    str = `${str} ${unit}`;

  return str;
};

export const getScrollbarWidth = () => {
  // taken from: https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
  var outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.width = "100px";
  outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

  document.body.appendChild(outer);

  var widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = "scroll";

  // add innerdiv
  var inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);

  var widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
};

export const generateId = () => 'id_' + (Math.random().toString(36).substr(2));

export function* timer({startTime, updateActionCreator}){
  let timeLeft = startTime;

  yield put(updateActionCreator(timeLeft));

  while(timeLeft > 0){
    yield delay(1000);

    timeLeft -=1;

    yield put(updateActionCreator(timeLeft));
  }
};