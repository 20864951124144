import React from 'react';
import {Link} from 'react-router-dom';
import {faCamera} from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import classname from './../../common/helpers/classname';
import Avatar, {sizes as avatarSizes} from './../../common/components/blocks/Avatar';
import {icons} from './../../common/components/elements/Icon';
import Button, {buttons} from './../../common/components/elements/Button';

const componentName = 'NewMessageSnackbarContent';
const cn = classname(componentName);

const SnackbarContent = React.memo(({isSimpleThread, thumbnail, carName, userName, messageText, messageFile, carLink, chatLink}) => {
  return (
    <React.Fragment>
      {
        !isSimpleThread ?
          <header className={cn('car')}>
            <Avatar
              className={cn('car-avatar')}
              image={thumbnail}
              size={avatarSizes.MEDIUM}
              faIcon={faCamera}
              icon={icons.PHOTOS}
            />
            <Button
              className={cn('car-link')}
              component={Link}
              to={carLink}
              button={buttons.SIMPLE}
            >
              {carName}
            </Button>
          </header>
          :
          null
      }
      <p className={cn('message')}>
        <span className={cn('message-username')}>
          {userName}:
        </span>
        {
          messageText ?
            <React.Fragment>
              <br/>
              <br/>
              <span>
                {messageText}
              </span>
            </React.Fragment>
            :
            null
        }
        {
          messageFile ?
            <React.Fragment>
              <br/>
              <br/>
              <span>
                Vedlagt fil: {messageFile.fileName}
              </span>
            </React.Fragment>
            :
            null
        }
      </p>
      <Button
        className={cn('open-button')}
        component={Link}
        to={chatLink}
        button={buttons.SIMPLE}
      >
        Åpne meldinger
      </Button>
    </React.Fragment>
  )
});

export default SnackbarContent;