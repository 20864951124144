import React, {PureComponent} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBell} from '@fortawesome/free-regular-svg-icons';

import classname from './../../common/helpers/classname';
import Button, {buttons} from './../../common/components/elements/Button';
import Icon, {icons} from './../../common/components/elements/Icon';

import './style.scss';
import * as config from './../config';
import beepSound from './beep.mp3';

let componentName = 'Snackbar';
let cn = classname(componentName);

export const {types, texts} = config;

const HeaderIcon = React.memo(({type, icon, faIcon}) => {
  if(icon){
    return (
      <Icon icon={icon}/>
    )
  } else if(faIcon){
    return (
      <FontAwesomeIcon
        icon={faIcon}
      />
    )
  } else if(type === config.types.ERROR || type === config.types.WARNING){
    return (
      <Icon
        icon={icons.WARNING}
      />
    )
  } else {
    return (
      <FontAwesomeIcon
        icon={faBell}
      />
    )
  }
});

class Snackbar extends PureComponent {
  setSetTimeout = ({setShortLifeTime = false} = {}) => {
    let {item, deleteByKey} = this.props;

    let lifeTime;

    if(setShortLifeTime)
      lifeTime = 3000;
    else if(item.lifeTime)
      lifeTime = item.lifeTime;
    else
      lifeTime = item.type === config.types.INFO || item.type === config.types.WARNING ? 5000 : 3000;

    this.deleteTimeout = setTimeout(
      () => deleteByKey(item.key),
      lifeTime
    );
  };
  onClose = () => {
    let {item, deleteByKey} = this.props;

    clearTimeout(this.deleteTimeout);

    deleteByKey(item.key);
  };
  onMouseEnter = () => {
    clearTimeout(this.deleteTimeout);
  };
  onMouseLeave = () => {
    this.setSetTimeout({setShortLifeTime: true});
  };
  componentDidMount(){
    let {item: {withBeep}} = this.props;

    this.setSetTimeout();

    if(withBeep){
      let audio = new Audio(beepSound);

      audio.load();
      audio.play();
    }
  };
  render(){
    let {item} = this.props;

    let {type, header, text, icon, faIcon} = item;

    return (
      <div
        className={cn.join(componentName, cn(type))}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <h6 className={cn('header')}>
          <HeaderIcon
            type={type}
            icon={icon}
            faIcon={faIcon}
          />
          <div className="ml-2">{header || config.mapTypeToConfig[type].name}</div>
          <Button
            className={cn('header-close-button')}
            button={buttons.SIMPLE}
            onClick={this.onClose}
          >
            <Icon
              icon={icons.CLOSE}
            />
          </Button>
        </h6>
        <div className={cn('body')}>
          {
            Array.isArray(text) ?
              text.map(t => {
                return (
                  <React.Fragment key={t}>
                    <span>{t}</span>
                    <br/>
                  </React.Fragment>
                )
              })
              :
              text
          }
        </div>
      </div>
    )
  }
};

export default Snackbar;