import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="16px" height="19px" viewBox="0 0 16 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1103.000000, -264.000000)" fill="#C1C7C9">
            <g transform="translate(100.000000, 248.000000)">
              <g>
                <path d="M1009.5,18.1698966 C1006.91216,18.829858 1005,21.15767 1005,23.9301868 L1005,27.8950761 C1005,28.2603825 1004.70058,28.5565217 1004.33403,28.5565217 C1003.59727,28.5565217 1003,29.1497699 1003,29.8782609 C1003,30.6082372 1003.59802,31.2 1004.33663,31.2 L1017.66337,31.2 C1018.40157,31.2 1019,30.6067519 1019,29.8782609 C1019,29.1482845 1018.39993,28.5565217 1017.66597,28.5565217 C1017.29817,28.5565217 1017,28.2626787 1017,27.8950761 L1017,23.9301868 C1017,21.1587673 1015.08824,18.8300823 1012.5,18.1699214 L1012.5,17.4872386 C1012.5,16.6639254 1011.82843,16 1011,16 C1010.17326,16 1009.5,16.6658594 1009.5,17.4872386 L1009.5,18.1698966 Z M1008.6,32 L1013.4,32 C1013.4,33.3254834 1012.32548,34.4 1011,34.4 C1009.67452,34.4 1008.6,33.3254834 1008.6,32 Z" id="Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
});

export default Icon;