const _prefix = 'PROCESSES::';

export const ADD_PROCESS = _prefix + 'ADD_PROCESS';
export const SET_SUCCESS = _prefix + 'SET_SUCCESS';
export const SET_ERROR = _prefix + 'SET_ERROR';
export const UPDATE = _prefix + 'UPDATE';

export const addProcess = (processName) => ({type: ADD_PROCESS, processName});
export const setSuccess = (processName, status) => ({type: SET_SUCCESS, processName, status});
export const setError = (processName, status, errorMessage, payload) => {

  let statusType = Object.prototype.toString.call(status);

  if(statusType == '[object Object]' || statusType == '[object Error]'){
    ({status, message: errorMessage, payload} = status);
  };

  return ({type: SET_ERROR, processName, status, errorMessage, payload});
};
export const update = (updateOb) => ({type: UPDATE, updateOb});