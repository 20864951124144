import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import * as sessionSelectors from './../../../redux/session/selectors';
import * as snackbarActions from './../../../../Snackbars/redux/actions';
import {types as snackbarTypes, texts as snackbarTexts} from './../../../../Snackbars/config';

const mapStateToProps = state => ({
  session: sessionSelectors.getSession(state)
});

class CustomLink extends PureComponent {
  warn = (e) => {
    e.preventDefault();

    let {dispatch} = this.props;

    dispatch(snackbarActions.push({type: snackbarTypes.WARNING, text: snackbarTexts.w2}));
  };
  render(){
    let {session, route, to, isProtected, children, className = ''} = this.props;

    if(route){
      to = to || route.path;
      isProtected = this.props.hasOwnProperty('isProtected') ? isProtected : route.isProtected;
    }

    return (
      <Link
        to={to}
        className={className}
        onClick={isProtected && !session.isAuthorized? this.warn : null}
      >
        {children}
      </Link>
    )
  }
};

export default connect(mapStateToProps)(CustomLink);
