import * as commonConfig from '../common/config';

export const componentName = 'Settings';
export const domain = 'Settings';
export const prefix = 'SETTINGS::';

export const PROFILE = {
  name: 'Profil',
  value: 1
};
export const PASSWORD = {
  name: 'Passord',
  value: 2
};
export const COMMUNICATION = {
  name: 'Kommunikasjon',
  alternativeName: 'Kommunikasjonspreferanser', // Longer heading
  value: 3
};
export const SOCIAL_NETWORKS = {
  name: 'Sosiale medier',
  value: 4
};

export const tabs = [PROFILE, PASSWORD, COMMUNICATION, SOCIAL_NETWORKS];

export const mapTabToConfig = {
  [PROFILE.value]: PROFILE,
  [PASSWORD.value]: PASSWORD,
  [COMMUNICATION.value]: COMMUNICATION,
  [SOCIAL_NETWORKS.value]: SOCIAL_NETWORKS,
};

export const urlProps = [{
  name: 'tab',
  type: Number,
  defaultValue: PROFILE.value
}];