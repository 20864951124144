import React from 'react';
import {call, select, put, takeEvery} from 'redux-saga/effects';
import {delay} from 'redux-saga';

import * as actions from './../../actions';
import * as selectors from './../../selectors';
import * as signalrActions from './../../../../common/services/signalr/actions';
import * as sessionSelectors from './../../../../common/redux/session/selectors';
import * as userService from './../../../../common/services/user';
import * as scroll from './../../../../common/helpers/scroll';
import * as immutable from './../../../../common/helpers/immutable';
import * as snackbarsActions from './../../../../Snackbars/redux/actions';
import * as generalService from './../../../../common/services';
import request from './../../../../common/services/request';
import setChatContentToBottom from './../common/setChatContentToBottom';
import extendThread from './../common/extendThread';
import extendMessage from './../common/extendMessage';
import {increaseNewMessagesCounter} from './../common/newMessagesCounter';
import {increaseNewThreadsCounter} from './../common/newThreadsCounter';
import {routes} from './../../../../common/config';
import SnackbarContent from './../../../SnackbarContent';

const isDev = process.env.NODE_ENV === 'development';

/*
New message model:
  {
  "senderUserId": "da27a521-5e03-402c-161f-08d5dffe2c00",
  "senderFirstName": "Mike",
  "senderLastName": "Tayson 2",
  "objectId": "01631cec-622a-425b-8d3b-0a8ded8031d1",
  "objectShortId": "MA5R8",
  "thread": {
    "id": "a24b4f1d-479d-4288-b971-4b022f91aaff",
    "createdDate": "2019-06-27T21:56:52.4162848",
    "objectId": "01631cec-622a-425b-8d3b-0a8ded8031d1",
    "shortId": "MA5R8",
    "year": 2008,
    "makeIdText": "Peugeot",
    "modelText": null,
    "modelIdText": "207",
    "automaticDescription": "Bil: Peugeot 207 1,0l 123hk",
    "otherUserId": "da27a521-5e03-402c-161f-08d5dffe2c00",
    "otherUserFirstName": "Mike",
    "otherUserLastName": "Tayson 2",
    "photoMain": {
      "id": "b1b84a13-2d4e-4097-6a0a-08d601c829d4",
      "description": null,
      "url": "api/File/691657ca-8c23-4e68-362b-08d601c829cf",
      "urlThumbnail": "api/File/691657ca-8c23-4e68-362b-08d601c829cf/thumbnail"
    },
    "lastMessage": {
      "fromSelf": true,
      "id": "c92f7fdc-a8f3-4033-ab64-61efbe21fe73",
      "createdDate": "2019-10-09T10:19:07.6776685",
      "isRead": false,
      "content": "25",
      "file": {
        fileName: "Middle Front-end Developer.png"
        id: "cbc44988-ffe4-4431-e9f4-08d75151f86d"
        url: "api/File/cbc44988-ffe4-4431-e9f4-08d75151f86d"
        urlThumbnail: "api/File/cbc44988-ffe4-4431-e9f4-08d75151f86d/thumbnail"
      }
    }
  }
}
 */

/**
 * Updates thread or creates a new one.
 */

export function* updateThread({threadOfMessage}){
  let {threads} = yield select(selectors.getData);

  // try to find current thread in data.threads

  let threadsHasThreadOfMessage = false;
  let indexOfThread;

  if(threads && threads.rows && threads.rows.length){
    for(let i = 0; i < threads.rows.length; i++){
      let thread = threads.rows[i];

      if(threadOfMessage.id === thread.id){
        threadsHasThreadOfMessage = true;
        indexOfThread = i;
      }
    }
  }

  // update/put a thread

  let extendedThreadOfMessage = yield call(extendThread, threadOfMessage);

  if(threadsHasThreadOfMessage){
    extendedThreadOfMessage.unreadCount = threads.rows[indexOfThread].unreadCount;
  }

  let updateOb = {
    threads: {
      rows: threadsHasThreadOfMessage ?
        // putting thread object to first position of list
        [extendedThreadOfMessage, ...immutable.removeFromArrayByIndex(threads.rows, indexOfThread)] :
        [extendedThreadOfMessage, ...threads.rows],
      totalCount: threadsHasThreadOfMessage ? threads.totalCount : 1
    }
  };

  if(!threadsHasThreadOfMessage)
    yield increaseNewThreadsCounter();

  yield put(actions.updateData(updateOb));
}

/**
 * Puts a new message to a messages list or creates a new one.
 */

export function* addNewMessage({newMessage, threadOfMessage, messagesOfThread}){
  // return if messages of thread are not fetched yet
  // because first messages will be fetched whenever user selects this thread

  if(!messagesOfThread)
    return;

  let extendedMessage = yield call(extendMessage, threadOfMessage.lastMessage);

  let updateOb = {
    [threadOfMessage.id]: {
      rows: messagesOfThread ? immutable.pushToArray(messagesOfThread.rows, extendedMessage) : [extendedMessage],
      totalCount: messagesOfThread ? messagesOfThread.totalCount : 1
    }
  };

  yield increaseNewMessagesCounter({thread: threadOfMessage});

  yield put(actions.updateData(updateOb));
}

export function* showSnackbar({newMessage, threadOfMessage}){
  let thumbnail = generalService.prepareFileLink(threadOfMessage.photoMain && threadOfMessage.photoMain.urlThumbnail);

  let extendedThreadOfMessage = yield call(extendThread, threadOfMessage);

  let userName = extendedThreadOfMessage.derived.otherUserName;
  let carName = extendedThreadOfMessage.derived.carNameWithYear;
  let messageText = extendedThreadOfMessage.lastMessage.content;

  messageText = messageText.length > 100 ? `${messageText.slice(0, 100)}...` : messageText;

  let messageFile = extendedThreadOfMessage.lastMessage.file;
  let carLink = routes.VEHICLE_PAGE.buildPath({id: threadOfMessage.shortId});
  let chatLink = routes.CHAT.path;

  yield put(snackbarsActions.pushInfoMessage({
    lifeTime: 5000,
    withBeep: true,
    header: 'Ny melding',
    text: (
      <SnackbarContent
        isSimpleThread={threadOfMessage.derived.isSimpleThread}
        thumbnail={thumbnail}
        userName={userName}
        carName={carName}
        messageText={messageText}
        messageFile={messageFile}
        carLink={carLink}
        chatLink={chatLink}
      />
    )
  }))
}

export function* setMessageAsRead({newMessage, threadOfMessage}){
  let {[threadOfMessage.id]: messages} = yield select(selectors.getData);

  if(!messages)
    return;

  let newMessageId = newMessage.thread.lastMessage.id;

  let result = yield call(request.post, {
    path: 'message/dismiss',
    params: {
      threadId: threadOfMessage.id,
      threadMessageIds: [newMessageId]
    }
  });

  if(result instanceof Error)
    return result;

  yield put(actions.updateData({
    [threadOfMessage.id]: {
      rows: messages.rows.map(row => {
        return row.id === newMessageId ?
          {...row, isRead: true} :
          row
      })
    }
  }))
};

/**
 * Decreases `unreadCount` & `unreadMessageCount`
 *
 * 1. Updates data.threads: data.threads.unreadMessageCount & `unreadCount` in thread object of data.threads.rows array
 * 2. Updates data[thread.id].unreadCount
 * 3. Updates ui.currentThread
 */

export function* increaseUnreadCount({threadOfMessage}){
  let data = yield select(selectors.getData);

  let {threads} = data;

  if(!threads)
    return;

  let newUnreadCountValue;

  let updatedThreadsRows = threads.rows.map((row) => {
    if(row.id === threadOfMessage.id){
      newUnreadCountValue = (row.unreadCount || 0) + 1;

      return {
        ...row,
        unreadCount: newUnreadCountValue
      }
    }

    return row;
  });

  yield put(actions.updateData({
    threads: {
      rows: updatedThreadsRows,
      unreadMessageCount: threads.unreadMessageCount + 1
    }
  }));

  if(data[threadOfMessage.id]){
    yield put(actions.updateData({
      [threadOfMessage.id]: {
        unreadCount: newUnreadCountValue
      }
    }))
  }

  let ui = yield select(selectors.getUi);

  if(ui.currentThread && ui.currentThread.id === threadOfMessage.id){
    yield put(actions.updateUi({
      currentThread: {
        unreadCount: newUnreadCountValue
      }
    }))
  }
};

export function* isMessageAlreadyAdded({newMessage, threadOfMessage}){
  let data = yield select(selectors.getData);

  if(!data[threadOfMessage.id])
    return false;

  for(let i = 0; i < data[threadOfMessage.id].rows.length; i++){
    if(data[threadOfMessage.id].rows[i].clientId === newMessage.clientId){
      return true;
    }
  }

  return false;
}

export function* handleNewMessage({payload: newMessage}){
  if(isDev) {
    console.log('newMessage:');
    console.log(newMessage);
  }

  let session = yield select(sessionSelectors.getSession);
  let ui = yield select(selectors.getUi);
  let data = yield select(selectors.getData);

  // prepare helpers data

  let threadOfMessage = newMessage.thread;
  let messagesOfThread = data[threadOfMessage.id];

  let senderIsACurrentUser = userService.getUserId(session) === newMessage.senderUserId;
  let userIsOnAChatPage = ui.isPageMounted;
  let isUserSelectedAThread = ui.currentThread;
  let userIsOnACurrentThread = isUserSelectedAThread && newMessage.thread.id === ui.currentThread.id;
  let userIsCloseToTheBottomOfMessagesList = isUserSelectedAThread ? scroll.getDistanceFromBottom({id: 'ChatMessages-list'}) <= 100 : false;

  // case 1: current user sends a message

  if(senderIsACurrentUser){

    // new message can be already added by `messages/send` saga
    // BUT only if user sends message from a chat page + only for same tab
    // for other cases, for example user sends message from a vehicle page or user opened multiple tabs,
    // we should add new message to messages list relying on this event
    // so, we check if such message already added, if not we add it to list

    let messageIsAlreadyAdded = yield call(isMessageAlreadyAdded, {newMessage, threadOfMessage});

    if(messageIsAlreadyAdded)
      return;

    yield call(updateThread, {threadOfMessage});
    yield call(addNewMessage, {newMessage, threadOfMessage, messagesOfThread});

    return;
  }
  /*if(senderIsACurrentUser){
    // case 1.1: current user sends a message from a chat page

    // NEW_THREAD_MESSAGE event will be thrown even when current user sends a message to another user
    // in case if current user sends a message being in a chat page we can ignore NEW_THREAD_MESSAGE event
    // because current message will be handled by `messages/send` saga

    if(userIsOnAChatPage){

      return;

    // case 1.2: current user sends a message NOT from a chat page (for example from vehicle page)

    } else {

      yield call(updateThread, {threadOfMessage});
      yield call(addNewMessage, {newMessage, threadOfMessage, messagesOfThread});

      return;
    }
  }*/

  // case 2: another user sends a message to a current user

  // case 2.1: current user is on a chat page + on a current thread + close to the bottom of messages list

  if(userIsOnAChatPage && userIsOnACurrentThread && userIsCloseToTheBottomOfMessagesList){
    yield call(updateThread, {threadOfMessage});
    yield call(addNewMessage, {newMessage, threadOfMessage, messagesOfThread});
    yield call(setChatContentToBottom);
    yield call(setMessageAsRead, {newMessage, threadOfMessage});

    return;
  }

  // case 2.2: all other cases

  yield call(updateThread, {threadOfMessage});
  yield call(addNewMessage, {newMessage, threadOfMessage, messagesOfThread});
  yield call(showSnackbar, {newMessage, threadOfMessage});
  yield call(increaseUnreadCount, {newMessage, threadOfMessage});
}

export function* watchNewMessage(){
  yield takeEvery(signalrActions.NEW_THREAD_MESSAGE, handleNewMessage);
}