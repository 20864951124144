import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="12px" height="7px" viewBox="0 0 12 7" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-6.000000, -9.000000)" fill="#298FCA">
            <polygon id="Light-arrow-up" points="11.9563953 9 11.5813953 9.33576279 6 14.3590214 6.75 15.1918633 11.9563953 10.5043674 17.1627907 15.1918633 17.9127907 14.3590214 12.3313953 9.33576279"></polygon>
          </g>
        </g>
      </svg>
    );
});

export default Icon;