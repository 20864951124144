import {take, cancel, fork, select} from 'redux-saga/effects';

import * as actions from '../../actions';
import * as mainAppCompActions from '../../../../MainApp/redux/actions';
import * as sessionActions from '../../../../common/redux/session/actions';
import * as sessionSelectors from '../../../../common/redux/session/selectors';
import {getFirstPage} from './getFirstPage';
import {loadMore} from './loadMore';

export function* triggerFetch(){
  let lastTask;

  while(true){
    const action = yield take([
      actions.LOAD_MORE,
      mainAppCompActions.MAIN_APP_COMPONENT_DID_MOUNT,
      sessionActions.SUCCESSFUL_LOGIN
    ]);

    let session = yield select(sessionSelectors.getSession);

    if(action.type === mainAppCompActions.MAIN_APP_COMPONENT_DID_MOUNT && !session.isAuthorized)
      continue;

    if(lastTask)
      yield cancel(lastTask);

    if(action.type === actions.LOAD_MORE){
      lastTask = yield fork(loadMore);
    } else {
      lastTask = yield fork(getFirstPage, action);
    }
  }
}