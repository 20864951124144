import {select, put} from 'redux-saga/effects';

import * as actions from './../../actions';
import * as selectors from './../../selectors';
import * as config from './../../../config';

export function* increaseNewMessagesCounter({thread} = {}){
  let {currentThread, messagesList} = yield select(selectors.getUi);

  let threadToUpdate = thread || currentThread;

  let currentCount = messagesList[threadToUpdate.id].newMessagesCounter;
  let currentCurrentPage = messagesList[threadToUpdate.id].currentPage;
  let currentShowUntil = messagesList[threadToUpdate.id].showUntil;

  if(currentCount/config.getMessagesBy >= 1){
    yield put(actions.updateUi({
      messagesList: {
        [threadToUpdate.id]: {
          currentPage: currentCurrentPage + 1,
          showUntil: currentShowUntil + 1,
          newMessagesCounter: currentCount % config.getMessagesBy
        }
      }
    }))
  } else {
    yield put(actions.updateUi({
      messagesList: {
        [threadToUpdate.id]: {
          newMessagesCounter: currentCount + 1
        }
      }
    }))
  }
}

export function* decreaseNewMessagesCounter({thread} = {}){
  let {currentThread, messagesList} = yield select(selectors.getUi);

  let threadToUpdate = thread || currentThread;

  let currentCount = messagesList[threadToUpdate.id].newMessagesCounter;

  yield put(actions.updateUi({
    messagesList: {
      [threadToUpdate.id]: {
        newMessagesCounter: currentCount - 1
      }
    }
  }))
}