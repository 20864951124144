import canLoadApp from './browser-sniffer';

if(canLoadApp){
  require('setimmediate');
  const React = require('react');
  const ReactDOM = require('react-dom');
  const {BrowserRouter} = require('react-router-dom');
  const {Provider} = require('react-redux');

  const store = require('./store').default;
  const App = require('./App').default;

  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>
    ,
    document.getElementById('root')
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
