export const customEvents = {
  SESSION: {
    LOGGED_IN: {
      category: 'Session',
      action: 'User logged in',
    },
    LOGGED_IN_WITH_FACEBOOK: {
      category: 'Session',
      action: 'User logged in with facebook'
    },
    FAILED_TO_LOGIN: {
      category: 'Session',
      action: 'User failed to log in',
    },
    FAILED_TO_LOGIN_WITH_PASSWORD: {
      category: 'Session',
      action: 'User failed to log in with login/password',
    },
    LOGGED_OUT: {
      category: 'Session',
      action: 'User logged out'
    }
  },
  REGISTRATION: {
    REGISTERED_WITH_PHONE_NUMBER: {
      category: 'Registration',
      action: 'User is registered with phone number'
    }
  },
  ADVERTISEMENTS: {
    USER_STARTED_TO_NEW_AD: {
      category: 'Advertisements',
      action: 'User started to a new ad'
    },
    SUBMITTED: {
      category: 'Advertisements',
      action: 'User submitted advertisement for review/publishing'
    },
    USER_ADDED_TO_FAVORITES: {
      category: 'Advertisements',
      action: 'User added to favorites'
    },
    USER_LOOKED_TO_PHONE_NUMBER: {
      category: 'Advertisements',
      action: 'User (not owner) looked to a phone number'
    },
  },
  MESSAGES: {
    NEW_MESSAGE_SENT: {
      category: 'Messages',
      action: 'New message is sent'
    },
  },
  BROWSE: {
    /*TODO*/USER_SWITCHED_TO_MAP_MODE: {
      category: 'BROWSE',
      action: 'User switched to map mode'
    },
    /*TODO*/USER_VIEWED_AD_ON_MAP: {
      category: 'BROWSE',
      action: 'User viewed advertisement on the map'
    }
  }
};