// because of special behaviour and bugs of IE,
// sniffing for ie and handling it is done directly in index.html

import bowser from 'bowser';

let canLoadApp = true;

// see: https://caniuse.com/#feat=flexbox and https://github.com/lancedikson/bowser

if(
  (bowser.msie && bowser.version <= 11)
  || (bowser.opera && bowser.version < 12)
  || (bowser.firefox && bowser.version < 28)
  || (bowser.chrome && bowser.version < 21)
  || (bowser.safari && bowser.version <= 6)
  || (bowser.ios && bowser.version < 7)
  || (bowser.android && bowser.version < 4.4)
){
  canLoadApp = false;
  document.getElementById('sniffer-message').style.display = 'block';
}

export default canLoadApp;



