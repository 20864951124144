import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
    return (
      <svg className={cn.join(componentName, className)} {...rest} width="14px" height="13px" viewBox="0 0 14 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-5.000000, -6.000000)">
            <path d="M8.41421356,11.5 L18,11.5 C18.5522847,11.5 19,11.9477153 19,12.5 C19,13.0522847 18.5522847,13.5 18,13.5 L8.41421356,13.5 L12.2071068,17.2928932 C12.5976311,17.6834175 12.5976311,18.3165825 12.2071068,18.7071068 C11.8165825,19.0976311 11.1834175,19.0976311 10.7928932,18.7071068 L5.29289322,13.2071068 C4.90236893,12.8165825 4.90236893,12.1834175 5.29289322,11.7928932 L10.7928932,6.29289322 C11.1834175,5.90236893 11.8165825,5.90236893 12.2071068,6.29289322 C12.5976311,6.68341751 12.5976311,7.31658249 12.2071068,7.70710678 L8.41421356,11.5 Z" id="path-1"/>
          </g>
        </g>
      </svg>
    );
});

export default Icon;