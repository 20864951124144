import {DateTime} from 'luxon';

import * as service from './../../../../common/services';

export default function* extend(thread){
  let derived = {
    isSimpleThread: false, // when thread is not related to ad
    otherUserName: '',
    carName: '',
    carNameWithYear: '',
    carThumbnail: '',
    lastMessage: {
      createdDateText: ''
    }
  };

  if(!thread.shortId)
    derived.isSimpleThread = true;

  derived.otherUserName = `${thread.otherUserFirstName} ${thread.otherUserLastName}`;
  derived.carName = `${thread.makeIdText} ${thread.modelIdText}`;
  derived.carNameWithYear = `${thread.makeIdText} ${thread.modelIdText} (${thread.year})`;
  derived.carThumbnail = thread.photoMain && service.prepareFileLink(thread.photoMain.urlThumbnail);

  // prepare last message date

  let date = DateTime.fromISO(thread.lastMessage.createdDate).setLocale('nb');

  if(date.year === DateTime.local().year){
    derived.lastMessage.createdDateText = date.toLocaleString({month: 'long', day: '2-digit'});
  } else {
    derived.lastMessage.createdDateText = date.toLocaleString({year: 'numeric', month: 'long', day: '2-digit'});
  };

  thread.derived = derived;

  return thread;
};