import React from 'react';
import {call, select, put, takeEvery} from 'redux-saga/effects';

import * as actions from './../../actions';
import * as selectors from './../../selectors';
import * as signalrActions from './../../../../common/services/signalr/actions';

const isDev = process.env.NODE_ENV === 'development';

/*
  Payload model: {
    otherUserId: "da27a521-5e03-402c-161f-08d5dffe2c00"
    threadId: "73f4a855-5ca7-477b-8a36-34cff3a7510c"
    threadMessageIds: ["826e2ddc-fe9a-463b-8452-1735605bd3b7"]
  }
*/

/**
 * Sets `isRead` property to true for read messages
 */

export function* handleDismissedMessages({payload}){
  if(isDev){
    console.log('Dismissed messages:');
    console.log(payload);
  }

  let {threadId, threadMessageIds} = payload;

  let {[threadId]: messages} = yield select(selectors.getData);

  if(!messages)
    return;

  yield put(actions.updateData({
    [threadId]: {
      rows: messages.rows.map((message) => {
        return threadMessageIds.includes(message.id) ?
          {...message, isRead: true} :
          message
      })
    }
  }))
}

export function* watchDismissedMessages(){
  yield takeEvery(signalrActions.DISMISSED_THREAD_MESSAGES, handleDismissedMessages);
}