import React from 'react';

import './style.scss';
import classname from './../../../helpers/classname';

const componentName = 'Icon';
const cn = classname(componentName);

const Icon = React.memo(({className, ...rest}) => {
  return (
    <svg className={cn.join(componentName, className)} {...rest} width="19px" height="24px" viewBox="0 0 19 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-2.000000, 0.000000)" fill="#298FCA">
          <path d="M16.7057479,1.00976293 L6.94282221,1 C5.86890038,1 5,1.87866331 5,2.95258513 L5,20.5258513 C5,21.5997731 5.86890038,22.4784365 6.94282221,22.4784365 L16.7057479,22.4784365 C17.7796697,22.4784365 18.658333,21.5997731 18.658333,20.5258513 L18.658333,2.95258513 C18.658333,1.87866331 17.7796697,1.00976293 16.7057479,1.00976293 L16.7057479,1.00976293 Z M16.7057479,18.5732662 L6.94282221,18.5732662 L6.94282221,4.90517026 L16.7057479,4.90517026 L16.7057479,18.5732662 L16.7057479,18.5732662 Z" id="Phone" transform="translate(11.829166, 11.739218) rotate(15.000000) translate(-11.829166, -11.739218) "></path>
        </g>
      </g>
    </svg>
  );
});

export default Icon;