import {call, put, select} from 'redux-saga/effects';

import * as actions from './../../actions';
import * as selectors from './../../selectors';
import * as config from './../../../config';
import {processesNames} from './../../../config';
import {FIRST_PAGE} from './../config';
import {dismissAll} from './../common/dismiss';
import request from './../../../../common/services/request';
import prepareParamsForGrid from './prepareParamsForGrid';
import extendItem from './extendItem';

export function* getFirstPage(action){

  // block handle scroll fluid

  yield put(actions.updateUi({blockHandleScrollFluid: true}));

  let params = yield call(prepareParamsForGrid, {type: FIRST_PAGE});

  let result = yield call(request.post, {
    path: 'Notification/grid',
    processName: processesNames.getFirstPage,
    params
  });

  if(result instanceof Error)
    return;

  // save `currentPage` & `showUntil`

  yield put(actions.updateUi({
    currentPage: 1,
    showUntil: 0
  }));

  // save result

  for(let i = 0; i < result.rows.length; i++){
    result.rows[i] = yield call(extendItem, {item: result.rows[i]})
  }

  yield put(actions.updateData({grid: result}));

  // unblock handle scroll fluid

  if(result.totalCount > config.getBy)
    yield put(actions.updateUi({blockHandleScrollFluid: false}));

  // dissmiss all if page is mounted

  let ui = yield select(selectors.getUi);

  if(ui.isPageMounted)
    yield call(dismissAll);
};